import moment from "moment";

export const stringToDateFormatter = (date: string): string => {
  return moment(date).format("YYYY-MM-DD");
};

export const stringToDateTimerFormatter = (date?: string): string => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

// export const formatVersionString = (text: string): string => {
//   let result = "";
//   const value = text.replace(/[^0-9\d]/g, "");
//   const parts = value.split("");

//   for (let i = 0; i < parts.length; i++) {
//     parts[i] = parts[i].replace(/\D/g, "");
//   }
//   result = parts.join(".").slice(0, 5);

//   return result;
// };


// export const formatVersionString = (text: string): string => {
//   let result = "";
//   const numericOnly = text.replace(/[^0-9]/g, "");

//   const parts = numericOnly.split("");

//   for (let i = 0; i < parts.length; i++) {
//     result = result + parts[i];
//     if (parts.length > 2 && (i === 0 || i === 1)) result = result + ".";
//   }

//   result = result.slice(0, 6);

//   return result;
// };

export const formatVersionString = (text: string): string => {
  return text.trim();
};