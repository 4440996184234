import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";
import IconBox from "../IconBox";

export interface NoticeProps {
  backgroundColor?: string;
  iconSrc?: string;
  iconWidth?: number;
  iconHeight?: number;
  text?: string;
}

const Noti: React.FC<NoticeProps> = ({
  backgroundColor = colors.YELLOW5,
  text,
  iconSrc,
}) => {
  return (
    <NoticeWrap backgroundColor={backgroundColor}>
      <IconBox src={iconSrc} width={20} height={20} />
      {text || "Text"}
    </NoticeWrap>
  );
};

export default Noti;

const NoticeWrap = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 10px;
  gap: 8px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};
  ${typo.BODY_9};
  color: ${colors.GRAY3};
  width: 100%;
`;
