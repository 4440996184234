import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";
import "antd/dist/antd.min.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { colors, typo } from ".";
import { ICONS } from "@assets/icons";

const SelectGlobalStyle = css`
  .create-select {
    .css-tlfecz-indicatorContainer > svg {
      display: none;
    }
    .css-1gtu0rj-indicatorContainer > svg {
      display: none;
    }

    .css-tlfecz-indicatorContainer {
      background: no-repeat center/cover url(${ICONS.ARROW_ICON.SELECT_DOWN});
      width: 24px;
      height: 24px;
    }

    .css-1gtu0rj-indicatorContainer {
      background: no-repeat center/cover url(${ICONS.ARROW_ICON.SELECT_DOWN});
      width: 24px;
      height: 24px;
    }
  }
  .filter-select {
    .css-tlfecz-indicatorContainer > svg {
      display: none;
    }
    .css-1gtu0rj-indicatorContainer > svg {
      display: none;
    }

    .css-tlfecz-indicatorContainer {
      background: no-repeat center/cover url(${ICONS.ARROW_ICON.SELECT_DOWN});
      width: 24px;
      height: 24px;
    }

    .css-1gtu0rj-indicatorContainer {
      background: no-repeat center/cover url(${ICONS.ARROW_ICON.SELECT_DOWN});
      width: 24px;
      height: 24px;
    }
  }
`;

const AntdGlobalStyle = css`
  /* pagination style */
  .ant-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;

    button,
    li {
      border: none !important;
      ${typo.BODY_7};
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      min-height: 24px;
      height: 100%;
      order: 2;
    }

    .ant-pagination-options {
      order: 1;
      margin-right: 16px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
      border-color: ${colors.GRAY9};
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #5e6565;
      padding: 0 14px;
    }

    .ant-select-selection-item {
      font-weight: 500;
    }

    /* select hover style */
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      /* background: gray; */
    }

    button > span > svg {
      display: none;
    }

    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button > .anticon-left {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: no-repeat center/cover
        url(${ICONS.ARROW_ICON.PAGINATION_LEFT});
    }

    button > .anticon-right {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: no-repeat center/cover
        url(${ICONS.ARROW_ICON.PAGINATION_RIGHT});
    }

    .ant-pagination-item a {
      color: ${colors.GRAY6};
    }

    .ant-pagination-item-active a {
      color: ${colors.GRAY2};
      font-weight: 500;
    }
  }

  /* date picker 공통 스타일 */
  .ant-picker {
    .ant-picker-clear {
      display: none !important;
    }

    .ant-picker-input {
      cursor: pointer;
    }
  }

  /* date picker style */
  .ant-picker-dropdown {
    z-index: 9999;

    .ant-picker-panel-container {
      border-radius: 8px;
      box-shadow: 0px 8px 32px rgba(53, 58, 63, 0.16);
      padding: 10px 0 6px;
    }
    .ant-picker-date-panel {
      width: 300px;
      gap: 8px;
    }

    .ant-picker-panel {
      border: none;
    }

    .ant-picker-header {
      border: none;
      padding: 0 14px;

      .ant-picker-header-view {
        color: ${colors.GRAY2};
        ${typo.HEADING_7};
        min-height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ant-picker-header-super-next-btn,
      .ant-picker-header-super-prev-btn {
        display: none;
      }

      .ant-picker-header-next-btn {
        display: inline-block;
        width: 20px;
        background: no-repeat url(${ICONS.ARROW_ICON.PAGINATION_RIGHT});
        background-position: 0%;
        background-size: 20px 20px;

        span {
          display: none;
        }
      }
      .ant-picker-header-prev-btn {
        display: inline-block;
        width: 20px;
        background: no-repeat url(${ICONS.ARROW_ICON.PAGINATION_LEFT});
        background-position: 0%;
        background-size: 20px 20px;

        span {
          display: none;
        }
      }
    }

    .ant-picker-body {
      padding: 0;
      margin: auto;
    }

    /* 요일 */
    .ant-picker-content {
      th {
        width: 40px;
        text-align: center;
        color: ${colors.GRAY6};
      }
    }

    /* 년도 */
    .ant-picker-content td {
    }

    .ant-picker-cell .ant-picker-cell-inner {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: auto;
    }

    /* 오늘 날짜 */
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
      color: ${colors.PRIMARY};
      ::before {
        border: none;
      }
    }

    /* select day style */
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background: ${colors.PRIMARY};
      color: ${colors.WHITE};
      border-radius: 50% !important;
    }

    /* 달력에 날짜 호버 됐을때 */
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background: ${colors.GRAY10};
      color: ${colors.GRAY2};
    }

    /* 오늘 버튼 */
    .ant-picker-footer {
      display: flex;
      border: none;
      padding: 10px 16px;

      a {
        width: 50px;
        height: 32px;
        padding: 7px 12px;
        border: 1px solid ${colors.GRAY8};
        border-radius: 8px;
        color: ${colors.GRAY4};
        ${typo.BODY_10};
      }
    }

    /* month panel */
    .ant-picker-month-panel {
      .ant-picker-cell-inner {
        padding: 0;
      }
    }

    /* decade panel */
    .ant-picker-decade-panel {
      .ant-picker-cell-inner {
        width: 55px;
        height: 55px;
      }
    }
  }

  /* select menuBar */
  .ant-select-dropdown {
    padding: 8px;
    border-radius: 12px;
    z-index: 10000;

    /* select menu item */
    .ant-select-item {
      padding: 8px 4px 8px 12px;
      ${typo.BODY_7};
    }

    /* select active */
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: ${colors.WHITE};
      ::after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        background: no-repeat center/cover
          url(${ICONS.CHECK_ICON.SELECTED_CHECK_ACTIVE});
      }
    }

    /* select hover */
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background: ${colors.GRAY9};
    }
  }

  /* data picker action style */
  .ant-picker-range .ant-picker:hover {
    border: none;
  }

  .ant-picker-range.ant-picker-focused {
    box-shadow: none;
  }

  /* data picker range style */
  .ant-picker-range {
    border: none;
    position: relative;
    cursor: pointer;

    /* focus bar color */
    .ant-picker-active-bar {
      background: ${colors.PRIMARY};
    }

    .ant-picker-range-separator {
      .ant-picker-separator {
        position: relative;

        ::after {
          content: "-";
          display: block;
        }
      }

      /* 기본 svg 제거 */
      .anticon-swap-right {
        display: none;
      }
    }

    /* input style */
    .ant-picker-input {
      width: fit-content;
      cursor: pointer;

      input {
        border: none;
        ${typo.BODY_8};
        color: ${colors.GRAY2};
      }
    }

    .ant-picker-input-active {
    }

    /* 달력 icon */
    .ant-picker-suffix {
      position: absolute;
      right: 10px;
      cursor: pointer;

      .anticon-calendar {
        position: relative;

        ::after {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          background: no-repeat center/cover
            url(${ICONS.DATE_ICON.CALENDAR_WHITE});
        }

        svg {
          display: none;
        }
      }
    }
  }
  /* clear button style */
  .ant-picker-clear {
    display: none;
  }
`;

const AgGridGlobalStyle = css`
  /* table wrap style */
  .ag-layout-normal {
    border: none;
  }

  /* title wrap style */
  .ag-header.ag-pivot-off {
    background: ${colors.GRAY11};
    border-bottom: 1px solid ${colors.GRAY9};
  }

  /* title style */
  .ag-header-cell {
    ${typo.BODY_8};
    font-weight: 500;
    color: ${colors.GRAY2};
  }

  /* title admin left border style */
  .ag-pinned-right-header {
    border: none;
  }

  /* content column left border style */
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border: none;
  }

  /* contents row style  */
  .ag-row {
    border-bottom: 1px solid ${colors.GRAY9};
  }

  /* contents wrap 홀수 background */
  .ag-row-odd {
    background: ${colors.WHITE};
  }

  /* content hover style */
  .ag-row-hover:not(.ag-full-width-row)::before {
    background-color: ${colors.GRAY11};
  }
`;

const CkEditorGlobalStyle = css`
  :root {
    --ck-border-radius: 8px;
  }
  .ck {
    /* menu bar border */
    .ck-rounded-corners {
      border-radius: 8px;
    }

    /* menu bar 간격 */
    .ck-toolbar > .ck-toolbar__items {
      gap: 3.2px;
    }

    /* menu bar border-color */
    .ck-toolbar {
      border-color: ${colors.GRAY9};
    }

    /* heading text 간격 */
    .ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
      padding: 6px 0;
    }

    /* heading width */
    .ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
      ${typo.BODY_9};
      width: 72px;
    }
    .ck.ck-list__item {
      width: 30px;
    }
    /* font-size text-align  */
    button.ck.ck-button.ck-fontsize-option.ck-off.ck-button_with-text {
      min-height: 45px;
      span {
        display: flex;
        align-items: center;
      }
    }

    /* contents box wrap */
    .ck-editor__main {
      /* contents box */
      .ck-editor__editable {
        min-height: 320px;
        height: 100%;
        padding: 12px;
        max-height: 800px;
      }

      /* contents border-color */
      .ck-editor__editable:not(.ck-focused) {
        border-color: ${colors.GRAY9};
      }

      /* contents first "p" tag 간격 */
      .ck-editor__editable_inline > :first-child {
        margin-top: 0;
      }

      p {
        margin-bottom: 6px;
      }
    }

    /* list style */
    .ck-content ol {
      list-style-type: decimal;
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    .ck-content ul {
      list-style-type: circle;
    }
    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }

    /* editor default style */
    h2 {
      font-size: 24px;
      margin-bottom: 6px;
    }
    h3 {
      font-size: 18.72px;
      margin-bottom: 6px;
    }
    h4 {
      font-size: 16px;
      margin-bottom: 6px;
    }
    strong {
      font-weight: bold;
    }
    i {
      font-style: italic;
    }
  }

  /* font-size toolbar */
  .ck-font-size-dropdown {
    /* font-size wrap */
    ul.ck.ck-reset.ck-list {
      overflow-y: auto;
      max-height: 360px;
      max-width: 100px;

      /* font-size inner-wrap */
      .ck-button {
        max-width: 98px;

        /* font-size label */
        .ck-button__label {
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  /* table atoms icon & header menu icons*/
  .ck-rounded-corners .ck.ck-button,
  .ck-rounded-corners a.ck.ck-button,
  .ck.ck-button.ck-rounded-corners,
  a.ck.ck-button.ck-rounded-corners {
    border-radius: 2px;
  }

  /* table contents style */
  .ck-content .table {
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;
      /* background-color: white; */
      border: 1px solid rgb(209, 215, 237);

      th {
        /* text-align: center; */
        vertical-align: bottom;
        padding: 10px;
        border: 1px solid rgb(209, 215, 237);
      }

      td {
        vertical-align: bottom;
        padding: 10px;
        border: 1px solid rgb(209, 215, 237);
      }
    }
  }
`;

const GlobalStyles = createGlobalStyle`
  ${reset}
*{
    box-sizing: border-box;
}

html,body {
  width: 100%;
  height: 100%;
  font-family: 'Pretendard';
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  min-width: 1280px;
}

a {
  text-decoration: none;
}

${AgGridGlobalStyle}
${AntdGlobalStyle}
${SelectGlobalStyle}
${CkEditorGlobalStyle}

`;

export default GlobalStyles;
