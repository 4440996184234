import { FaqDetailResponse } from "@apis/faq/types";
import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import IconBox from "@components/atoms/IconBox";
import Editor from "@components/molecules/Editor";
import { ApiUrls } from "@constants/api-urls";
import useCommonDelete from "@hooks/useCommonDelete";
import useCommonEdit from "@hooks/useCommonEdit";
import useFetchDetailData from "@hooks/useFetchDetailData";
import { useFetchFaqCategoryOption } from "@hooks/useFetchFaqCategoryOption";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { FaqNoti } from "@pages/FaqCreate";
import { FaqCommonContentsOptionType } from "@pages/FaqCreate/Data";
import { colors, typo } from "@styles/index";
import {
  CommonElementType,
  RenderCommonElementByType,
} from "@utils/DynamicComponentUtils";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FaqDetailData, FaqDetailInitValuesType } from "./Data";
import { compareObjects } from "@utils/data-fns";
import CopyButton from "@components/atoms/CopyButton";

const FaqDetail: React.FC = () => {
  // Props
  const HEADER_TITLE = "FAQ 상세";
  const CONTENT_TITLE = "FAQ 내용";

  // Data
  const { createBodyKey, formInitialValues } = FaqDetailData;

  // Formik
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      editMutation(values);
    },
  });

  // Hooks
  const { query, goBack } = useRouter();
  const {
    hideModal,
    showConfirmModal,
    showReasonModificationModal,
    showReasonEditModal,
  } = useGlobalModal();
  const id = query && query.id ? query.id : "";
  const { initData } = useFetchDetailData<FaqDetailResponse>({
    id: id,
    queryKey: "faqDetail",
    fetchUrl: ApiUrls.faqDelete,
    formik,
  });
  const { editMutation } = useCommonEdit<FaqDetailInitValuesType>({
    editUrl: ApiUrls.faqDelete,
    createBodyKey,
  });
  const { handleDelete } = useCommonDelete({ deleteUrl: ApiUrls.faqDelete });

  const [selectedCategory1, setSelectedCategory1] = useState("");
  const [selectedCategory2, setSelectedCategory2] = useState("");

  const initCategoryOptionCode = "NOTF00";
  const { categoryOption: categoryCustomOption1 } = useFetchFaqCategoryOption(
    initCategoryOptionCode,
  );
  const {
    categoryOption: categoryCustomOption2,
    setCategoryOption: setCategoryCustomOption2,
  } = useFetchFaqCategoryOption(formik.values.category1);
  const {
    categoryOption: categoryCustomOption3,
    setCategoryOption: setCategoryCustomOption3,
  } = useFetchFaqCategoryOption(formik.values.category2);

  // Functions
  const handleGoBackClick = () => {
    if (JSON.stringify(initData) !== JSON.stringify(formik?.values)) {
      showConfirmModal({
        title: `작성한 내용을 저장하지 않고\n 취소 하시겠어요?`,
        firstButtonText: "닫기",
        secondButtonText: "확인",
        onConfirm: () => {
          hideModal();
          goBack();
        },
      });
    } else {
      goBack();
    }
  };

  const handleEditContent = () => {
    showReasonEditModal({
      category: "faq",
      onSave: () => {
        localStorage.removeItem("previewData");
        hideModal();
        formik?.handleSubmit();
      },
      onChangeReason: (e) => {
        formik.setFieldValue("updateReason", e.target.value);
      },
    });
  };

  const handleDeleteContent = () => {
    showConfirmModal({
      title: `삭제하시겠어요?`,
      firstButtonText: "닫기",
      secondButtonText: "삭제하기",
      onConfirm: () => {
        handleDelete(id);
        hideModal();
      },
    });
  };

  const handleReasonModification = () => {
    showReasonModificationModal({
      category: "faq",
      updateHistory: formik?.values.updateHistory,
    });
  };

  const isEditButtonValidate = useCallback(() => {
    if (!initData || !formik) return true;
    const { title, category1, category2, category3, content } = formik.values;
    const isCompare = compareObjects(initData, formik.values);

    return (
      isCompare || !title || !category1 || !category2 || !category3 || !content
    );
  }, [formik.values, initData]);

  // Effects
  // 대분류, 중분류 초기값 설정
  useEffect(() => {
    if (initData) {
      setSelectedCategory1(initData?.category1);
      setSelectedCategory2(initData?.category2);
    }
  }, [initData]);

  // 대분류 변경시 중분류, 소분류 초기화
  useEffect(() => {
    if (
      formik.values.category1 === "" ||
      (selectedCategory1 && selectedCategory1 !== formik.values.category1)
    ) {
      setCategoryCustomOption2([]);
      setCategoryCustomOption3([]);
      formik.setFieldValue("category2", "");
      formik.setFieldValue("category3", "");
      setSelectedCategory1(formik.values.category1);
    }
  }, [formik.values.category1, selectedCategory1]);

  // 중분류 변경시 소분류 초기화
  useEffect(() => {
    if (
      formik.values.category2 === "" ||
      (selectedCategory2 && selectedCategory2 !== formik.values.category2)
    ) {
      setCategoryCustomOption3([]);
      formik.setFieldValue("category3", "");
    }
  }, [formik.values.category2]);

  // Renderers Data
  const contentsOption: FaqCommonContentsOptionType[] = [
    {
      label: "노출 상태",
      name: "isExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "대분류",
      name: "category1",
      type: CommonElementType.SELECT,
      placeholder: "대분류를 선택해주세요",
      options: categoryCustomOption1,
    },
    {
      label: "중분류",
      name: "category2",
      type: CommonElementType.SELECT,
      placeholder: "중분류를 선택해주세요",
      notice: FaqNoti,
      options: categoryCustomOption2,
    },
    {
      label: "소분류",
      name: "category3",
      type: CommonElementType.SELECT,
      placeholder: "소분류를 선택해주세요",
      options: categoryCustomOption3,
    },
    {
      label: "제목",
      name: "title",
      type: CommonElementType.INPUT,
      placeholder: "제목을 입력해주세요",
    },
  ];

  return (
    <ContentsWrap>
      <HeaderWrap>
        <IconBox
          width={32}
          height={32}
          src={ICONS.ARROW_ICON.BACK}
          isCursorPointer
          onClick={handleGoBackClick}
        />
        <HeaderTitle>{HEADER_TITLE}</HeaderTitle>
      </HeaderWrap>

      <div>
        <ContentBox style={{ padding: 0 }}>
          <ContentsHeader>
            {CONTENT_TITLE}

            <ButtonWrap>
              <CopyButton text="외부 링크 복사" type="external" />
              <CopyButton text="앱 연결 링크 복사" type="app" />
            </ButtonWrap>
          </ContentsHeader>

          <ContentsMainWrap>
            {contentsOption?.map(
              ({ label, name, options, notice, ...res }, _) => {
                return (
                  <React.Fragment key={`${_}_contents`}>
                    <InputWrap>
                      <LabelWrap>
                        <LabelInnerWrap>
                          <Label>{label || "LABEL"}</Label>
                        </LabelInnerWrap>
                      </LabelWrap>
                      <InputContentsWrap noti={!!notice}>
                        {RenderCommonElementByType({
                          formik,
                          name,
                          options,
                          ...res,
                        })}
                        {notice && notice}
                      </InputContentsWrap>
                    </InputWrap>
                  </React.Fragment>
                );
              },
            )}

            <Editor formik={formik} initData={formik?.values.content ?? ""} />
          </ContentsMainWrap>
        </ContentBox>

        <FooterButtonWrap>
          <LeftButtonWrap>
            <Button
              text="삭제"
              buttonType="line_red"
              onClick={handleDeleteContent}
            />
            <Button
              text="수정사유 확인"
              buttonType="line_primary"
              onClick={handleReasonModification}
              disabled={formik.values.updateHistory.length === 0}
            />
          </LeftButtonWrap>
          <RightButtonWrap>
            <Button
              text="취소"
              buttonType="line_black"
              onClick={handleGoBackClick}
            />
            <Button
              disabled={isEditButtonValidate()}
              text="저장하기"
              onClick={handleEditContent}
            />
          </RightButtonWrap>
        </FooterButtonWrap>
      </div>
    </ContentsWrap>
  );
};

export default FaqDetail;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const HeaderTitle = styled(MainHeaderTitle)`
  margin: 0;
`;

const TableTitle = styled.div`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const ContentTitle = styled(TableTitle)`
  margin: 0;
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;

const ContentsHeader = styled(ContentTitle)`
  display: flex;
  justify-content: space-between;
`;

const ContentsMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 840px;
  margin: auto;
  padding: 52px 0 80px;
`;

const InputWrap = styled.div`
  display: flex;
  gap: 12px;
  min-height: 48px;
  width: 100%;
`;

const LabelWrap = styled.span`
  min-width: 200px;
  padding: 12px 0 12px;
`;

const LabelInnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const Label = styled.p`
  color: ${colors.GRAY6};
  ${typo.HEADING_7};
`;

const InputContentsWrap = styled.div<{ noti?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ noti }) =>
    noti &&
    css`
      flex-direction: column;
      gap: 12px;
    `}
`;

const FooterButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const LeftButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;

const RightButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;
