import { ICONS } from "@assets/icons";
import { PrivateRoutes } from "@constants/urls";

export const ASIDE_MENU_LIST = [
  {
    title: "홈 관리",
    path: "home-section",
    labelIcon: ICONS.SIDEMENU_ICON.HOME,
    subMenu: [
      {
        title: "팝업/배너관리",
        path: PrivateRoutes.Banner,
      },
      {
        title: "충전서비스 이용방법 관리",
        path: PrivateRoutes.ChargingServiceDescription,
        includePaths: [],
      },
    ],
  },
  {
    title: "전체보기 관리",
    path: "costumer-section",
    labelIcon: ICONS.SIDEMENU_ICON.VIEW_ALL,
    subMenu: [
      {
        title: "FAQ 관리",
        path: PrivateRoutes.Faq,
        includePaths: [],
      },
      {
        title: "이벤트 관리",
        path: PrivateRoutes.Event,
        includePaths: [],
      },
      {
        title: "공지사항 관리",
        path: PrivateRoutes.Notice,
        includePaths: [],
      },
      {
        title: "충전기 사용방법 관리",
        path: PrivateRoutes.ChargerDescription,
        includePaths: [],
      },
      {
        title: "앱 버전 관리",
        path: PrivateRoutes.AppVersion,
        includePaths: [],
      },
    ],
  },
  {
    title: "약관 관리",
    path: "terms_section",
    labelIcon: ICONS.SIDEMENU_ICON.TERMS_WHITE,
    subMenu: [
      {
        title: "약관 관리",
        path: PrivateRoutes.Terms,
        // includePaths: [
        //   SUBSCRIBE_PRODUCT_CREATE_PATH,
        //   SUBSCRIBE_PRODUCT_DETAIL_PATH,
        // ],
      },
    ],
  },
];
