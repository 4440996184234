import { colors } from "@styles/index";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React from "react";
import styled from "styled-components";

export interface BasicOptionType {
  label: string;
  value: string;
}

interface CustomCheckBoxProps {
  options: BasicOptionType[];
  selectItems: CheckboxValueType[];
  onChange: (checkedValues: CheckboxValueType[]) => void;
}

const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
  options,
  onChange,
  selectItems,
}) => {
  return (
    <StyledCheckbox options={options} onChange={onChange} value={selectItems} />
  );
};

export default CustomCheckBox;

const StyledCheckbox = styled(Checkbox.Group)`
  /* 정렬 */
  display: flex;
  gap: 40px;

  .ant-checkbox-wrapper {
    align-items: center;
  }

  /* 체크박스 */
  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }
  .ant-checkbox-checked:after {
    border-color: ${colors.PRIMARY};
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }

  /* 체크 */
  .ant-checkbox-inner:after {
    width: 6.714286px;
    height: 11.142857px;
    top: 47%;
    left: 28.5%;
  }

  .ant-checkbox + span {
    padding-right: unset;
  }

  /* 효과 */
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.PRIMARY};
    border-color: ${colors.PRIMARY};
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${colors.PRIMARY};
  }
`;
