import { BaseNoticeProps } from "@components/molecules/Noti/BaseNoti";
import useGlobalNotiStore, { GlobalNotiType } from "@store/noti";
import { useEffect } from "react";

export default function useGlobalNoti() {
  const { notiType } = useGlobalNotiStore((state) => state.globalNoti);
  const setNoti = useGlobalNotiStore((state) => state.setGlobalNoti);

  const showNoti = ({ notiType, notiProps }: GlobalNotiType) => {
    setNoti({ notiType, notiProps });
  };
  const hideNoti = () => {
    setNoti({ notiType: null, notiProps: null });
  };

  useEffect(() => {
    if (notiType) {
      setTimeout(() => {
        hideNoti();
      }, 4800);
    }
  }, [notiType]);

  const showConfirmNoti = ({
    onClose,
    text = "게시물이 등록되었어요.",
  }: BaseNoticeProps) => {
    showNoti({
      notiType: "ConfirmNoti",
      notiProps: {
        text,
        onClose: () => (onClose ? onClose() : hideNoti()),
      },
    });
  };

  const showDeleteNoti = ({
    onClose,
    text = "게시물이 삭제되었어요.",
  }: BaseNoticeProps) => {
    showNoti({
      notiType: "DeleteNoti",
      notiProps: {
        text,
        onClose: () => (onClose ? onClose() : hideNoti()),
      },
    });
  };

  const showEditNoti = ({
    onClose,
    text = "게시물이 수정되었어요.",
  }: BaseNoticeProps) => {
    showNoti({
      notiType: "EditNoti",
      notiProps: {
        text,
        onClose: () => (onClose ? onClose() : hideNoti()),
      },
    });
  };

  return {
    showNoti,
    hideNoti,
    showConfirmNoti,
    showDeleteNoti: showDeleteNoti,
    showEditNoti,
  };
}
