import { colors, typo } from "@styles/index";
import styled from "styled-components";

// 테이블 텍스트 포맷 (텍스트가 길면 ... 처리)
export const TextFormatting = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableText = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${colors.GRAY2};
  ${typo.BODY_8};
  font-weight: 400;
`;

// 필수 텍스트 포맷
export const Essential = styled.span`
  border-radius: 100px;
  display: inline-block;
  padding: 4px 10px;
  background: ${colors.PRIMARY7};
  color: ${colors.PRIMARY2};
  ${typo.BODY_10};
`;

// 선택 텍스트 포맷
export const Choice = styled(Essential)`
  background: ${colors.GRAY10};
  color: ${colors.GRAY2};
`;

export const UpdateText = styled.span`
  padding: 14px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  ${typo.BODY_8};
  font-weight: 400;
  color: ${colors.GRAY2};
`;
