export const connectionCategoryChange = (category: string) => {
  switch (category) {
    case "notices":
      return "notice";
    case "events":
      return "event";
    case "terms":
      return "terms";
    case "url":
      return "url";
  }
};

export const connectionCategoryReverseChange = (category: string) => {
  switch (category) {
    case "notice":
      return "notices";
    case "event":
      return "events";
    case "terms":
      return "terms";
    case "url":
      return "url";
  }
};
