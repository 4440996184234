import { ICONS } from "@assets/icons";
import IconBox from "@components/atoms/IconBox";
import { colors, typo } from "@styles/index";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export interface BaseNoticeProps {
  backgroundColor?: string;
  iconSrc?: string;
  iconWidth?: number;
  iconHeight?: number;
  text?: string;
  onClose?: () => void;
}

const BaseNoti: React.FC<BaseNoticeProps> = ({
  backgroundColor = colors.BLUE2,
  text,
  onClose,
}) => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(true);
    setTimeout(() => {
      setIsShow(false);
    }, 4200);
  }, []);

  // animation 주는 걸로 하자.

  return (
    <NoticeWrap
      className={isShow ? "show" : "hide"}
      backgroundColor={backgroundColor}
    >
      <div>
        <IconBox src={ICONS.NOTI_ICON.NOTI} width={20} height={20} />
        {text || "Text"}
      </div>
      <IconBox
        isCursorPointer
        src={ICONS.NOTI_ICON.CLOSE_WHITE}
        width={24}
        height={24}
        onClick={onClose}
      />
    </NoticeWrap>
  );
};

export default BaseNoti;

const NoticeWrap = styled.div<{ backgroundColor?: string }>`
  position: fixed;
  left: 50%;
  top: 60px;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  min-width: 312px;
  padding: 11px 12px 10px;
  border-radius: 18px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : ""};

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    color: ${colors.WHITE};
    ${typo.BODY_7};
  }

  transition: all 0.5s ease-in-out;

  &.show {
    opacity: 1;
  }

  &.hide {
    top: 40px;
    opacity: 0;
  }
`;
