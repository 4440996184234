import { colors } from "@styles/index";
import React, { PropsWithChildren } from "react";
import styled, { css, CSSObject } from "styled-components";

interface ContentBoxProps {
  className?: string;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string;
  style?: CSSObject;
}

const ContentBox: React.FC<PropsWithChildren<ContentBoxProps>> = ({
  children,
  className,
  backgroundColor = colors.WHITE,
  borderColor = colors.GRAY9,
  borderRadius = "12px",
  style,
}) => {
  return (
    <>
      <ContentBoxContainer
        style={style}
        className={className}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderRadius={borderRadius}
      >
        {children}
      </ContentBoxContainer>
    </>
  );
};

export default ContentBox;

const ContentBoxContainer = styled.div<ContentBoxProps>`
  padding: 24px;
  overflow: hidden;

  ${({ borderColor, borderRadius, backgroundColor }) => css`
    background-color: ${backgroundColor};
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `}
`;
