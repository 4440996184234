import { BannerDetailResponse } from "@apis/banner/types";
import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import { BasicOptionType } from "@components/atoms/CheckBox";
import ContentBox from "@components/atoms/ContentBox";
import IconBox from "@components/atoms/IconBox";
import RenderBannerExposureLocationCheckbox from "@components/molecules/RenderBannerExposureLocationCheckbox";
import RenderBannerConnectionList from "@components/molecules/RenderBannerConnectionList";
import SizeNoticeBox from "@components/molecules/SizeNoticeBox";
import { ApiUrls } from "@constants/api-urls";
import regex from "@constants/regex";
import useCommonDelete from "@hooks/useCommonDelete";
import useCommonEdit from "@hooks/useCommonEdit";
import useFetchDetailData from "@hooks/useFetchDetailData";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import {
  ContentsHeader,
  ContentsMainWrap,
  ContentsWrap,
  FooterButtonWrap,
  HeaderTitle,
  HeaderWrap,
  InputContentsWrap,
  InputWrap,
  Label,
  LabelInnerWrap,
  LabelWrap,
  LeftButtonWrap,
  PopupConnectionCategory,
  RightButtonWrap,
} from "@pages/BannerCreate";
import { RenderCommonElementByType } from "@utils/DynamicComponentUtils";
import { connectionCategoryReverseChange } from "@utils/connection-category-util";
import { compareObjects } from "@utils/data-fns";
import { useFormik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { BannerDetailData, BannerDetailInitValuesType } from "./Data";

const BannerDetail: React.FC = () => {
  // Props
  const HEADER_TITLE = "배너 상세";
  const CONTENT_TITLE = "배너 내용";

  // Data
  const { createBodyKey, formInitialValues, contentsOption } = BannerDetailData;

  // Formik
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      editMutation(values);
    },
  });

  // Hooks
  const { query, goBack } = useRouter();
  const { hideModal, showConfirmModal } = useGlobalModal();
  const id = query && query.id ? query.id : "";
  const { initData } = useFetchDetailData<BannerDetailResponse>({
    id: id,
    queryKey: "bannerDetail",
    fetchUrl: ApiUrls.bannerDetail,
    formik,
  });
  const { editMutation } = useCommonEdit<BannerDetailInitValuesType>({
    editUrl: ApiUrls.banner,
    createBodyKey,
  });
  const { handleDelete } = useCommonDelete({ deleteUrl: ApiUrls.banner });
  const [connectionKindList, setConnectionKindList] = useState<BasicOptionType>(
    PopupConnectionCategory[0],
  );

  // Functions
  const handleGoBackClick = () => {
    if (JSON.stringify(initData) !== JSON.stringify(formik?.values)) {
      showConfirmModal({
        title: `작성한 내용을 저장하지 않고\n 취소 하시겠어요?`,
        firstButtonText: "닫기",
        secondButtonText: "확인",
        onConfirm: () => {
          hideModal();
          goBack();
        },
      });
    } else {
      goBack();
    }
  };

  const handleEditContent = () => {
    showConfirmModal({
      title: `저장하시겠어요?`,
      firstButtonText: "닫기",
      secondButtonText: "저장하기",
      onConfirm: () => {
        hideModal();
        formik?.handleSubmit();
      },
    });
  };

  const handleDeleteContent = () => {
    showConfirmModal({
      title: `삭제하시겠어요?`,
      firstButtonText: "닫기",
      secondButtonText: "삭제하기",
      onConfirm: () => {
        handleDelete(id);
        hideModal();
      },
    });
  };

  const isEditButtonValidate = useCallback(() => {
    if (!initData) return true;

    const {
      category,
      postStartAt,
      postEndAt,
      title,
      thumbnail,
      contentCategory,
      contentId,
      url,
      bannerExposureLocation,
    } = formik.values;

    const isContentUrl = contentCategory === "url";

    const isCompare = compareObjects(initData, formik.values);
    const urlRegex = regex.url;
    const isUrl = urlRegex.test(url);

    const hasEmptyFields =
      !title ||
      !category ||
      !postStartAt ||
      !postEndAt ||
      !thumbnail ||
      !contentCategory ||
      (isContentUrl ? !url || !isUrl : !contentId) ||
      (category === "banner" && !bannerExposureLocation.length);

    return isCompare || hasEmptyFields;
  }, [formik.values, initData]);

  useEffect(() => {
    if (!formik.values.contentCategory) return;

    PopupConnectionCategory.forEach((item) => {
      if (
        item.value ===
        connectionCategoryReverseChange(formik?.values.contentCategory)
      ) {
        setConnectionKindList(item);
      }
    });
  }, [formik.values.contentCategory]);

  useEffect(() => {
    const { values, setValues, setFieldValue, initialValues } = formik;
    const { category, url, title } = values;
    const defaultContentCategory = category === "banner" ? "event" : "notice";

    if (!initData) return;

    if (initData.category !== category) {
      setValues({
        ...initialValues,
        postStartAt: moment().format("YYYY-MM-DD"),
        category,
        contentCategory: defaultContentCategory,
      });
    }

    if (url || title) return;
    setFieldValue("contentCategory", defaultContentCategory);
  }, [formik.values.category, initData]);

  return (
    <ContentsWrap>
      <HeaderWrap>
        <IconBox
          width={32}
          height={32}
          src={ICONS.ARROW_ICON.BACK}
          isCursorPointer
          onClick={handleGoBackClick}
        />
        <HeaderTitle>{HEADER_TITLE}</HeaderTitle>
      </HeaderWrap>

      <div>
        <ContentBox style={{ padding: 0 }}>
          <ContentsHeader>{CONTENT_TITLE}</ContentsHeader>

          <ContentsMainWrap>
            {contentsOption?.map(
              (
                {
                  label,
                  name,
                  options,
                  hasKeywordToShowContent,
                  isSizeQuestion,
                  size,
                  ...res
                },
                _,
              ) => {
                if (hasKeywordToShowContent) {
                  if (formik.values.category === hasKeywordToShowContent) {
                    return (
                      <RenderBannerExposureLocationCheckbox
                        key={`${_}_contents`}
                        label={label}
                        formik={formik}
                        name={name}
                        checkBoxOptions={options as BasicOptionType[]}
                      />
                    );
                  }
                }

                if (!hasKeywordToShowContent) {
                  const bannerSizeCheck =
                    formik.values.category === "popup" ? size : "small";

                  return (
                    <React.Fragment key={`${_}_contents`}>
                      <InputWrap>
                        <LabelWrap>
                          <LabelInnerWrap>
                            <Label>{label || "LABEL"}</Label>
                            {isSizeQuestion && (
                              <SizeNoticeBox size={(size = bannerSizeCheck)} />
                            )}
                          </LabelInnerWrap>
                        </LabelWrap>
                        <InputContentsWrap>
                          {RenderCommonElementByType({
                            formik,
                            name,
                            options,
                            size: bannerSizeCheck,
                            ...res,
                          })}
                        </InputContentsWrap>
                      </InputWrap>
                    </React.Fragment>
                  );
                }
              },
            )}

            {RenderBannerConnectionList({
              connectionContentType: formik.values.category,
              formik,
              connectionKindList,
              setConnectionKindList,
            })}
          </ContentsMainWrap>
        </ContentBox>

        <FooterButtonWrap>
          <LeftButtonWrap>
            <Button
              text="삭제"
              buttonType="line_red"
              onClick={handleDeleteContent}
            />
          </LeftButtonWrap>
          <RightButtonWrap>
            <Button
              text="취소"
              buttonType="line_black"
              onClick={handleGoBackClick}
            />
            <Button
              disabled={isEditButtonValidate()}
              text="저장하기"
              onClick={handleEditContent}
            />
          </RightButtonWrap>
        </FooterButtonWrap>
      </div>
    </ContentsWrap>
  );
};

export default BannerDetail;
