import { TermsEditorModalProps } from "@components/molecules/Modal/TermsEditorModal";
import { ConnectionModalProps } from "@components/molecules/Modal/ConnectionModal";
import { EventButtonCreateModalProps } from "@components/molecules/Modal/EventButtonCreateModal";
import { OneButtonModalProps } from "@components/molecules/Modal/OneButtonModal";
import { ReasonEditModalProps } from "@components/molecules/Modal/ReasonEditModal";
import { ReasonModificationModalProps } from "@components/molecules/Modal/ReasonModificationModal";
import { TwoButtonModalProps } from "@components/molecules/Modal/TwoButtonModal";
import useGlobalModalStore, { GlobalModalType } from "@store/modal";
import { OrderManagementModalProps } from "@components/molecules/Modal/OrderManagementModal";
import { ManualOrderManagementModalProps } from "@components/molecules/Modal/ManualOrderManagementModal";
import { FAQOrderManagementModalProps } from "@components/molecules/Modal/FAQOrderManagementModal";

export default function useGlobalModal() {
  const setModal = useGlobalModalStore((state) => state.setGlobalModal);

  const showModal = ({ modalType, modalProps }: GlobalModalType) => {
    setModal({ modalType, modalProps });
  };
  const hideModal = () => {
    setModal({ modalType: null, modalProps: null });
    window.document.body.style.overflow = "unset";
  };

  const showConfirmModal = ({
    title,
    firstButtonText,
    secondButtonText,
    onCancel,
    onConfirm,
  }: TwoButtonModalProps) => {
    showModal({
      modalType: "TwoButtonModal",
      modalProps: {
        title,
        firstButtonText,
        secondButtonText,
        onCancel: () => (onCancel ? onCancel() : hideModal()),
        onConfirm: () => onConfirm?.(),
        handleClose: () => hideModal(),
      },
    });
  };

  const showAlertModal = ({
    title,
    buttonText,
    onConfirm,
    handleClose,
  }: OneButtonModalProps) => {
    showModal({
      modalType: "OneButtonModal",
      modalProps: {
        title,
        buttonText,
        onConfirm: () => (onConfirm ? onConfirm() : hideModal()),
        handleClose: () => (handleClose ? handleClose() : hideModal()),
      },
    });
  };

  const showOrderManagementModal = ({
    modalHeaderTitle,
    contentTitle,
    firstButtonText,
    secondButtonText,
    category,
  }: OrderManagementModalProps) => {
    showModal({
      modalType: "OrderManagementModal",
      modalProps: {
        modalHeaderTitle,
        contentTitle,
        firstButtonText,
        secondButtonText,
        category,
        handleClose: () => hideModal(),
      },
    });
  };

  const showManualOrderManagementModal = ({
    modalHeaderTitle,
    contentTitle,
    firstButtonText,
    secondButtonText,
    category,
  }: ManualOrderManagementModalProps) => {
    showModal({
      modalType: "ManualOrderManagementModal",
      modalProps: {
        modalHeaderTitle,
        contentTitle,
        firstButtonText,
        secondButtonText,
        category,
        handleClose: () => hideModal(),
      },
    });
  };

  const showConnectionModal = ({
    category,
    modalHeaderTitle,
    contentTitle,
    firstButtonText,
    secondButtonText,
    onClickFirstButton,
    onClickSecondButton,
    formik,
  }: ConnectionModalProps) => {
    showModal({
      modalType: "ConnectionModal",
      modalProps: {
        category,
        modalHeaderTitle,
        contentTitle,
        firstButtonText,
        secondButtonText,
        onClickFirstButton: () =>
          onClickFirstButton ? onClickFirstButton() : hideModal(),
        onClickSecondButton: () => onClickSecondButton?.(),
        handleClose: () => hideModal(),
        formik,
      },
    });
  };

  const showReasonModificationModal = ({
    category,
    updateHistory,
  }: ReasonModificationModalProps) => {
    showModal({
      modalType: "ReasonModificationModal",
      modalProps: {
        category,
        updateHistory,
      },
    });
  };

  const showReasonEditModal = ({
    category,
    onSave,
    onChangeReason,
  }: ReasonEditModalProps) => {
    showModal({
      modalType: "ReasonEditModal",
      modalProps: {
        category,
        onSave,
        onChangeReason,
      },
    });
  };

  const showEventButtonCreateModal = ({
    onCancel,
    hideModal,
    formik,
  }: EventButtonCreateModalProps) => {
    showModal({
      modalType: "EventButtonCreateModal",
      modalProps: {
        onCancel,
        formik,
        hideModal,
      },
    });
  };

  const showTermsEditorModal = ({
    title,
    modalType,
    termsId,
  }: TermsEditorModalProps) => {
    showModal({
      modalType: "TermsEditorModal",
      modalProps: {
        termsId,
        title,
        modalType,
      },
    });
  };

  const showFaqOrderManagementModal = ({
    onCancel,
    title,
    subTitle,
    type,
  }: FAQOrderManagementModalProps) => {
    showModal({
      modalType: "FAQOrderManagementModal",
      modalProps: {
        onCancel: onCancel ? onCancel : hideModal,
        title,
        subTitle,
        type,
      },
    });
  };

  return {
    showModal,
    hideModal,
    showConfirmModal,
    showAlertModal,
    showConnectionModal,
    showReasonEditModal,
    showReasonModificationModal,
    showEventButtonCreateModal,
    showOrderManagementModal,
    showManualOrderManagementModal,
    showTermsEditorModal,
    showFaqOrderManagementModal,
  };
}
