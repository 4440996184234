import { requestFetchFaqCategoryList } from "@apis/faq";
import { useState } from "react";
import { useQuery } from "react-query";
import { CommonOptionType } from "src/types/common";

export const useFetchFaqCategoryOption = (selectedCategoryCode?: string) => {
  const [categoryOption, setCategoryOption] = useState<CommonOptionType[]>([]);
  const initCategoryOption = { name: "전체", code: "" };

  useQuery(
    ["fetchFaqCategoryOption", selectedCategoryCode],
    () => requestFetchFaqCategoryList(selectedCategoryCode),
    {
      onSuccess: (data) => {
        const customOption: CommonOptionType[] = [
          initCategoryOption,
          ...data,
        ].map((item) => {
          return {
            label: item.name,
            value: item.code,
          };
        });
        if (customOption) setCategoryOption(customOption);
      },
      enabled: !!selectedCategoryCode,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return { categoryOption, setCategoryOption };
};
