import { FilterElementType } from "@utils/DynamicComponentUtils/index";
import {
  exposureStatusCheck,
  RequiredStateCheck,
  textFormat,
  upDataAtFormat,
} from "@utils/TableItemFormattingUtils";
import { ColDef } from "ag-grid-community";
import { CommonFilterDataType, CommonFormType } from "src/types/common";

export interface TermsInitialValues {
  title: string;
  isRequired: string;
  isExposed: string;
  isServiceTerms: string;
  isSignUpTerms: string;
}

export const termsData: CommonFilterDataType<
  TermsInitialValues,
  CommonFormType,
  ColDef
> = {
  formInitialValues: {
    title: "",
    isRequired: "",
    isExposed: "",
    isServiceTerms: "",
    isSignUpTerms: "",
  },
  forms: [
    {
      type: FilterElementType.INPUT,
      name: "title",
      label: "약관명",
    },
    {
      type: FilterElementType.SELECT,
      name: "isRequired",
      label: "필수 여부",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "필수",
          value: true,
        },
        {
          label: "선택",
          value: false,
        },
      ],
    },
    {
      type: FilterElementType.SELECT,
      name: "isSignUpTerms",
      label: "회원가입 노출",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "노출",
          value: true,
        },
        {
          label: "미노출",
          value: false,
        },
      ],
    },
    {
      type: FilterElementType.SELECT,
      name: "isServiceTerms",
      label: "서비스 약관 노출",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "노출",
          value: true,
        },
        {
          label: "미노출",
          value: false,
        },
      ],
    },
  ],
  tableList: [
    {
      field: "title",
      headerName: "약관명",
      width: 750,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "isRequired",
      headerName: "필수 여부",
      maxWidth: 165,
      flex: 1,
      suppressMovable: true,
      cellRenderer: RequiredStateCheck,
    },
    {
      field: "isSignupTerms",
      headerName: "회원가입 노출 상태",
      maxWidth: 165,
      flex: 1,
      suppressMovable: true,
      cellRenderer: exposureStatusCheck,
    },
    {
      field: "isServiceTerms",
      headerName: "서비스 약관 노출 상태",
      maxWidth: 165,
      flex: 1,
      suppressMovable: true,
      cellRenderer: exposureStatusCheck,
    },
    {
      field: "updatedAt",
      headerName: "업데이트 일시",
      minWidth: 180,
      maxWidth: 200,
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
    {
      field: "manager",
      headerName: "마지막 수정",
      pinned: "right",
      suppressMovable: true,
      maxWidth: 180,
      cellRenderer: textFormat,
    },
  ],
};
