import { ICONS } from "@assets/icons";
import IconBox from "@components/atoms/IconBox";
import { Home } from "@constants/urls";
import useRouter from "@hooks/useRouter";
import useAuthStore from "@store/auth";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

const Header: React.FC = () => {
  const { push } = useRouter();
  const userInfo = useAuthStore((state) => state.userInfo);
  return (
    <CommonHeader>
      <div
        className="logo-wrap"
        onClick={() => {
          push(Home);
        }}
      >
        <IconBox src={ICONS.EVERON_LOGO} isCursorPointer />
      </div>
      <div className="user-info">
        <UserName>{userInfo?.name || "에버온"}</UserName>
      </div>
    </CommonHeader>
  );
};

export default Header;

const CommonHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-bottom: 1px solid ${colors.GRAY9};

  .logo-wrap {
    width: 125px;
    height: 24px;
    cursor: pointer;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const UserName = styled.span`
  ${typo.HEADING_7}
  color: ${colors.GRAY2};
`;
