import { ICONS } from "@assets/icons";
import { colors, typo } from "@styles/index";

import styled, { css } from "styled-components";

export const LoginWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  max-width: 400px;
  height: 100vh;
  margin: auto;

  white-space: pre-wrap;
`;

export const HeaderWrap = styled.div`
  width: 400px;
  height: 132px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

export const SubTitle = styled.p`
  ${typo.HEADING_3}
  line-height: 140%;
  color: ${colors.GRAY5};
  text-align: center;
`;

export const LogoWrap = styled.div`
  width: 210px;
  height: 40px;
`;

export const CheckIconWrap = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const FormWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Label = styled.label`
  ${typo.HEADING_9}
  line-height: 20px;
  color: ${colors.GRAY6};
`;

export const LoginInfoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StayLoginWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  transition: 0.3s ease;
`;

export const StayLogin = styled.p`
  ${typo.BODY_9}
  color: ${colors.GRAY6}
`;

export const ForgetPassword = styled.p`
  color: ${colors.GRAY6};
  ${typo.BODY_9};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const RightArrowIcon = styled.span`
  display: inline-block;
  width: 8px;
  height: 13px;
  margin-left: 7px;
  background: no-repeat center/cover url(${ICONS.ARROW_ICON.RIGHT});
`;

export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

export const Copyright = styled.small`
  ${typo.BODY_10}
  color: ${colors.GRAY7}
`;

/*  */

export const ToggleIcon = styled.span<{ isToggleActive?: boolean }>`
  width: 24px;
  height: 24px;
  transition: 0.3s;
  background-position: center;
  background-repeat: no-repeat;

  ${({ isToggleActive }) => {
    if (isToggleActive) {
      return css`
        background: url(${ICONS.CHECK_ICON.TOGGLE_ACTIVE});
      `;
    } else {
      return css`
        background: url(${ICONS.CHECK_ICON.TOGGLE_INACTIVE});
      `;
    }
  }}
`;
