import request from "@network/index";
import { message } from "antd";
import { useCallback, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useQuery } from "react-query";
import BaseModal from "../BaseModal";
import TwoButtonModal from "../TwoButtonModal";
import { ModalType } from "@store/modal";
import {
  CloseIconWrap,
  ContentItemWrap,
  ContentsDataWrap,
  ContentsSideTitle,
  ContentTitle,
  ContentWrap,
  FooterButtonWrap,
  HeaderTitle,
  ModalContents,
  ModalFooter,
  ModalHeader,
} from "./style";
import useGlobalModal from "@hooks/useGlobalModal";
import { Rows } from "@apis/common/types";
import IconBox from "@components/atoms/IconBox";
import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import DataEmpty from "@components/atoms/DateEmpty";
import useGlobalNoti from "@hooks/useGlobalNoti";
import { ErrorResponseType } from "@network/types";

export interface ManualOrderManagementModalProps {
  modalHeaderTitle?: string;
  contentTitle?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  modalType?: ModalType;
  category?: "charger" | "charging-service";
  handleClose?: () => void;
}

interface DataType {
  id: number;
  title: string;
  category: string;
  isExposed: true;
  postStartAt: string;
  postEndAt: string;
  createdAt: string;
  updatedAt: string;
  displayOrder: number;
  manager?: string;
}

function ManualOrderManagementModal({
  modalType = "ManualOrderManagementModal",
  modalHeaderTitle,
  contentTitle,
  firstButtonText,
  secondButtonText,
  category,
  handleClose,
}: ManualOrderManagementModalProps) {
  const { hideModal } = useGlobalModal();
  const { showNoti } = useGlobalNoti();

  let url = "/manuals?category=charger&isExposed=true&sort=displayOrder,asc";
  if (category === "charging-service") {
    url = "/charging-service-manuals?category=charging-service&isExposed=true&sort=displayOrder,asc";
  }

  const { data: initDisPlayOrderData } = useQuery(
    "disPlayOrder",
    async () => {
      const { data } = await request<void, Rows<DataType>>({
        method: "get",
        url: url,
      });
      return data.rows;
    },
    {
      onSuccess: (data) => {
        setDisPlayOrderData(data);
      },
      onError: (error) => {
        setDisPlayOrderData([]);
      },
    },
  );

  const [disPlayOrderData, setDisPlayOrderData] = useState<DataType[]>([]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, draggableId, source } = result;

      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        source.index === destination.index
      )
        return;

      const newItems = Array.from(disPlayOrderData);

      const pickData = disPlayOrderData[source.index];
      newItems.splice(source.index, 1);
      newItems.splice(destination?.index, 0, pickData);
      setDisPlayOrderData(newItems);
    },
    [disPlayOrderData],
  );

  interface RequestBody {
    id: number;
  }

  const requestOrderChange = async (body: RequestBody[]) => {
    await request({
      method: "put",
      url: `/charging-service-manuals/display-order`,
      requestBody: {
        manualsDisplayOrder: body,
      },
    });
  };

  const [isSaveModal, setIsSaveModal] = useState(false);

  const onOrderChange = () => {
    setIsSaveModal(true);
  };

  // 노출 순번 변경 API 호출
  const handleBannerOrderSave = async () => {
    const body = disPlayOrderData.map((item) => {
      return {
        id: item.id,
      };
    });

    try {
      await requestOrderChange(body);
      hideModal();
      setIsSaveModal(false);
      showNoti({
        notiType: "ConfirmNoti",
        notiProps: {
          text: "노출 순번이 변경되었어요.",
        },
      });
    } catch (e) {
      const error = e as ErrorResponseType;
      alert(error.message);
      console.error("📕 >>>>>>>> DISPLAY ORDER ERROR : ", error);
    }
  };

  const [isCancelModal, setIsCancelModal] = useState(false);

  const onCloseModal = () => {
    let isOrderDisplayOrderDataSame = true;
    if (disPlayOrderData && initDisPlayOrderData) {
      if (disPlayOrderData.length === initDisPlayOrderData.length) {
        isOrderDisplayOrderDataSame = initDisPlayOrderData.every(
          (item, index) => item.id === disPlayOrderData[index].id,
        );
      }
    }

    if (isOrderDisplayOrderDataSame) {
      hideModal();
      setDisPlayOrderData([]);
    } else {
      setIsCancelModal(true);
    }
  };

  return (
    <>
      <BaseModal
        modalType={modalType}
        isOpen
        handleClose={onCloseModal}
        modalWidth={50}
        disableModalPadding
      >
        <div>
          <ModalHeader>
            <HeaderTitle>{modalHeaderTitle || "modalHeaderTitle"}</HeaderTitle>
            <CloseIconWrap>
              <IconBox
                src={ICONS.MODAL_ICON.CLOSE}
                width={24}
                height={24}
                isCursorPointer
                onClick={onCloseModal}
              />
            </CloseIconWrap>
          </ModalHeader>

          <ModalContents>
            {disPlayOrderData.length > 0 ? (
              <>
                <ContentsSideTitle>노출 순번</ContentsSideTitle>
                <ContentWrap>
                  <ContentTitle>
                    <span style={{ marginRight: 20 }}>NO.</span>
                    {contentTitle || "contentTitle"}
                  </ContentTitle>
                  {/* table? */}
                  {/* data 어떻게 움직일지,,,, */}
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                      droppableId="data-list"
                      // direction="horizontal"
                      // type="column"
                    >
                      {/* ul */}
                      {(provided) => {
                        return (
                          <ContentsDataWrap
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            maxHeight={62}
                          >
                            {disPlayOrderData.map(({ id, title }, idx) => {
                              return (
                                <Draggable
                                  draggableId={`${id}_Data_items`}
                                  key={`${id}_items`}
                                  index={idx}
                                >
                                  {(provided, snapshot) => {
                                    const INIT_POSIOTION_HEIGHT = 151;
                                    const ITEM_HEIGHT = 62;
                                    const ITEM_INDEX = idx;

                                    const ITEM_POSITION =
                                      INIT_POSIOTION_HEIGHT +
                                      ITEM_HEIGHT * ITEM_INDEX;

                                    return (
                                      <ContentItemWrap
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        isDragging={snapshot.isDragging}
                                        ref={provided.innerRef}
                                        style={{
                                          ...provided.draggableProps.style,
                                          top: `${ITEM_POSITION}px`,
                                          left: "178px",
                                        }}
                                      >
                                        <IconBox
                                          isCursorPointer
                                          src={ICONS.MODAL_ICON.DRAG_DROP}
                                          width={24}
                                          height={24}
                                        />
                                        <span className="itemNumber">
                                          {idx + 1}
                                        </span>
                                        <span className="itemContent">
                                          {title}
                                        </span>
                                      </ContentItemWrap>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ContentsDataWrap>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </ContentWrap>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DataEmpty />
              </div>
            )}
          </ModalContents>

          <ModalFooter>
            <FooterButtonWrap>
              <Button
                style={{ marginRight: 8 }}
                text={firstButtonText || "firstButtonText"}
                buttonSize="lager"
                buttonType="text"
                onClick={onCloseModal}
                // onClick={() => {
                //   hideModal();
                //   setDisPlayOrderData([]);
                // }}
              />
              <Button
                text={secondButtonText || "secondButtonText"}
                buttonSize="lager"
                onClick={onOrderChange}
              />
            </FooterButtonWrap>
          </ModalFooter>
        </div>
      </BaseModal>

      {isSaveModal && (
        <TwoButtonModal
          title="저장하시겠어요?"
          secondButtonText="저장하기"
          firstButtonText="취소하기"
          onConfirm={handleBannerOrderSave}
          onCancel={() => {
            setIsSaveModal(false);
          }}
          handleClose={() => {
            setIsSaveModal(false);
          }}
        />
      )}
      {isCancelModal && (
        <TwoButtonModal
          title={`작성한 내용을 저장하지 않고,\n취소하시겠어요?`}
          secondButtonText="확인"
          firstButtonText="닫기"
          onConfirm={() => {
            hideModal();
            setIsCancelModal(false);
            setDisPlayOrderData([]);
          }}
          onCancel={() => {
            setIsCancelModal(false);
          }}
          handleClose={() => {
            setIsCancelModal(false);
          }}
        />
      )}
    </>
  );
}

export default ManualOrderManagementModal;
