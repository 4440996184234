import { useBodyScrollLock } from "@hooks/useBodyScrollLock";
import useGlobalModalStore from "@store/modal";
import { useEffect } from "react";
import OneButtonModal from "../OneButtonModal";
import TwoButtonModal from "../TwoButtonModal";
import ConnectionModal from "../ConnectionModal";
import ReasonEditModal from "../ReasonEditModal";
import ReasonModificationModal from "../ReasonModificationModal";
import EventButtonCreateModal from "../EventButtonCreateModal";
import TermsEditorModal from "../TermsEditorModal";
import OrderManagementModal from "../OrderManagementModal";
import ManualOrderManagementModal from "../ManualOrderManagementModal";
import FAQOrderManagementModal from "../FAQOrderManagementModal";

const MODAL_TYPES = {
  OneButtonModal: "OneButtonModal",
  TwoButtonModal: "TwoButtonModal",
  OrderManagementModal: "OrderManagementModal",
  ManualOrderManagementModal: "ManualOrderManagementModal",
  ConnectionModal: "ConnectionModal",
  TermsEditorModal: "TermsEditorModal",
  ReasonEditModal: "ReasonEditModal",
  ReasonModificationModal: "ReasonModificationModal",
  EventButtonCreateModal: "EventButtonCreateModal",
  FAQOrderManagementModal: "FAQOrderManagementModal",
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.OneButtonModal]: OneButtonModal,
  [MODAL_TYPES.TwoButtonModal]: TwoButtonModal,
  [MODAL_TYPES.ConnectionModal]: ConnectionModal,
  [MODAL_TYPES.ReasonEditModal]: ReasonEditModal,
  [MODAL_TYPES.ReasonModificationModal]: ReasonModificationModal,
  [MODAL_TYPES.EventButtonCreateModal]: EventButtonCreateModal,
  [MODAL_TYPES.TermsEditorModal]: TermsEditorModal,
  [MODAL_TYPES.OrderManagementModal]: OrderManagementModal,
  [MODAL_TYPES.ManualOrderManagementModal]: ManualOrderManagementModal,
  [MODAL_TYPES.FAQOrderManagementModal]: FAQOrderManagementModal,
};

const GlobalModal: React.FC = () => {
  const {
    globalModal: { modalProps, modalType },
  } = useGlobalModalStore();

  const { lockScroll, openScroll } = useBodyScrollLock();
  const isScroll = document.body.style.overflow;

  useEffect(() => {
    if (isScroll === "hidden") return;
    if (modalType) {
      lockScroll();
    } else {
      openScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }
    const ModalComponent = MODAL_COMPONENTS[modalType];
    return <ModalComponent {...modalProps} />;
  };

  return <>{renderComponent()}</>;
};

export default GlobalModal;
