import { BannerListResponse } from "@apis/banner/types";
import { colors } from "@styles/index";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { FormikContextType } from "formik";
import { useRef } from "react";
import styled from "styled-components";
import DataEmpty from "../DateEmpty";

interface TableProps<T> {
  currentPageSize?: number;
  rowData: T[];
  tableContentsInfoData: ColDef[];
  minHeight?: number;
  formik?: FormikContextType<any>;
  onDetailClick?: (id: number) => void;
  onTermsDetailClick?: (category: string) => void;
  setSelectData?: (data: BannerListResponse) => void;
  setIsMismatchModal?: (value: React.SetStateAction<boolean>) => void;
  setIsMissingModal?: (value: React.SetStateAction<boolean>) => void;
}

const Table = <T extends {}>({
  currentPageSize,
  minHeight = 331,
  rowData,
  tableContentsInfoData,
  formik,
  onDetailClick,
  onTermsDetailClick,
  setIsMismatchModal,
  setIsMissingModal,
  setSelectData,
}: TableProps<T>) => {
  const gridRef = useRef<AgGridReact<T>>(null);
  const PAGE_SIZE = 10;
  const ROW_HEIGHT = 48;

  const tableHeight = () => {
    if (!rowData || !currentPageSize) {
      return;
    }

    const ROW_HEIGHT = 48;
    const HEADER_HEIGHT = 50;
    const dataLength = rowData.length;

    const height = dataLength * ROW_HEIGHT + HEADER_HEIGHT;
    return `${height}px`;
  };

  return (
    <TableWrap
      className="ag-theme-alpine"
      style={{
        width: "100%",
        height: `${tableHeight()}`,
        margin: "0 auto",
        minHeight: `${minHeight}px`,
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        rowHeight={ROW_HEIGHT}
        suppressCellFocus
        suppressDragLeaveHidesColumns
        paginationAutoPageSize={true}
        paginationPageSize={currentPageSize || PAGE_SIZE}
        noRowsOverlayComponent={DataEmpty}
        overlayLoadingTemplate={"로딩중입니다..."}
        defaultColDef={{
          resizable: false,
          suppressMenu: false,
          flex: 1,
          sortable: false,
          cellStyle: {
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            fontWeight: 400,
            color: colors.GRAY2,
            lineHeight: "20px",
          },
        }}
      >
        {tableContentsInfoData?.map(
          ({ field, pinned, minWidth, headerName, ...res }, idx) => {
            return (
              <AgGridColumn
                lockPinned={true}
                key={idx + 10}
                field={field}
                pinned={pinned ? pinned : false}
                minWidth={minWidth}
                headerName={headerName}
                onCellClicked={(e: CellClickedEvent<any, any>) => {
                  if (onDetailClick) onDetailClick(e.data.id);
                  if (onTermsDetailClick) onTermsDetailClick(e.data.category);

                  // 배너 연결 목록 아이템 클릭시
                  if (
                    setSelectData &&
                    setIsMismatchModal &&
                    setIsMissingModal
                  ) {
                    if (!formik?.values) return;
                    const { postStartAt, postEndAt } = formik.values;

                    if (!postEndAt) {
                      setIsMissingModal(true);
                    }

                    // 게시 기간 비교 로직
                    const isPostStartAt = postStartAt >= e.data.postStartAt;
                    const isPostEndAt = postEndAt <= e.data.postEndAt;

                    if (!isPostStartAt || !isPostEndAt) {
                      setIsMismatchModal(true);
                    } else if (isPostStartAt && isPostEndAt) {
                      setSelectData(e.data);
                    }
                  }
                }}
                {...res}
              />
            );
          },
        )}
      </AgGridReact>
    </TableWrap>
  );
};

export default Table;

export const TableWrap = styled.div`
  /* pass */
  width: 100%;
  height: 100%;

  .exposure {
    position: relative;
    color: ${colors.PRIMARY};

    ::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background: ${colors.PRIMARY};
      position: absolute;
      left: 0px;
      border-radius: 50%;
    }
  }

  .unExposure {
    position: relative;
    color: ${colors.GRAY6};

    ::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background: ${colors.GRAY6};
      position: absolute;
      left: 0px;
      border-radius: 50%;
    }
  }
`;
