import { Row } from "@apis/common/types";
import request from "@network/index";
import { ResponseAuth } from "./types";

// token 발급
export const requestTokenGet = async (body: string) => {
  await request<{ code: string }, void>({
    method: "post",
    url: "/auth",
    requestBody: {
      code: body,
    },
  });
};

// user 정보
export const requestUserInfo = async () => {
  const { data } = await request<void, Row<ResponseAuth>>({
    method: "get",
    url: "/auth/session",
  });
  return data.row;
};
