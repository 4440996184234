import Button from "@components/atoms/Button";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";
import Editor from "../Editor";
import { ConnectionSelectedItem } from "../ConnectionSelectItem";
import Input from "@components/atoms/Input";
import { FormikContextType } from "formik";
import useGlobalModal from "@hooks/useGlobalModal";

interface RenderEventCategoryContentProps {
  formik: FormikContextType<any>;
  category: string;
}

const RenderEventCategoryContent: React.FC<RenderEventCategoryContentProps> = ({
  formik,
  category,
}) => {
  const { hideModal, showEventButtonCreateModal } = useGlobalModal();

  const connectionUrlButtonDelete = (idx: number) => {
    const urls = formik.values.urls.filter(
      (_: any, index: any) => index !== idx,
    );

    if (urls.length === 0) formik.setFieldValue("urls", undefined);
    formik.setFieldValue("urls", urls);
  };

  switch (category) {
    case "button": {
      return (
        <>
          {formik.values?.urls && formik.values.urls?.length !== 0 ? (
            <>
              <Button
                style={{ width: "fit-content", marginLeft: "212px" }}
                buttonType="line_primary"
                text="버튼 수정하기"
                buttonSize="small"
                onClick={() => {
                  showEventButtonCreateModal({
                    onCancel: () => {
                      // 얼럿으로 한번 경고후 취소 진행.
                      hideModal();
                    },
                    hideModal,
                    formik,
                  });
                }}
              />
              <InputWrap>
                <Label style={{ minWidth: "200px", paddingTop: "18px" }}>
                  이벤트 버튼
                </Label>
                <ConnectionSelectItemWrap>
                  {formik.values?.urls.map(
                    (
                      { title, url }: { title: string; url: string },
                      idx: number,
                    ) => {
                      return (
                        <ConnectionSelectedItem
                          key={`${title}_${idx}`}
                          title={title}
                          url={url}
                          onClick={() => {
                            connectionUrlButtonDelete(idx);
                          }}
                        />
                      );
                    },
                  )}
                </ConnectionSelectItemWrap>
              </InputWrap>
            </>
          ) : (
            // 이벤트 버튼 생성 하기
            <Button
              style={{ width: "fit-content", marginLeft: "212px" }}
              buttonType="line_primary"
              text="버튼 생성하기"
              buttonSize="small"
              onClick={() => {
                showEventButtonCreateModal({
                  onCancel: () => {
                    // 얼럿으로 한번 경고후 취소 진행.
                    hideModal();
                  },
                  formik,
                  hideModal,
                });
              }}
            />
          )}

          <Editor formik={formik} initData={formik.values.content || ""} />
        </>
      );
    }
    case "url": {
      return (
        <StyledDiv>
          <Input
            inputType="create"
            id="url"
            name="url"
            onChange={formik?.handleChange}
            value={formik?.values.url}
            full
            placeholder={"URL을 입력해주세요."}
          />
        </StyledDiv>
      );
    }
    case "content": {
      return <Editor formik={formik} initData={formik.values.content || ""} />;
    }
    default:
      return null;
  }
};

export default RenderEventCategoryContent;

export const InputWrap = styled.div`
  display: flex;
  gap: 12px;
  min-height: 48px;
  width: 100%;
`;

export const Label = styled.p`
  color: ${colors.GRAY6};
  ${typo.HEADING_7};
`;

const ConnectionSelectItemWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledDiv = styled.div`
  padding-left: 212px;
`;
