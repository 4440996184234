import { ICONS } from "@assets/icons";
import IconBox from "@components/atoms/IconBox";
import { colors, typo } from "@styles/index";
import { stringToDateFormatter } from "@utils/string-utils";

import React from "react";
import styled from "styled-components";

interface PublishPeriodNotificationProps {
  contentStartAt?: string;
  contentEndAt?: string;
}

const PublishPeriodNotification: React.FC<PublishPeriodNotificationProps> = ({
  contentStartAt = "2023.05.11",
  contentEndAt = "2023.06.12",
}) => {
  return (
    <Notification>
      <IconBox src={ICONS.NOTI_ICON.NOTICE_GRAY} width={14} height={14} />{" "}
      <span>
        연결된 컨텐츠의 게시기간 : {stringToDateFormatter(contentStartAt)} ~{" "}
        {stringToDateFormatter(contentEndAt)}
      </span>
    </Notification>
  );
};

export default PublishPeriodNotification;

const Notification = styled.div`
  ${typo.BODY_10};
  color: ${colors.GRAY6};
  display: flex;
  align-items: center;
  gap: 4px;
`;
