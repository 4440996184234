import { BannerListResponse } from "@apis/banner/types";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import Table from "@components/atoms/Table";
import Filter from "@components/molecules/Filter";
import { PrivateRoutes } from "@constants/urls";
import { useFetchList } from "@hooks/useFetchList";
import useGlobalModal from "@hooks/useGlobalModal";
import { colors, typo } from "@styles/index";
import { stringToDateTimerFormatter } from "@utils/string-utils";
import { Pagination } from "antd";
import React from "react";
import styled from "styled-components";
import { BannerInitialValues, bannerData } from "./Data";

type Type = Omit<BannerListResponse, "postStartAt" | "postEndAt">;
interface BannerTableListDataType extends Type {
  postDataAt: string;
}

const BannerManagement: React.FC = () => {
  // Data
  const filter = bannerData.forms;
  const formInitialValues = bannerData.formInitialValues;
  const tableContentsInfoData = bannerData.tableList;

  // Props
  const MAIN_TITLE = "팝업/배너 관리";
  const TABLE_TITLE = "팝업/배너 목록";
  const FILTER_GIRD_COLUMN = 2;
  const tableButtonText = "배너 등록";

  // Hook Props
  const QUERY_KEY = "bannerList";
  const URL = "/banners";
  const DETAIL_PATH = PrivateRoutes.BannerDetail;
  const CREATE_PATH = PrivateRoutes.BannerCreate;

  const {
    push,
    listData,
    formik,
    page,
    setPage,
    pageSize,
    totalCount,
    handleChangePageSize,
    handleFormReset,
  } = useFetchList<BannerListResponse, BannerInitialValues>({
    url: URL,
    queryKey: QUERY_KEY,
    formInitialValues: formInitialValues,
  });
  const { showOrderManagementModal } = useGlobalModal();

  /** 작성 페이지 이동 */
  const onCreateClick = () => {
    push(CREATE_PATH);
  };

  /** 상세 페이지 이동 id */
  const onDetailClick = (id: number | string) => {
    push(`${DETAIL_PATH}/${id}`);
  };

  /** 데이터 업데이트를 하지 않았을 경우 createdAt과 updatedAt이 같은 값이 나오기 때문에 createAt으로 통일 */
  const bannerTableListData: BannerTableListDataType[] = listData?.map(
    (item) => {
      const { postStartAt, postEndAt, ...rest } = item;
      return {
        ...rest,
        postDataAt: `${stringToDateTimerFormatter(
          postStartAt,
        )} ~ ${stringToDateTimerFormatter(postEndAt)}`,
      };
    },
  );

  return (
    <ContentsWrap>
      <MainHeaderTitle>{MAIN_TITLE}</MainHeaderTitle>

      <ContentBox>
        <Filter
          formik={formik}
          filterData={filter}
          girdColumn={FILTER_GIRD_COLUMN}
          formInitialValues={formInitialValues}
          handleFormReset={handleFormReset}
        />
      </ContentBox>

      <ContentBoxWithHeader>
        <TableHeader>
          <TableTitle>{TABLE_TITLE}</TableTitle>
          <HeaderButtonWrap>
            <Button
              buttonSize="small"
              buttonType="line_black"
              text="팝업 노출 순번 관리"
              onClick={() => {
                if (bannerTableListData && bannerTableListData?.length > 0)
                  showOrderManagementModal({
                    modalHeaderTitle: "팝업 노출 순번 관리",
                    contentTitle: "팝업 노출 목록",
                    firstButtonText: "닫기",
                    secondButtonText: "저장",
                    category: "popup",
                  });
              }}
            />

            <Button
              buttonSize="small"
              buttonType="line_black"
              text="홈 띠 배너 순번관리"
              onClick={() => {
                if (bannerTableListData && bannerTableListData?.length > 0)
                  showOrderManagementModal({
                    modalHeaderTitle: "홈 띠 배너 순번 관리",
                    contentTitle: "홈 띠 배너 노출 목록",
                    firstButtonText: "닫기",
                    secondButtonText: "저장",
                    category: "banner",
                  });
              }}
            />

            <Button
              buttonSize="small"
              text={tableButtonText || "tableButtonText"}
              onClick={onCreateClick}
            />
          </HeaderButtonWrap>
        </TableHeader>

        <Table<BannerTableListDataType>
          rowData={bannerTableListData}
          tableContentsInfoData={tableContentsInfoData}
          currentPageSize={pageSize}
          onDetailClick={onDetailClick}
        />

        {/* pageNation */}
        <TableBottomWrap>
          <ContentsTotal>
            총<span>{totalCount}</span>
          </ContentsTotal>
          <PageNationWrap>
            <Pagination
              defaultCurrent={page}
              defaultPageSize={pageSize}
              total={totalCount}
              showSizeChanger
              locale={{ items_per_page: "개씩 보기" }}
              current={page}
              pageSize={pageSize}
              onShowSizeChange={handleChangePageSize}
              onChange={setPage}
            />
          </PageNationWrap>
        </TableBottomWrap>
      </ContentBoxWithHeader>
    </ContentsWrap>
  );
};

export default BannerManagement;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

/** 두번째 컨테이너 스타일 */
const ContentBoxWithHeader = styled.div`
  margin-top: 20px;
  padding: 22px 20px;
  background: ${colors.WHITE};
  border-radius: 16px;
  border: 1px solid ${colors.GRAY9};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderButtonWrap = styled.div`
  display: flex;
  max-height: 32px;
  gap: 8px;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const TableBottomWrap = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ContentsTotal = styled.span`
  display: flex;
  gap: 8px;
  color: ${colors.GRAY6};
  font-weight: 500;
  ${typo.BODY_8};

  span {
    ${typo.BODY_8};
    font-weight: 500;
    color: ${colors.GRAY2};
  }
`;

const PageNationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
