import EVENT_HEADER from "./images/eventHeader.png";
import PHONE_HEADER from "./images/phoneHeader.png";
import NOTICE_HEADER from "./images/noticeHeader.png";
import PHONE_BACKGROUND from "./images/phoneBackground.png";
import CHARGER_HEADER from "./images/chargerHeader.png";
import CHARGER_PHONE_HEADER from "./images/chargerPhoneHeader.png";
import CHARGING_SERVICE_HEADER from "./images/chargingServiceHeader.png";

export const IMAGES = {
  EVENT_HEADER,
  PHONE_HEADER,
  NOTICE_HEADER,
  PHONE_BACKGROUND,
  CHARGER_HEADER,
  CHARGER_PHONE_HEADER,
  CHARGING_SERVICE_HEADER,
};
