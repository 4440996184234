/* eslint-disable react-hooks/rules-of-hooks */
import Button from "@components/atoms/Button";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalNoti from "@hooks/useGlobalNoti";
import useTermsDataStore from "@store/terms";
import { CommonElementType } from "@utils/DynamicComponentUtils";
import { textFormat, upDataAtFormat } from "@utils/TableItemFormattingUtils";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import {
  CommonContentsRenderType,
  CommonFilterDataType,
} from "src/types/common";

export interface TermsDetailInitialValues {
  title: string;
  isRequired: string;
  isExposed: string;
  isServiceTerms: string;
  isSignupTerms: string;
  postStartAt: string;
  postEndAt: string;
  content: string;
  category: string;
}

export const TermsDetailData: CommonFilterDataType<
  TermsDetailInitialValues,
  CommonContentsRenderType,
  ColDef<any>
> = {
  formInitialValues: {
    title: "",
    isRequired: "",
    isExposed: "",
    isServiceTerms: "",
    isSignupTerms: "",
    postStartAt: "",
    postEndAt: "",
    content: "",
    category: "",
  },

  forms: [
    {
      type: CommonElementType.INFO_INPUT,
      name: "title",
      label: "약관명",
    },
    {
      type: CommonElementType.INFO_INPUT,
      name: "isRequired",
      label: "필수 여부",
    },
    {
      type: CommonElementType.INFO_INPUT,
      name: "isSignupTerms",
      label: "회원가입 노출",
    },
    {
      type: CommonElementType.INFO_INPUT,
      name: "isServiceTerms",
      label: "서비스 약관 노출",
    },
  ],
  tableList: [
    {
      field: "postDataAt",
      headerName: "게시 기간",
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "updatedAt",
      headerName: "등록 날짜",
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
    {
      field: "manager",
      headerName: "마지막 수정",
      suppressMovable: true,
      flex: 1,
      cellRenderer: textFormat,
    },
    {
      field: "id",
      headerName: "관리",
      pinned: "right",
      suppressMovable: true,
      maxWidth: 100,
      cellRenderer: ({ value }: ICellRendererParams) => {
        const { category } = useTermsDataStore((state) => state.termsData);
        const { showTermsEditorModal } = useGlobalModal();
        const { hideNoti } = useGlobalNoti();

        const onTermsEditorOpenModal = () => {
          hideNoti();
          showTermsEditorModal({
            title: termsCategoryCheck(category as TermsCategoryType),
            termsId: value as number,
          });
        };

        return (
          <Button
            buttonSize="small"
            buttonType="line_black"
            text="상세보기"
            style={{ padding: "8px" }}
            onClick={onTermsEditorOpenModal}
          />
        );
      },
    },
  ],
};

export type TermsCategoryType =
  | "service-use-policy"
  | "privacy-policy"
  | "service-notification"
  | "marketing-notification"
  | "marketing-target"
  | "privacy-to-third-party"
  | "";

export const termsCategoryCheck = (category: TermsCategoryType) => {
  switch (category) {
    case "service-use-policy":
      return "회원 이용 약관";
    case "privacy-policy":
      return "개인정보 처리방침";
    case "service-notification":
      return "서비스 푸시 알림";
    case "marketing-notification":
      return "마케팅 정보 수신";
    case "marketing-target":
      return "개인정보 수집 및 마케팅 활용";
    case "privacy-to-third-party":
      return "자동 조회 서비스 동의";
    default:
      return "알 수 없음";
  }
};
