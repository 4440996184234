import { ICONS } from "@assets/icons";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";
import IconBox from "../IconBox";

interface DataEmptyProps {
  style?: React.CSSProperties;
}

const DataEmpty: React.FC<DataEmptyProps> = (props) => {
  return (
    <Wrap {...props}>
      <IconBox
        src={ICONS.DATE_ICON.EMPTY_PLACEHOLDER_ICON}
        width={56}
        height={56}
      />
      <Text>데이터가 존재하지 않습니다.</Text>
    </Wrap>
  );
};

export default DataEmpty;

const Wrap = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Text = styled.p`
  color: ${colors.GRAY7};
  ${typo.BODY_8}
`;
