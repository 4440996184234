import * as XLSX from "xlsx";
import FileSaver from "file-saver";

interface ExcelData {
  fileName: string;
  header: string[];
  data: (string | number | any)[];
  colsWidth: number[];
}

/**
 * @description 엑셀 다운로드 함수
 * @param {string} fileName - 파일 이름
 * @param {string[]} header - 엑셀 헤더
 * @param {number[]} colsWidth - 엑셀 열 너비 ( 헤더와 동일한 순서로 너비를 넣어야 함 )
 * @param {(string | number)[]} data - 엑셀 데이터 ( 헤더와 동일한 순서로 데이터를 넣어야 함 )
 */
export const excelDownload = ({
  fileName,
  header,
  data,
  colsWidth,
}: ExcelData) => {
  // 엑셀 파일의 타입 설정
  const FILE_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  // 엑셀 파일의 확장자
  const FILE_EXTENSION = ".xlsx";

  // 엑셀 파일의 이름
  const FILE_NAME = fileName;

  // 결과 데이터 배열을 초기화하고 헤더 데이터를 추가
  const result = [header];

  // 객체에서 값만 추출하는 함수
  const extractValues = (obj: any) => Object.values(obj);

  // 초기 데이터 배열을 이용하여 데이터 배열 생성
  const makeDataArray = (initExcelData: any) =>
    initExcelData.map(extractValues);

  // 데이터 배열 생성 및 결과 배열에 추가
  result.push(...makeDataArray(data));

  // 결과 배열을 이용하여 시트 생성
  const ws = XLSX.utils.aoa_to_sheet(result);

  // 열의 너비 설정
  const wscols = colsWidth.map((width) => ({ wpx: width }));
  ws["!cols"] = [...wscols];

  // 워크북 생성
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  // 워크북을 이용하여 엑셀 파일 생성
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // 생성된 엑셀 파일 다운로드
  const excelData = new Blob([excelBuffer], { type: FILE_TYPE });
  FileSaver?.saveAs(excelData, FILE_NAME + FILE_EXTENSION);
};
