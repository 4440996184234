import { ChargerDescriptionListResponse } from "@apis/chargerDescription/types";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import Table from "@components/atoms/Table";
import ExcelButton from "@components/molecules/ExcelButton";
import Filter from "@components/molecules/Filter";
import { ApiUrls } from "@constants/api-urls";
import { PrivateRoutes } from "@constants/urls";
import { useFetchList } from "@hooks/useFetchList";
import useGlobalModal from "@hooks/useGlobalModal";
import { colors, typo } from "@styles/index";
import { Pagination } from "antd";
import React from "react";
import styled from "styled-components";
import {
  ChargerDescriptionData,
  ChargerDescriptionInitialValues,
} from "./Data";
import moment from "moment";
import { excelDownload } from "@utils/excel-download";

type ChargerDescriptionTableListDataType = Omit<
  ChargerDescriptionListResponse,
  "createdAt"
>;

const ChargerDescriptionManagement: React.FC = () => {
  // Data
  const filter = ChargerDescriptionData.forms;
  const formInitialValues = ChargerDescriptionData.formInitialValues;
  const tableContentsInfoData = ChargerDescriptionData.tableList;

  // Props
  const MAIN_TITLE = "충전기 사용방법 관리";
  const TABLE_TITLE = "충전기 사용방법 목록";
  const FILTER_GIRD_COLUMN = 2;
  const tableButtonText = "충전기 사용방법 등록";

  // Hooks
  const QUERY_KEY = "chargerList";
  const URL = ApiUrls.chargerDescription;
  const DETAIL_PATH = PrivateRoutes.ChargerDescriptionDetail;
  const CREATE_PATH = PrivateRoutes.ChargerDescriptionCreate;

  const {
    push,
    listData,
    formik,
    page,
    setPage,
    pageSize,
    totalCount,
    handleChangePageSize,
    handleFormReset,
  } = useFetchList<
    ChargerDescriptionListResponse,
    ChargerDescriptionInitialValues
  >({
    url: URL,
    queryKey: QUERY_KEY,
    formInitialValues: formInitialValues,
  });
  const { showManualOrderManagementModal } = useGlobalModal();

  /** 작성 페이지 이동 */
  const onCreateClick = () => {
    push(CREATE_PATH);
  };

  /** 상세 페이지 이동 id */
  const onDetailClick = (id: number | string) => {
    push(`${DETAIL_PATH}/${id}`);
  };

  const ChargerDescriptionTableListData: ChargerDescriptionTableListDataType[] =
    listData?.map((item) => {
      const { createdAt, updatedAt, ...rest } = item;
      return {
        ...rest,
        updatedAt: updatedAt ?? createdAt,
      };
    });

  const onExcelDownload = () => {
    const header = ["제목", "노출 상태", "업데이트 일시", "관리자명"];

    // 엑셀 너비 지정
    const colsWidth = [200, 100, 150, 150];

    const excelData = listData?.map((data) => {
      return {
        title: data.title,
        isExposed: data.isExposed ? "노출" : "비노출",
        updatedAt: data.updatedAt
          ? moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss")
          : moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        manger: data.manager ?? "-",
      };
    });

    if (excelData)
      excelDownload({
        fileName: "충전기 사용방법 관리",
        header,
        data: excelData,
        colsWidth,
      });
  };

  return (
    <ContentsWrap>
      <MainHeaderTitle>{MAIN_TITLE}</MainHeaderTitle>

      <ContentBox>
        <Filter
          formik={formik}
          filterData={filter}
          girdColumn={FILTER_GIRD_COLUMN}
          formInitialValues={formInitialValues}
          handleFormReset={handleFormReset}
        />
      </ContentBox>

      <ContentBoxWithHeader>
        <TableHeader>
          <TableTitle>{TABLE_TITLE}</TableTitle>
          <HeaderButtonWrap>
            <Button
              buttonSize="small"
              buttonType="line_black"
              text="콘텐츠 순번관리"
              onClick={() => {
                if (
                  ChargerDescriptionTableListData &&
                  ChargerDescriptionTableListData?.length > 0
                )
                  showManualOrderManagementModal({
                    modalHeaderTitle: "콘텐츠 순번관리",
                    contentTitle: "콘텐츠 노출 목록",
                    firstButtonText: "닫기",
                    secondButtonText: "저장",
                    category: "charger",
                  });
              }}
            />

            <ExcelButton onClick={() => onExcelDownload()} />

            <Button
              buttonSize="small"
              text={tableButtonText || "tableButtonText"}
              onClick={onCreateClick}
            />
          </HeaderButtonWrap>
        </TableHeader>

        <Table<ChargerDescriptionTableListDataType>
          rowData={ChargerDescriptionTableListData}
          tableContentsInfoData={tableContentsInfoData}
          currentPageSize={pageSize}
          onDetailClick={onDetailClick}
        />

        {/* pageNation */}
        <TableBottomWrap>
          <ContentsTotal>
            총<span>{totalCount}</span>
          </ContentsTotal>
          <PageNationWrap>
            <Pagination
              defaultCurrent={page}
              defaultPageSize={pageSize}
              total={totalCount}
              showSizeChanger
              locale={{ items_per_page: "개씩 보기" }}
              current={page}
              pageSize={pageSize}
              onShowSizeChange={handleChangePageSize}
              onChange={setPage}
            />
          </PageNationWrap>
        </TableBottomWrap>
      </ContentBoxWithHeader>
    </ContentsWrap>
  );
};

export default ChargerDescriptionManagement;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const ContentBoxWithHeader = styled.div`
  margin-top: 20px;
  padding: 22px 20px;
  background: ${colors.WHITE};
  border-radius: 16px;
  border: 1px solid ${colors.GRAY9};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderButtonWrap = styled.div`
  display: flex;
  max-height: 32px;
  gap: 8px;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const TableBottomWrap = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ContentsTotal = styled.span`
  display: flex;
  gap: 8px;
  color: ${colors.GRAY6};
  font-weight: 500;
  ${typo.BODY_8};

  span {
    ${typo.BODY_8};
    font-weight: 500;
    color: ${colors.GRAY2};
  }
`;

const PageNationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
