import { AppVersionResponse } from "@apis/appVersion/types";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import Table from "@components/atoms/Table";
import ExcelButton from "@components/molecules/ExcelButton";
import Filter from "@components/molecules/Filter";
import { ApiUrls } from "@constants/api-urls";
import { PrivateRoutes } from "@constants/urls";
import { useFetchList } from "@hooks/useFetchList";
import { colors, typo } from "@styles/index";
import { Pagination } from "antd";
import React from "react";
import styled from "styled-components";
import { AppVersionInitialValues, appVersionData } from "./Data";

type AppVersionTableListDataType = Omit<AppVersionResponse, "createdAt">;

const AppVersionManageMent: React.FC = () => {
  // Data
  const filter = appVersionData.forms;
  const formInitialValues = appVersionData.formInitialValues;
  const tableContentsInfoData = appVersionData.tableList;

  // Props
  const MAIN_TITLE = "앱 버전 관리";
  const TABLE_TITLE = "앱 버전 목록";
  const FILTER_GIRD_COLUMN = 1;
  const tableButtonText = "앱 버전 등록";

  const onExcelDownload = () => {};

  // Hooks
  const QUERY_KEY = "appVersionList";
  const URL = ApiUrls.appVersion;
  const DETAIL_PATH = PrivateRoutes.AppVersionDetail;
  const CREATE_PATH = PrivateRoutes.AppVersionCreate;

  const {
    push,
    listData,
    formik,
    page,
    setPage,
    pageSize,
    totalCount,
    handleChangePageSize,
    handleFormReset,
  } = useFetchList<AppVersionResponse, AppVersionInitialValues>({
    url: URL,
    queryKey: QUERY_KEY,
    formInitialValues: formInitialValues,
  });

  /** 작성 페이지 이동 */
  const onCreateClick = () => {
    push(CREATE_PATH);
  };

  /** 상세 페이지 이동 id */
  const onDetailClick = (id: number | string) => {
    push(`${DETAIL_PATH}/${id}`);
  };

  const appVersionTableListData: AppVersionTableListDataType[] = listData?.map(
    (item) => {
      const { updatedAt, createdAt, ...rest } = item;
      return {
        ...rest,
        updatedAt: updatedAt ?? createdAt,
      };
    },
  );

  return (
    <ContentsWrap>
      <MainHeaderTitle>{MAIN_TITLE}</MainHeaderTitle>

      <ContentBox>
        <Filter
          formik={formik}
          filterData={filter}
          girdColumn={FILTER_GIRD_COLUMN}
          formInitialValues={formInitialValues}
          handleFormReset={handleFormReset}
        />
      </ContentBox>

      <ContentBoxWithHeader>
        <TableHeader>
          <TableTitle>{TABLE_TITLE}</TableTitle>
          <HeaderButtonWrap>
            <ExcelButton onClick={() => onExcelDownload()} />

            <Button
              buttonSize="small"
              text={tableButtonText || "tableButtonText"}
              onClick={onCreateClick}
            />
          </HeaderButtonWrap>
        </TableHeader>

        <Table<AppVersionTableListDataType>
          rowData={appVersionTableListData}
          tableContentsInfoData={tableContentsInfoData}
          currentPageSize={pageSize}
          onDetailClick={onDetailClick}
        />

        {/* pageNation */}
        <TableBottomWrap>
          <ContentsTotal>
            총<span>{totalCount}</span>
          </ContentsTotal>
          <PageNationWrap>
            <Pagination
              defaultCurrent={page}
              defaultPageSize={pageSize}
              total={totalCount}
              showSizeChanger
              locale={{ items_per_page: "개씩 보기" }}
              current={page}
              pageSize={pageSize}
              onShowSizeChange={handleChangePageSize}
              onChange={setPage}
            />
          </PageNationWrap>
        </TableBottomWrap>
      </ContentBoxWithHeader>
    </ContentsWrap>
  );
};

export default AppVersionManageMent;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const ContentBoxWithHeader = styled.div`
  margin-top: 20px;
  padding: 22px 20px;
  background: ${colors.WHITE};
  border-radius: 16px;
  border: 1px solid ${colors.GRAY9};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderButtonWrap = styled.div`
  display: flex;
  max-height: 32px;
  gap: 8px;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const TableBottomWrap = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ContentsTotal = styled.span`
  display: flex;
  gap: 8px;
  color: ${colors.GRAY6};
  font-weight: 500;
  ${typo.BODY_8};

  span {
    ${typo.BODY_8};
    font-weight: 500;
    color: ${colors.GRAY2};
  }
`;

const PageNationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
