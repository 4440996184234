import moment from "moment";

export function isEditCheck(postStartAt: string, postEndAt: string) {
  const startDate = moment(postStartAt);
  const endDate = moment(postEndAt);
  const today = moment();

  if (today.isBetween(startDate, endDate, null, "[]")) {
    // console.log(" 게시중 - 이 글은 수정 불가능");
    return "active";
  } else {
    if (today.isBefore(startDate)) {
      // console.log("미래 - 이 글은 수정 가능", today.isAfter(endDate));
      return "future";
    } else if (today.isAfter(endDate)) {
      // console.log("과거 - 이 글은 수정 불가능");
      return "past";
    }
  }
}

export default isEditCheck;
