import {
  CommonContentsRenderType,
  CommonCreateBodyKeyType,
  CommonDataType,
} from "src/types/common";

export interface FaqCreateInitValuesType {
  title: string;
  isExposed: boolean;
  category1: string;
  category2: string;
  category3: string;
  content: string;
}

export interface FaqCommonContentsOptionType extends CommonContentsRenderType {
  placeholder?: string;
  notice?: JSX.Element;
}

export const FaqCreateData: CommonDataType<
  CommonCreateBodyKeyType,
  FaqCreateInitValuesType
> = {
  createBodyKey: [
    "isExposed",
    "category1",
    "category2",
    "category3",
    "title",
    "content",
  ],
  formInitialValues: {
    title: "",
    isExposed: false,
    category1: "",
    category2: "",
    category3: "",
    content: "",
  },
};
