import { requestTokenGet, requestUserInfo } from "@apis/auth";
import { ReactComponent as Logo } from "@assets/icons/everonLogo.svg";
import Button from "@components/atoms/Button";
import { PrivateRoutes } from "@constants/urls";
import useRouter from "@hooks/useRouter";
import { onSessionTokenReset } from "@network/index";
import useAuthStore from "@store/auth";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import useGlobalModal from "@hooks/useGlobalModal";
import { ErrorResponseType } from "@network/types";
import {
  Copyright,
  FooterWrap,
  HeaderWrap,
  LoginWrap,
  LogoWrap,
  SubTitle,
} from "./styles";

const Landing: React.FC = () => {
  const { replace, query } = useRouter();
  const code = query?.code as string;
  const [getCode, setGetCode] = useState<string>("");
  const { setUserInfo } = useAuthStore();
  const { showAlertModal } = useGlobalModal();

  useEffect(() => {
    if (code) {
      setGetCode(code);
    } else {
      (async () => {
        try {
          const userInfo = await requestUserInfo();
          setUserInfo({ userInfo });
          replace(PrivateRoutes.Banner); // 메인페이지로 이동
        } catch (error) {
          onSessionTokenReset();
        }
      })();
    }
  }, [code, replace, setUserInfo]);

  useQuery(["getToken", getCode], () => requestTokenGet(getCode), {
    onSuccess: async () => {
      const userInfo = await requestUserInfo();
      setUserInfo({ userInfo });

      replace(PrivateRoutes.Banner); // 메인페이지로 이동
    },
    onError: (error: ErrorResponseType) => {
      if (error.statusCode === 400 && error.message === "Code not valid") {
        showAlertModal({
          title: `로그인 오류가 발생 했어요. 다시 로그인 해주세요.`,
          onConfirm: () => {
            onSessionTokenReset();
          },
          handleClose: () => {
            onSessionTokenReset();
          },
        });
      } else
        showAlertModal({
          title: error.message,
          onConfirm: () => onSessionTokenReset(),
        });
    },
    enabled: !!getCode,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <LoginWrap>
        <HeaderWrap>
          <LogoWrap>
            <Logo />
          </LogoWrap>
          <SubTitle>{`언제나 어디서나\n스마트 모빌리티 파트너`}</SubTitle>
        </HeaderWrap>

        <FooterWrap>
          <Button
            full
            buttonType="text"
            text="잠시만 기다려 주세요"
            style={{ fontWeight: 500 }}
          />
          <Copyright>Copyright © 2022 Everon. All Rights Reserved.</Copyright>
        </FooterWrap>
      </LoginWrap>
    </>
  );
};

export default Landing;
