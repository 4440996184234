import React from "react";
import styled, { css } from "styled-components";

interface IconProps {
  src?: string;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
  isCursorPointer?: boolean;

  className?: string;
}

const IconBox: React.FC<IconProps> = ({
  src,
  width,
  height,
  onClick,
  className,
  isCursorPointer,
}) => {
  return (
    <>
      <IconContainer
        className={className}
        width={width}
        height={height}
        onClick={onClick}
        src={src}
        isCursorPointer={isCursorPointer}
      />
    </>
  );
};

export default IconBox;

export const IconContainer = styled.span<IconProps>`
  display: inline-block;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  ${({ src }) =>
    src &&
    css`
      background: no-repeat center/contain url(${src});
    `};
  cursor: ${({ isCursorPointer }) => (isCursorPointer ? "pointer" : "auto")};
`;
