import ADMIN from "./icons/adminIcon.svg";
import CUSTOMER from "./icons/customerIcon.svg";
import EVERON_LOGO from "./icons/everonLogo.svg";
import EXCEL from "./icons/excel.svg";
import FILTER_DOWN from "./icons/filterDownArrow.svg";
import HOME from "./icons/home.svg";
import LIGHT_BULB from "./icons/lightBulb.svg";
import BACK from "./icons/mainBackArrow.svg";
import MARKETING from "./icons/marketing.svg";
import PAGINATION_LEFT from "./icons/pageNationLeft.svg";
import RIGHT_LARGE from "./icons/rightLarge.svg";
import LEFT_LARGE from "./icons/leftLarge.svg";
import PAGINATION_RIGHT from "./icons/pageNationRight.svg";
import CALENDAR from "./icons/calendar.svg";
import RESET from "./icons/reset.svg";
import QUESTION from "./icons/question.svg";
import UPLOAD_ICON from "./icons/uploadIcon.svg";
import RIGHT from "./icons/rightArrow.svg";
import SELECT_DOWN from "./icons/selectDown.svg";
import ERROR_404 from "./icons/error404.svg";
import ERROR_500 from "./icons/error500.svg";
import ERROR_503 from "./icons/error503.svg";
import SELECTED_CHECK_ACTIVE from "./icons/selectedCheck.svg";
import TERMS from "./icons/termsIcon.svg";
import TERMS_WHITE from "./icons/termsWhite.svg";
import CLOSE from "./icons/close.svg";
import CLOSE_RED from "./icons/closeRed.svg";
import CLOSE_WHITE from "./icons/closeWhite.svg";
import TOGGLE_ACTIVE from "./icons/toggleActive.svg";
import DRAG_DROP from "./icons/dragdrop.svg";
import TOGGLE_INACTIVE from "./icons/toggleUnActive.svg";
import UP from "./icons/upArrow.svg";
import NOTI from "./icons/noti.svg";
import VIEW_ALL from "./icons/viewAll.svg";
import EMPTY_PLACEHOLDER_ICON from "./icons/emptyPlaceHolderIcon.svg";
import NOTICE_GRAY from "./icons/noticeGray.svg";
import TERMS_PRIMARY from "./icons/termsPrimary.svg";
import QUESTION_TOOLTIP from "./icons/questionTooltip.svg";
import CALENDAR_WHITE from "./icons/calendarWhite.svg";

export const ICONS = {
  EVERON_LOGO,

  CHECK_ICON: {
    TOGGLE_ACTIVE,
    TOGGLE_INACTIVE,
    SELECTED_CHECK_ACTIVE,
  },

  ARROW_ICON: {
    RIGHT,
    UP,
    FILTER_DOWN,
    SELECT_DOWN,
    PAGINATION_RIGHT,
    PAGINATION_LEFT,
    BACK,
    RIGHT_LARGE,
    LEFT_LARGE,
  },

  SIDEMENU_ICON: {
    ADMIN,
    MARKETING,
    TERMS,
    TERMS_WHITE,
    CUSTOMER,
    HOME,
    VIEW_ALL,
    TERMS_PRIMARY,
  },

  NOTI_ICON: {
    LIGHT_BULB,
    CLOSE_WHITE,
    NOTI,
    NOTICE_GRAY,
    QUESTION_TOOLTIP,
  },

  TEXT_ICON: {
    RESET,
    QUESTION,
  },

  MODAL_ICON: {
    DRAG_DROP,
    CLOSE,
  },

  BUTTON_ICON: {
    EXCEL,
    CLOSE_RED,
  },

  DATE_ICON: {
    CALENDAR,
    UPLOAD_ICON,
    EMPTY_PLACEHOLDER_ICON,
    CALENDAR_WHITE,
  },

  ERROR_ICON: {
    ERROR_404,
    ERROR_500,
    ERROR_503,
  },
};
