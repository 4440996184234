import Button from "@components/atoms/Button";
import { BasicOptionType } from "@components/atoms/CheckBox";
import Input from "@components/atoms/Input";
import { StyledRadio } from "@components/atoms/Radio";
import {
  BannerConnectionCategory,
  InputContentsWrap,
  InputWrap,
  Label,
  LabelInnerWrap,
  LabelWrap,
  PopupConnectionCategory,
} from "@pages/BannerCreate";
import { BannerCreateInitValuesType } from "@pages/BannerCreate/Data";
import { colors } from "@styles/index";
import { Radio, RadioChangeEvent } from "antd";
import { FormikContextType } from "formik";
import React from "react";
import styled from "styled-components";
import { ConnectionSelectedItem } from "../ConnectionSelectItem";
import PublishPeriodNotification from "../PublishPeriodNotification";
import useGlobalModal from "@hooks/useGlobalModal";

interface RenderBannerConnectionListProps {
  connectionContentType: string;
  formik: FormikContextType<BannerCreateInitValuesType>;
  connectionKindList: BasicOptionType;
  setConnectionKindList: React.Dispatch<React.SetStateAction<BasicOptionType>>;
}

const RenderBannerConnectionList: React.FC<RenderBannerConnectionListProps> = ({
  connectionContentType,
  formik,
  connectionKindList,
  setConnectionKindList,
}) => {
  const { showConnectionModal } = useGlobalModal();

  if (connectionContentType === "banner") {
    return (
      <InputWrap>
        <LabelWrap>
          <LabelInnerWrap>
            <Label>연결 목록 선택</Label>
          </LabelInnerWrap>
        </LabelWrap>
        <InputContentsWrap>
          <div style={{ paddingTop: 12, width: "100%" }}>
            <Radio.Group
              onChange={(value: RadioChangeEvent) => {
                const category = value.target.value;

                const selectedCategory = BannerConnectionCategory.filter(
                  ({ value }) => value === category,
                );
                setConnectionKindList(selectedCategory[0]);

                formik.setFieldValue("contentId", "");
                formik.setFieldValue("contentTitle", "");
                formik.setFieldValue("url", "");
                formik.setFieldValue("contentCategory", category);
              }}
              defaultValue={BannerConnectionCategory?.[0].value}
              value={connectionKindList.value}
            >
              {BannerConnectionCategory?.map(({ label, value }) => {
                return (
                  <StyledRadio key={label} value={value}>
                    {label}
                  </StyledRadio>
                );
              })}
            </Radio.Group>

            <ConnectButtonWrap>
              {connectionKindList.value === "url" ? (
                <StyledDiv
                  style={{
                    padding: "unset",
                  }}
                >
                  <Input
                    inputType="create"
                    id="url"
                    name="url"
                    onChange={formik?.handleChange}
                    value={formik?.values.url}
                    full
                    placeholder={"외부 URL을 입력해주세요"}
                  />
                </StyledDiv>
              ) : (
                <>
                  {formik?.values.contentId && formik?.values.contentTitle ? (
                    <ConnectionSelectItemWrap>
                      <ConnectionSelectedItem
                        title={formik.values.contentTitle ?? ""}
                        onClick={() => {
                          formik?.setFieldValue("contentId", "");
                          formik?.setFieldValue("contentTitle", "");
                        }}
                      />
                      {formik.values?.contentPostStartAt &&
                        formik.values?.contentPostEndAt && (
                          <PublishPeriodNotification
                            contentStartAt={formik.values.contentPostStartAt}
                            contentEndAt={formik.values.contentPostEndAt}
                          />
                        )}
                    </ConnectionSelectItemWrap>
                  ) : (
                    <Button
                      buttonType="line_primary"
                      buttonSize="regular"
                      text={`${connectionKindList.label} 불러오기`}
                      onClick={() => {
                        showConnectionModal({
                          category: connectionKindList.value,
                          modalHeaderTitle: `${connectionKindList.label} 연결`,
                          contentTitle: `${connectionKindList.label} ${
                            connectionKindList.label === "약관"
                              ? "이력"
                              : "목록"
                          }`,
                          firstButtonText: "취소",
                          secondButtonText: "확인",
                          formik,
                        });
                      }}
                    />
                  )}
                </>
              )}
            </ConnectButtonWrap>
          </div>
        </InputContentsWrap>
      </InputWrap>
    );
  }
  if (connectionContentType === "popup") {
    return (
      <InputWrap>
        <InputWrap>
          <LabelWrap>
            <LabelInnerWrap>
              <Label>연결 목록 선택</Label>
            </LabelInnerWrap>
          </LabelWrap>
          <InputContentsWrap>
            <div style={{ paddingTop: 12, width: "100%" }}>
              <Radio.Group
                onChange={(value: RadioChangeEvent) => {
                  const category = value.target.value;
                  const selectedCategory = PopupConnectionCategory.filter(
                    ({ value }) => value === category,
                  );
                  setConnectionKindList(selectedCategory[0]);

                  formik.setFieldValue("contentId", "");
                  formik.setFieldValue("contentTitle", "");
                  formik.setFieldValue("url", "");
                  formik.setFieldValue("contentCategory", category);
                }}
                defaultValue={PopupConnectionCategory?.[0].value}
                value={connectionKindList.value}
              >
                {PopupConnectionCategory?.map(({ label, value }) => {
                  return (
                    <StyledRadio key={label} value={value}>
                      {label}
                    </StyledRadio>
                  );
                })}
              </Radio.Group>

              <ConnectButtonWrap>
                {connectionKindList.value === "url" ? (
                  <StyledDiv
                    style={{
                      padding: "unset",
                    }}
                  >
                    <Input
                      inputType="create"
                      id="url"
                      name="url"
                      onChange={formik?.handleChange}
                      value={formik?.values.url}
                      full
                      placeholder={"외부 URL을 입력해주세요"}
                    />
                  </StyledDiv>
                ) : (
                  <>
                    {formik?.values.contentId && formik?.values.contentTitle ? (
                      <ConnectionSelectItemWrap>
                        <ConnectionSelectedItem
                          title={formik.values.contentTitle ?? ""}
                          onClick={() => {
                            formik?.setFieldValue("contentId", "");
                            formik?.setFieldValue("contentTitle", "");
                          }}
                        />
                        {formik.values?.contentPostStartAt &&
                          formik.values?.contentPostEndAt && (
                            <PublishPeriodNotification
                              contentStartAt={formik.values.contentPostStartAt}
                              contentEndAt={formik.values.contentPostEndAt}
                            />
                          )}
                      </ConnectionSelectItemWrap>
                    ) : (
                      <Button
                        buttonType="line_primary"
                        buttonSize="regular"
                        text={`${connectionKindList.label} 불러오기`}
                        onClick={() =>
                          showConnectionModal({
                            category: connectionKindList.value,
                            modalHeaderTitle: `${connectionKindList.label} 연결`,
                            contentTitle: `${connectionKindList.label} ${
                              connectionKindList.label === "약관"
                                ? "이력"
                                : "목록"
                            }`,
                            firstButtonText: "취소",
                            secondButtonText: "확인",
                            formik,
                          })
                        }
                      />
                    )}
                  </>
                )}
              </ConnectButtonWrap>
            </div>
          </InputContentsWrap>
        </InputWrap>
      </InputWrap>
    );
  }
  return null;
};

export default RenderBannerConnectionList;

const StyledDiv = styled.div`
  padding-left: 200px;
`;

const ConnectButtonWrap = styled.div`
  position: relative;
  padding-top: 24px;
  margin-top: 20px;

  ::before {
    content: "";
    width: 100%;
    height: 1px;
    background: ${colors.GRAY10};
    position: absolute;
    top: 0px;
  }
`;

const ConnectionSelectItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
