import Button from "@components/atoms/Button";
import BaseModal from "@components/molecules/Modal/BaseModal";
import { ModalType } from "@store/modal";
import { colors, typo } from "@styles/index";
import styled from "styled-components";

export interface TwoButtonModalProps {
  title?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  modalType?: ModalType;
  onConfirm?: () => void;
  onCancel?: () => void;
  handleClose?: () => void;
}

function TwoButtonModal({
  modalType = "TwoButtonModal",
  title = "title",
  firstButtonText = "cancel",
  secondButtonText = "confirm",
  onConfirm,
  onCancel,
  handleClose,
}: TwoButtonModalProps) {
  return (
    <BaseModal modalType={modalType} isOpen handleClose={handleClose}>
      <ModalTitle>{title}</ModalTitle>
      <ButtonWrap>
        <Button
          buttonType="text"
          buttonSize="lager"
          style={{ marginRight: 8 }}
          text={firstButtonText}
          onClick={onCancel}
        />
        <Button
          buttonSize="lager"
          text={secondButtonText}
          onClick={onConfirm}
        />
      </ButtonWrap>
    </BaseModal>
  );
}

export default TwoButtonModal;

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const ModalTitle = styled.div`
  white-space: pre-line;
  color: ${colors.GRAY2};
  ${typo.HEADING_3};
`;
