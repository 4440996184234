import { UpdateHistory } from "@apis/faq/types";
import { CommonCreateBodyKeyType, CommonDataType } from "src/types/common";

export interface FaqDetailInitValuesType {
  title: string;
  isExposed: boolean;
  category1: string;
  category2: string;
  category3: string;
  content: string;
  updateReason: string;
  updateHistory: UpdateHistory[];
}

export const FaqDetailData: CommonDataType<
  CommonCreateBodyKeyType,
  FaqDetailInitValuesType
> = {
  createBodyKey: [
    "isExposed",
    "category1",
    "category2",
    "category3",
    "title",
    "content",
    "id",
    "updateReason",
  ],
  formInitialValues: {
    title: "",
    isExposed: false,
    category1: "",
    category2: "",
    category3: "",
    content: "",
    updateReason: "",
    updateHistory: [],
  },
};
