import {
  requestTermsHistory,
  requestTermsHistoryCreate,
  requestTermsHistoryDelete,
  requestTermsHistoryUpdate,
} from "@apis/terms";
import { TermsCategoryType } from "@apis/terms/types";
import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import { RangeDateWrap } from "@components/atoms/DatePicker";
import IconBox from "@components/atoms/IconBox";
import Editor from "@components/molecules/Editor";
import ExcelButton from "@components/molecules/ExcelButton";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalNoti from "@hooks/useGlobalNoti";
import { ErrorResponseType } from "@network/types";
import { termsCategoryCheck } from "@pages/TermsDetail/Data";
import { ModalType } from "@store/modal";
import useTermsDataStore from "@store/terms";
import { colors, typo } from "@styles/index";
import { excelDownload } from "@utils/excel-download";
import { stringToDateTimerFormatter } from "@utils/string-utils";
import { htmlToText } from "html-to-text";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled, { css } from "styled-components";
import BaseModal from "../BaseModal";
import OneButtonModal from "../OneButtonModal";
import ReasonEditModal from "../ReasonEditModal";
import ReasonModificationModal from "../ReasonModificationModal";
import TwoButtonModal from "../TwoButtonModal";
import isEditCheck from "./util";
import { DatePicker } from "antd";

type IsEditType = "active" | "future" | "past" | undefined;

export interface TermsEditorModalProps {
  title: string;
  termsId?: number;
  modalType?: ModalType;
}

function TermsEditorModal({
  termsId,
  title,
  modalType = "TermsEditorModal",
}: TermsEditorModalProps): JSX.Element {
  const { showDeleteNoti, showConfirmNoti, showEditNoti } = useGlobalNoti();
  const { hideModal } = useGlobalModal();
  const { setTermsData, resetTermsData, termsData } = useTermsDataStore(
    (state) => state,
  );
  const [isEdit, setIsEdit] = useState<IsEditType>(undefined);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState({
    isOpenState: false,
    modalText: "",
  });

  const modalTitleState = isEdit === undefined ? "등록" : "상세";

  // 수정 사유 확인 모달 STATE
  const [isReasonModificationModalOpen, setIsReasonModificationModalOpen] =
    useState(false);

  // 수정 사유 입력 모달 STATE
  const [isReasonEditModal, setIsReasonEditModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const DATA_FORMAT = "YYYY-MM-DD";
  const TODAY_DATE = moment().format(DATA_FORMAT);

  const queryClient = useQueryClient();

  // 상세 이력 조회
  const { data: initData } = useQuery(
    ["termsHistory", termsId],
    () => requestTermsHistory(termsId),
    {
      onSuccess: (data) => {
        const { postStartAt, postEndAt } = data;
        setIsEdit(isEditCheck(postStartAt, postEndAt));
        setTermsData(data);
      },
      onError: (error: ErrorResponseType) => {
        setIsAlertModalOpen({
          isOpenState: true,
          modalText: error?.message ?? "알 수 없는 에러가 발생했어요.",
        });
      },
      enabled: !!termsId,
    },
  );

  const { mutate: termsHistoryCreate } = useMutation(
    "termsHistoryCreate",
    requestTermsHistoryCreate,
    {
      onSuccess: () => {
        hideModal();
        resetTermsData();

        showConfirmNoti({ text: "이력이 등록되었어요" });
        queryClient.invalidateQueries("termsDetail");
      },
      onError: (error: ErrorResponseType) => {
        if (
          error.message.includes("Token is not active") ||
          error.message.includes(
            "Refresh toked issued before the client session started",
          )
        )
          return;

        setIsAlertModalOpen({
          isOpenState: true,
          modalText: error?.message ?? "알 수 없는 에러가 발생했어요.",
        });
      },
      retry: false,
    },
  );

  // 약관 이력 수정
  const { mutate: termsHistoryUpdate } = useMutation(
    ["termsHistoryUpdate"],
    requestTermsHistoryUpdate,
    {
      onSuccess: () => {
        showEditNoti({ text: "이력이 수정되었어요." });
        queryClient.invalidateQueries("termsDetail");
      },
      onError: (error: ErrorResponseType) => {
        if (
          error.message.includes("Token is not active") ||
          error.message.includes(
            "Refresh toked issued before the client session started",
          )
        )
          return;

        setIsAlertModalOpen({
          isOpenState: true,
          modalText: error?.message ?? "알 수 없는 에러가 발생했어요.",
        });
      },
      retry: false,
    },
  );

  // 약관 이력 삭제
  const { mutate: termsHistoryDelete } = useMutation(
    "termsHistoryDelete",
    requestTermsHistoryDelete,
    {
      onSuccess: () => {
        showDeleteNoti({ text: "이력이 삭제되었어요." });
        queryClient.invalidateQueries("termsDetail");
      },
      onError: (error: ErrorResponseType) => {
        if (
          error.message.includes("Token is not active") ||
          error.message.includes(
            "Refresh toked issued before the client session started",
          )
        )
          return;

        setIsAlertModalOpen({
          isOpenState: true,
          modalText: error?.message ?? "알 수 없는 에러가 발생했어요.",
        });
      },
      retry: false,
    },
  );

  const onChangeStartAt = (date: any, dateString: string) => {
    setTermsData({
      ...termsData,
      postStartAt: dateString,
    });
  };

  const onChangeEndAt = (date: any, dateString: string) => {
    setTermsData({
      ...termsData,
      postEndAt: dateString,
    });
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSaveButtonClick = () => {
    const { category, content, postEndAt, postStartAt } = termsData;

    termsHistoryCreate({ category, content, postEndAt, postStartAt });
  };

  const handleCloseModal = () => {
    hideModal();

    setTermsData({
      ...termsData,
      postStartAt: TODAY_DATE,
      postEndAt: "",
      content: "",
      id: undefined,
    });
  };

  // 수정 사유 확인 모달 열기
  const onReasonModificationButtonClick = () => {
    setIsReasonModificationModalOpen(true);
  };

  // 수정 사유 입력 모달 열기
  const onEditModalOpen = () => {
    if (!termsData.id) return;
    const { category, content, postEndAt, postStartAt, id } = termsData;

    if (isEdit === "active") {
      termsHistoryUpdate({
        category,
        content,
        postEndAt,
        postStartAt,
        id,
      });

      hideModal();
      return;
    }
    setIsReasonEditModal(true);
  };

  // 수정 사유 입력
  const handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTermsData({
      ...termsData,
      updateReason: e.target.value,
    });
  };

  // 수정 사유 입력 모달 저장하기 버튼
  const handleReasonConfirm = () => {
    setIsReasonEditModal(false);

    const { category, content, postEndAt, postStartAt, id, updateReason } =
      termsData;
    if (isEdit === "future" || isEdit === "active") {
      if (!termsData.id) return;

      termsHistoryUpdate({
        category,
        content,
        postEndAt,
        postStartAt,
        id,
        updateReason,
      });

      resetTermsData();
    }

    hideModal();
  };

  const handleEditorChange = (content: string) => {
    setTermsData({
      ...termsData,
      content,
    });
  };

  // 수정 사유 모달 닫기
  const handleReasonClose = () => {
    setIsReasonEditModal(false);
  };

  // 삭제 버튼 클릭
  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  // 삭제 모달 확인 버튼
  const handleDeleteModalConfirm = () => {
    if (!termsData.id) return;
    setIsDeleteModalOpen(false);
    termsHistoryDelete(termsData.id);
    resetTermsData();
    hideModal();
  };

  const onExcelDownload = () => {
    const header = [
      "구분",
      "최조 등록 일시",
      "수정 변경 일시",
      "내용",
      "작성자",
    ];
    const colsWidth = [100, 180, 180, 700, 150];
    // const rowsHeight = [0, 0];

    const excelData = {
      title: termsCategoryCheck(termsData.category as TermsCategoryType),
      createAt: stringToDateTimerFormatter(termsData?.createdAt),
      updateAt: stringToDateTimerFormatter(
        termsData.updatedAt ?? termsData.createdAt,
      ),
      content: htmlToText(termsData.content),
      manger: termsData.manager,
    };

    if (excelData) {
      excelDownload({
        fileName: termsCategoryCheck(termsData.category as TermsCategoryType),
        header,
        data: [excelData],
        colsWidth,
        // rowsHeight,
      });
    }
  };

  const isCheckEdit = useCallback(() => {
    if (isEdit === "active" || isEdit === "future") {
      const customInitData = {
        ...initData,
        postStartAt:
          initData && moment(initData.postStartAt).format(DATA_FORMAT),
        postEndAt: initData
          ? moment(initData.postEndAt).format(DATA_FORMAT)
          : "",
      };

      const customTermsState = {
        ...termsData,
        postStartAt:
          termsData && moment(termsData.postStartAt).format(DATA_FORMAT),
        postEndAt: termsData.postEndAt
          ? moment(termsData.postEndAt).format(DATA_FORMAT)
          : "",
      };
      const initTermsData = JSON.stringify(customInitData);
      const termsStateData = JSON.stringify(customTermsState);

      if (initTermsData === termsStateData) {
        return true;
      } else if (
        initTermsData !== termsStateData &&
        termsData.postEndAt &&
        termsData.postStartAt
      ) {
        return false;
      }
    }
    return true;
  }, [initData, isEdit, termsData]);

  const isSaveButtonValid = useCallback(() => {
    const { postStartAt, postEndAt, content } = termsData;

    if (postStartAt && postEndAt && content) {
      return false;
    } else {
      return true;
    }
  }, [termsData]);

  // // 게시 기간 유효성 검사
  useEffect(() => {
    const { postEndAt, postStartAt } = termsData;
    if (!postEndAt || !postStartAt) return;

    const isSameOrAfter = moment(postStartAt).isSameOrBefore(postEndAt);

    if (!isSameOrAfter) {
      setIsAlertModalOpen({
        isOpenState: true,
        modalText: `게시기간이 올바르지 않아요.\n다시 확인해주세요.`,
      });
      setTermsData({
        ...termsData,
        postEndAt: "",
      });
      return;
    }
  }, [termsData.postEndAt, termsData.postStartAt]);

  useEffect(() => {
    setTermsData({
      ...termsData,
      category: termsData.category ?? "",
      postStartAt: TODAY_DATE,
    });
  }, []);

  return (
    <>
      <BaseModal
        modalType={modalType}
        isOpen
        modalWidth={61.25}
        disableModalPadding
        disableModalInnerGap
        handleClose={handleCloseModal}
      >
        <ModalTitleWrap>
          <ModalTitle>
            {title} 이력 {modalTitleState}
          </ModalTitle>
          <IconBox
            src={ICONS.MODAL_ICON.CLOSE}
            width={24}
            height={24}
            onClick={handleCloseModal}
            isCursorPointer
          />
        </ModalTitleWrap>

        <EditorWrap>
          <PostDataWrap>
            <Label>게시 기간</Label>

            <RangeDateWrap>
              <DatePicker
                onChange={onChangeStartAt}
                placeholder={"기간을 선택해주세요"}
                format={DATA_FORMAT}
                value={
                  termsData.postStartAt
                    ? moment(termsData.postStartAt, DATA_FORMAT)
                    : undefined
                }
                disabled={isEdit === "active" || isEdit === "past"}
              />
              <span />
              <DatePicker
                onChange={onChangeEndAt}
                placeholder={"기간을 선택해주세요"}
                format={DATA_FORMAT}
                value={
                  termsData.postEndAt
                    ? moment(termsData.postEndAt, DATA_FORMAT)
                    : undefined
                }
                disabled={isEdit === "past"}
              />
            </RangeDateWrap>
          </PostDataWrap>

          {isEdit === "future" || !termsData.id ? (
            <Editor
              initData={termsData.content ?? ""}
              onChange={handleEditorChange}
              placeHolder="이력 상세를 등록해주세요"
            />
          ) : (
            <TermsData
              dangerouslySetInnerHTML={{ __html: termsData.content }}
            />
          )}
        </EditorWrap>

        <ButtonWrap isCreate={!termsData.id}>
          {/* 삭제 가능할 경우 */}
          {termsData.id && (
            <ButtonInnerLeftWrap>
              {isEdit === "future" && (
                <Button
                  buttonType="line_red"
                  buttonSize="lager"
                  text="삭제"
                  onClick={handleDeleteButtonClick}
                />
              )}
              <Button
                buttonType="line_primary"
                buttonSize="lager"
                text="수정사유 확인"
                onClick={onReasonModificationButtonClick}
                disabled={termsData.updateHistory.length === 0}
              />
              <StyledExcelButton
                iconsWidth={24}
                iconsHeight={24}
                onClick={onExcelDownload}
              />
            </ButtonInnerLeftWrap>
          )}

          <div>
            <Button
              buttonType="text"
              buttonSize="lager"
              style={{ marginRight: 8 }}
              text={"닫기"}
              onClick={handleCloseModal}
            />
            {/* 수정 가능할 경우 */}
            {(isEdit === "future" || isEdit === "active") && (
              <Button
                buttonSize="lager"
                text={"저장하기"}
                onClick={onEditModalOpen}
                disabled={isCheckEdit()}
              />
            )}
            {/* 생성 */}
            {!termsData.id && (
              <Button
                buttonSize="lager"
                text={"등록하기"}
                onClick={handleSaveButtonClick}
                disabled={isSaveButtonValid()}
              />
            )}
          </div>
        </ButtonWrap>
      </BaseModal>
      {isReasonModificationModalOpen && (
        <ReasonModificationModal
          updateHistory={termsData?.updateHistory}
          category={"terms"}
          onClose={() => setIsReasonModificationModalOpen(false)}
        />
      )}

      {isAlertModalOpen.isOpenState && (
        <OneButtonModal
          title={isAlertModalOpen.modalText}
          onConfirm={() =>
            setIsAlertModalOpen({
              isOpenState: false,
              modalText: "",
            })
          }
        />
      )}
      {isReasonEditModal && (
        <ReasonEditModal
          category="terms"
          onSave={handleReasonConfirm}
          onChangeReason={handleChangeReason}
          onClose={handleReasonClose}
        />
      )}
      {isDeleteModalOpen && (
        <TwoButtonModal
          title="삭제하시겠습니까?"
          firstButtonText="닫기"
          secondButtonText="삭제하기"
          onCancel={onDeleteModalClose}
          onConfirm={handleDeleteModalConfirm}
          handleClose={onDeleteModalClose}
        />
      )}
    </>
  );
}

export default TermsEditorModal;

const ButtonWrap = styled.div<{ isCreate?: boolean }>`
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;

  ${({ isCreate }) => {
    if (isCreate) {
      return css`
        margin-left: auto;
        display: block;
        justify-content: unset;
      `;
    }
  }}
`;

const ButtonInnerLeftWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledExcelButton = styled(ExcelButton)`
  width: 159px;
  padding: 12px 20px;
  ${typo.BODY_7};
`;

const ModalTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 33px 32px 19px;
`;

const ModalTitle = styled.div`
  white-space: pre-line;
  color: ${colors.GRAY2};
  ${typo.HEADING_2};
`;

const EditorWrap = styled.div`
  /*  */

  padding: 32px 30px;
  border-top: 1px solid ${colors.GRAY9};
  border-bottom: 1px solid ${colors.GRAY9};
`;

const TermsData = styled.div`
  color: ${colors.GRAY6};
  word-break: break-all;
  white-space: pre-wrap;
  min-height: 300px;
  max-height: 450px;
  width: 100%;
  overflow-y: scroll;
`;

const PostDataWrap = styled.div`
  /*  */
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 24px;
`;

const Label = styled.div`
  /*  */
  display: flex;
  align-items: center;
  max-width: 200px;
  width: 100%;
  ${typo.HEADING_7};
  color: ${colors.GRAY6};
`;
