import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMemo } from "react";
import queryString from "query-string";

export default function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  return useMemo(() => {
    return {
      push: navigate,
      replace: (to: To, options?: NavigateOptions | undefined) =>
        navigate(to, { replace: true, ...options }),
      goBack: () => navigate(-1),
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      state: location.state,
      location,
    };
  }, [location, navigate]);
}
