import {
  Choice,
  Essential,
  TableText,
  TextFormatting,
  UpdateText,
} from "@utils/TableItemFormattingUtils/styles/table-formatting-common";
import { ICellRendererParams } from "ag-grid-community";
import moment from "moment";

// 테이블 텍스트 포맷
export const textFormat = ({ value }: ICellRendererParams) => {
  return <TextFormatting>{value}</TextFormatting>;
};

// 날짜 포맷
export const dataAtFormat = ({ value }: ICellRendererParams) => {
  return <TextFormatting>{moment(value).format("YYYY-MM-DD")}</TextFormatting>;
};

// 날짜,시간 포맷
export const upDataAtFormat = ({ value }: ICellRendererParams) => {
  return (
    <TextFormatting>
      {moment(value).format("YYYY-MM-DD HH:mm:ss")}
    </TextFormatting>
  );
};

// 배너 종류 체크
export const bannerKindCheck = ({ value }: ICellRendererParams) => {
  return value === "popup" ? (
    <TableText className="popup">팝업</TableText>
  ) : (
    <TableText className="banner">띠 배너</TableText>
  );
};

// 노출상태 체크
export const exposureStatusCheck = ({ value }: ICellRendererParams) => {
  return value ? (
    <TableText style={{ padding: "14px 12px" }} className="exposure">
      노출
    </TableText>
  ) : (
    <TableText style={{ padding: "14px 12px" }} className="unExposure">
      미노출
    </TableText>
  );
};

// 약관 필수 여부 체크
export const RequiredStateCheck = ({ value }: ICellRendererParams) => {
  return value ? <Essential>필수</Essential> : <Choice>선택</Choice>;
};

// 앱 버전 업데이트 여부 체크
export const forceUpdateCheck = ({ value }: ICellRendererParams) => {
  return value ? (
    <UpdateText className="exposure">업데이트</UpdateText>
  ) : (
    <UpdateText className="unExposure">미 업데이트</UpdateText>
  );
};
