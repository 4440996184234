import { PrivateRoutes } from "@constants/urls";
import AppVersionCreate from "@pages/AppVersionCreate";
import AppVersionDetail from "@pages/AppVersionDetail";
import AppVersionManageMent from "@pages/AppVersionManageMent";
import BannerCreate from "@pages/BannerCreate";
import BannerDetail from "@pages/BannerDetail";
import BannerManagement from "@pages/BannerManagement";
import ChargerDescriptionCreate from "@pages/ChargerDescriptionCreate";
import ChargerDescriptionDetail from "@pages/ChargerDescriptionDetail";
import ChargerDescriptionManagement from "@pages/ChargerDescriptionManagement";
import ChargingServiceDescriptionCreate from "@pages/ChargingServiceDescriptionCreate";
import ChargingServiceDescriptionDetail from "@pages/ChargingServiceDescriptionDetail";
import ChargingServiceDescriptionManagement from "@pages/ChargingServiceDescriptionManagement";
import EventCreate from "@pages/EventCreate";
import EventDetail from "@pages/EventDetail";
import EventManagement from "@pages/EventManagement";
import FaqCreate from "@pages/FaqCreate";
import FaqDetail from "@pages/FaqDetail";
import FaqManagement from "@pages/FaqManagement";
import NoticeCreate from "@pages/NoticeCreate";
import NoticeDetail from "@pages/NoticeDetail";
import NoticeManagement from "@pages/NoticeManagement";
import TermsDetail from "@pages/TermsDetail";
import TermsManagement from "@pages/TermsManagement";

export const PRIVATE_ROUTES = [
  // BANNER
  {
    path: PrivateRoutes.Banner,
    element: <BannerManagement />,
  },
  {
    path: PrivateRoutes.BannerCreate,
    element: <BannerCreate />,
  },
  {
    path: `${PrivateRoutes.BannerDetail}/:id`,
    element: <BannerDetail />,
  },
  // // FAQ
  {
    path: PrivateRoutes.Faq,
    element: <FaqManagement />,
  },
  {
    path: PrivateRoutes.FaqCreate,
    element: <FaqCreate />,
  },
  {
    path: `${PrivateRoutes.FaqDetail}/:id`,
    element: <FaqDetail />,
  },
  // // EVENT
  {
    path: PrivateRoutes.Event,
    element: <EventManagement />,
  },
  {
    path: PrivateRoutes.EventCreate,
    element: <EventCreate />,
  },
  {
    path: `${PrivateRoutes.EventDetail}/:id`,
    element: <EventDetail />,
  },
  // // NOTICE
  {
    path: PrivateRoutes.Notice,
    element: <NoticeManagement />,
  },
  {
    path: PrivateRoutes.NoticeCreate,
    element: <NoticeCreate />,
  },
  {
    path: `${PrivateRoutes.NoticeDetail}/:id`,
    element: <NoticeDetail />,
  },
  // // CHAERGER DESCRIPTION
  {
    path: PrivateRoutes.ChargerDescription,
    element: <ChargerDescriptionManagement />,
  },
  {
    path: PrivateRoutes.ChargerDescriptionCreate,
    element: <ChargerDescriptionCreate />,
  },
  {
    path: `${PrivateRoutes.ChargerDescriptionDetail}/:id`,
    element: <ChargerDescriptionDetail />,
  },
  // // CHARGING-SERVICE DESCRIPTION
  {
    path: PrivateRoutes.ChargingServiceDescription,
    element: <ChargingServiceDescriptionManagement />,
  },
  {
    path: PrivateRoutes.ChargingServiceDescriptionCreate,
    element: <ChargingServiceDescriptionCreate />,
  },
  {
    path: `${PrivateRoutes.ChargingServiceDescriptionDetail}/:id`,
    element: <ChargingServiceDescriptionDetail />,
  },
  // // APP VERSION
  {
    path: PrivateRoutes.AppVersion,
    element: <AppVersionManageMent />,
  },
  {
    path: PrivateRoutes.AppVersionCreate,
    element: <AppVersionCreate />,
  },
  {
    path: `${PrivateRoutes.AppVersionDetail}/:id`,
    element: <AppVersionDetail />,
  },
  {
    path: PrivateRoutes.Terms,
    element: <TermsManagement />,
  },
  {
    path: `${PrivateRoutes.TermsDetail}/:category`,
    element: <TermsDetail />,
  },
];
