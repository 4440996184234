export const ApiUrls = {
  /** Auth */
  auth: "/auth", // POST
  user: "/auth/session", // GET

  /** Banner */
  banner: "/banners", // GET POST PUT
  bannerDetail: "/banners", // GET
  bannerDelete: "/banners", // DELETE
  bannerDisplayEdit: "/banners/display-order", // PUT

  /** Faq */
  faq: "/faqs", // GET POST PUT
  faqDetail: "/faqs", // GET
  faqDelete: "/faqs", // DELETE
  faqCategory: "/faqs/categories", // GET
  faqMiddleCategory: "/faqs/middle-categories", // GET
  faqDisplayEdit: "/faqs/display-order", // PUT
  faqCategoryEdit: "/faqs/display-order-order-by-category", // PUT

  /** Event */
  event: "/events", // GET POST PUT
  eventDetail: "/events", // GET
  eventDelete: "/events", // DELETE

  /** Notice */
  notice: "/notices", // GET POST PUT
  noticeDetail: "/notices", // GET
  noticeDelete: "/notices", // DELETE

  /** ChargerDescription */
  chargerDescription: "/manuals", // GET POST PUT
  chargerDescriptionDetail: "/manuals", // GET
  chargerDescriptionDelete: "/manuals", // DELETE

  /** ChargingServiceDescription */
  chargingServiceDescription: "/charging-service-manuals", // GET POST PUT
  chargingServiceDescriptionDetail: "/charging-service-manuals", // GET
  chargingServiceDescriptionDelete: "/charging-service-manuals", // DELETE

  /** AppVersion */
  appVersion: "/versions", // GET POST PUT
  appVersionDetail: "/versions", // GET
  appVersionDelete: "/versions", // DELETE

  /** Terms */
  terms: "/terms", // GET
  termsHistory: "/terms/history", // GET POST PUT
  termsDetail: "/terms", // GET
  termsDelete: "/terms", // DELETE
};
