import { IMAGES } from "@assets/images";
import Button from "@components/atoms/Button";
import useRouter from "@hooks/useRouter";
import { colors, typo } from "@styles/index";
import moment from "moment";
import React from "react";
import styled, { css } from "styled-components";

interface UrlType {
  title: string;
  url: string;
  btnType?: "primary" | "gray";
}

const PreView: React.FC = () => {
  const { query } = useRouter();

  const data = localStorage.getItem("previewData");
  const parseData = JSON?.parse(data ?? "");

  const isNoticePreview = query.type === "notice";
  const isChargerPreview = query.type === "charger-description";
  const isChargingServicePreview =
    query.type === "charging-service-description";
  const isEventPreview = query.type === "event";

  const isUrlButtonValidation = parseData?.contentCategory === "button";
  const eventButtonData: UrlType[] = parseData?.urls?.map(
    ({ title, url }: UrlType, idx: number) => {
      return {
        btnType: idx === 0 ? "primary" : "gray",
        title,
        url,
      };
    },
  );

  return (
    <MobileWrap>
      <MobileInnerWrap previewUrl={query?.type}>
        <MobileHeaderWrap previewUrl={query?.type} />
        <CommonHeaderWrap previewUrl={query?.type} />

        {isChargerPreview && (
          <>
            <ChargerImageWrap>
              <ChargerImage
                src={parseData?.thumbnail?.thumbUrl ?? parseData?.thumbnail}
              />
            </ChargerImageWrap>
            <ChargerTitle>{parseData?.title}</ChargerTitle>
          </>
        )}

        {isChargingServicePreview && (
          <>
            <ChargingServiceImageWrap>
              <ChargingServiceImage
                src={parseData?.thumbnail?.thumbUrl ?? parseData?.thumbnail}
              />
            </ChargingServiceImageWrap>
            <ChargingServiceTitle>{parseData?.title}</ChargingServiceTitle>
          </>
        )}

        {isNoticePreview && (
          <NoticeTitleWrap previewUrl={query.type}>
            <NoticeTitle>{parseData?.title}</NoticeTitle>
            <NoticeDataAt>
              {moment(parseData?.postStartAt).format("YYYY-MM-DD")}
            </NoticeDataAt>
          </NoticeTitleWrap>
        )}

        <CommonContentsWrap
          previewUrl={query.type}
          dangerouslySetInnerHTML={{
            __html: parseData?.content,
            // __html: `<p><img class="image_resized" style="width:100%;" src="https://everon-gatewayserver-prod-files.s3.ap-northeast-2.amazonaws.com/event/%EC%9D%B4%EB%B2%A4%ED%8A%B8+%EC%83%81%EC%84%B8%ED%8E%98%EC%9D%B4%EC%A7%80.png"></p>`,
            // __html: `<p>㈜에버온의 충전서비스를 이용해 주시는 고객님께 감사드립니다.<br><br>해양경찰정 전기점검으로 인해<br>충전기 사용이 중단되시는 부분에 대한 안내를 드리오니<br>이용시 참고하시기 바랍니다.<br><br>- 아 래 -<br><br>1. 충전소명 : 해양경찰청<br><br>2.작업 일시: 2020년 9월 12일 (토) ~ 2020년 9월 13일 (일)<br><br>3.작업 시간: 12일 00:00 ~ 13일 24:00까지<br><br>※ 단, 점검 중 특이사항 발생시 점검시간이 늘어날 수 있습니다.<br><br>이용에 불편을 드려 죄송합니다.<br><br>감사합니다. ㈜에버온의 충전서비스를 이용해 주시는 고객님께 감사드립니다.<br><br>해양경찰정 전기점검으로 인해<br>충전기 사용이 중단되시는 부분에 대한 안내를 드리오니<br>이용시 참고하시기 바랍니다.<br><br>- 아 래 -<br><br>1. 충전소명 : 해양경찰청<br><br>2.작업 일시: 2020년 9월 12일 (토) ~ 2020년 9월 13일 (일)<br><br>3.작업 시간: 12일 00:00 ~ 13일 24:00까지<br><br>※ 단, 점검 중 특이사항 발생시 점검시간이 늘어날 수 있습니다.<br><br>이용에 불편을 드려 죄송합니다.<br><br>감사합니다.&nbsp;<br><br><br><br><br>&nbsp;</p>`,
          }}
        />

        {isEventPreview && isUrlButtonValidation && (
          <MobileFooterWrap>
            <ButtonWrap>
              {eventButtonData?.map(({ title, url, btnType }) => {
                return (
                  <Button
                    key={title}
                    style={{ flex: 1 }}
                    buttonType={btnType}
                    buttonSize="extra"
                    text={title}
                    onClick={() => {
                      window.open(url, "_blank");
                    }}
                  />
                );
              })}
            </ButtonWrap>
          </MobileFooterWrap>
        )}
      </MobileInnerWrap>
    </MobileWrap>
  );
};

export default PreView;

type PreviewUrlType =
  | "notice"
  | "event"
  | "charger-description"
  | "charging-service-description"
  | string
  | (string | null)[]
  | null
  | undefined;

const MobileWrap = styled.div`
  background: no-repeat center / cover url(${IMAGES.PHONE_BACKGROUND});
  width: 395px;
  height: 817px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileInnerWrap = styled.div<{
  previewUrl: PreviewUrlType;
}>`
  ${({ previewUrl }) => {
    if (previewUrl === "charger-description") {
      return css`
        width: 378px;
      `;
    } else if (previewUrl === "charging-service-description") {
      return css`
        width: 378px;
      `;
    } else {
      return css`
        width: 360px;
      `;
    }
  }}

  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${colors.WHITE};
  position: relative;
  height: 782px;
  border-radius: 50px;

  /* scroll bar 제거 */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MobileHeaderWrap = styled.div<{
  previewUrl: PreviewUrlType;
}>`
  /*  */
  height: 44px;
  width: 375px;
  ${({ previewUrl }) => {
    if (previewUrl === "event" || previewUrl === "notice") {
      return css`
        background: no-repeat center / contain url(${IMAGES.PHONE_HEADER});
      `;
    } else if (previewUrl === "charger-description") {
      return css`
        background: no-repeat center / cover url(${IMAGES.CHARGER_PHONE_HEADER});
      `;
    } else if (previewUrl === "charging-service-description") {
      return css`
        background: no-repeat center / cover url(${IMAGES.CHARGER_PHONE_HEADER});
      `;
    }
  }}

  position: fixed;
  top: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50px 50px 0 0;
`;

const CommonHeaderWrap = styled.div<{
  previewUrl: PreviewUrlType;
}>`
  height: 60px;
  width: 376px;

  ${({ previewUrl }) => {
    if (previewUrl === "event") {
      return css`
        background: no-repeat center / cover url(${IMAGES.EVENT_HEADER});
      `;
    } else if (previewUrl === "notice") {
      return css`
        background: no-repeat center / cover url(${IMAGES.NOTICE_HEADER});
      `;
    } else if (previewUrl === "charger-description") {
      return css`
        background: no-repeat center / cover url(${IMAGES.CHARGER_HEADER});
      `;
    } else if (previewUrl === "charging-service-description") {
      return css`
        background: no-repeat center / cover
          url(${IMAGES.CHARGING_SERVICE_HEADER});
      `;
    }
  }}

  position: fixed;
  top: 59px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const CommonContentsWrap = styled.div<{
  previewUrl?: PreviewUrlType;
}>`
  /** 웹뷰 기본 스타일 적용 */
  ${({ previewUrl }) => {
    if (previewUrl === "event") {
      return css`
        padding: 0;
        margin-top: 104px;
      `;
    } else if (previewUrl === "charger-description") {
      return css`
        padding: 0 16px;
      `;
    } else if (previewUrl === "charging-service-description") {
      return css`
        padding: 0 16px;
      `;
    } else {
      return css`
        padding: 5px 10px 0px;
      `;
    }
  }}

  img {
    width: 100%;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 6px;
  }
  h3 {
    font-size: 18.72px;
    margin-bottom: 6px;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  strong {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }

  p {
    /* margin-bottom: 6px; */
    margin-bottom: 20px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  figure {
    width: 100%;
  }

  .table {
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid rgb(209, 215, 237);

      th {
        vertical-align: bottom;
        padding: 10px;
        border: 1px solid rgb(209, 215, 237);
      }

      td {
        vertical-align: bottom;
        padding: 10px;
        border: 1px solid rgb(209, 215, 237);
      }
    }
  }
`;

const MobileFooterWrap = styled.div`
  background-color: ${colors.WHITE};

  width: 92%;
  height: 76px;
  left: 50%;
  border-radius: 0 0 50px 50px;
  transform: translate(-50%, 0);

  position: fixed;
  bottom: 15px;

  ::after {
    content: "";
    display: block;
    width: 100%;
    height: 32px;
    position: absolute;
    top: -32px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

const NoticeTitleWrap = styled.div<{
  previewUrl: PreviewUrlType;
}>`
  ${({ previewUrl }) => {
    if (previewUrl === "notice") {
      return css`
        margin-top: 104px;
      `;
    }
  }}

  width: 100%;
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const NoticeTitle = styled.h2`
  ${typo.HEADING_4}
  color: ${colors.GRAY4};
`;

const NoticeDataAt = styled.small`
  ${typo.BODY_9}
  color: ${colors.GRAY6};
`;

const ChargerImageWrap = styled.div`
  position: fixed;
  top: 104px;
  width: 378px;
  height: 190px;
  background-color: ${colors.GRAY11};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChargerImage = styled.span<{ src?: string }>`
  background: red;
  width: 100%;
  min-width: 72px;
  max-width: 126px;
  height: 130px;
  background: no-repeat center / contain url(${({ src }) => src});
`;

const ChargerTitle = styled.div`
  width: 100%;
  margin-top: 326px;
  padding: 0 36px 16px;
  ${typo.HEADING_2};
`;

const ChargingServiceImageWrap = styled.div`
  position: fixed;
  top: 104px;
  width: 378px;
  height: 190px;
  background-color: ${colors.GRAY11};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChargingServiceImage = styled.span<{ src?: string }>`
  background: red;
  width: 100%;
  min-width: 72px;
  max-width: 126px;
  height: 130px;
  background: no-repeat center / contain url(${({ src }) => src});
`;

const ChargingServiceTitle = styled.div`
  width: 100%;
  margin-top: 326px;
  padding: 0 36px 16px;
  ${typo.HEADING_2};
`;

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px 20px;
  gap: 8px;
`;
