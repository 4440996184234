import { ICONS } from "@assets/icons";
import { colors } from "@styles/index";
import {
  DatePicker as CustomDataPicker,
  DatePickerProps as CustomDataPickerProps,
  Space,
} from "antd";
import { FormikContextType } from "formik";
import moment from "moment";
import React from "react";
import styled from "styled-components";

interface DatePickerProps {
  type: "basic" | "range";
  placeholder?: string;
  secondPlaceholder?: string;
  onChange: CustomDataPickerProps["onChange"];
  onChangeSecond?: CustomDataPickerProps["onChange"];
  formik: FormikContextType<any>;
  name: string;
  secondName?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  type = "basic",
  placeholder,
  secondPlaceholder,
  formik,
  secondName,
  name,
  onChange,
  onChangeSecond,
}) => {
  const dateFormat = "YYYY-MM-DD";
  const todayDate = moment().format();

  const isFirstValue = formik.values[name];
  const isSecondValue = secondName && formik.values[secondName];

  if (type === "range") {
    return (
      <RangeDateWrap>
        <CustomDataPicker
          onChange={onChange}
          placeholder={placeholder || "placeholder"}
          format={dateFormat}
          defaultValue={moment(todayDate, dateFormat)}
          value={isFirstValue ? moment(formik.values[name], dateFormat) : null}
        />
        <span />
        <CustomDataPicker
          onChange={onChangeSecond}
          placeholder={secondPlaceholder || "secondPlaceholder"}
          format={dateFormat}
          value={
            isSecondValue
              ? moment(secondName && formik.values[secondName], dateFormat)
              : null
          }
        />
      </RangeDateWrap>
    );
  }

  if (type === "basic") {
    return (
      <DataWrap direction="vertical">
        <CustomDataPicker
          onChange={onChange}
          placeholder={placeholder || "placeholder"}
          format={dateFormat}
          value={moment(formik.values[name], dateFormat)}
          defaultValue={moment(todayDate, dateFormat)}
        />
      </DataWrap>
    );
  }

  return null;
};

export default DatePicker;

const DataWrap = styled(Space)`
  width: 100%;

  .ant-space-item {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
    border-radius: 12px;
    min-height: 48px;

    input {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      ::placeholder {
        color: ${colors.GRAY6};
      }
    }

    .ant-picker-suffix {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: no-repeat center url(${ICONS.DATE_ICON.CALENDAR});
      margin: 0;

      svg {
        display: none;
      }
    }

    .ant-picker-clear {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: 20px;
        height: 20px;
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const RangeDateWrap = styled(DataWrap)`
  display: flex;
  width: 100%;
  gap: 16px !important;

  .ant-space-item:nth-child(2) {
    max-width: 14px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: inline-block;
      width: 14px;
      height: 100%;
      position: relative;

      ::after {
        content: "";
        display: inline-block;
        width: 14px;
        height: 1px;
        background: ${colors.GRAY8};
        position: absolute;
        top: 50%;
      }
    }
  }
`;
