import { TermsHistoryDetailResponse } from "@apis/terms/types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface TermsDataType extends TermsHistoryDetailResponse {
  updateReason?: string;
}

interface TermsDataStore {
  termsData: TermsDataType;
  setTermsData: (data: TermsDataType) => void;
  getTermsData: () => TermsDataStore;
  resetTermsData: () => void;
  setCategory: (category: string) => void;
}

const initialData: TermsDataType = {
  id: undefined,
  title: "",
  content: "",
  postStartAt: "",
  postEndAt: "",
  category: "",
  updateHistory: [],
  updateReason: "",
  createdAt: "",
  updatedAt: "",
  manager: "",
};

const useTermsDataStore = create<TermsDataStore>()(
  devtools((set, get) => ({
    termsData: { ...initialData },
    setTermsData: (data) => set({ termsData: { ...data } }),
    getTermsData: () => get(),
    resetTermsData: () => set({ termsData: { ...initialData } }),
    setCategory: (category) =>
      set((state) => ({
        termsData: {
          ...state.termsData,
          category,
        },
      })),
  })),
);

export default useTermsDataStore;
