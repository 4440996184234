import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import IconBox from "@components/atoms/IconBox";
import Noti from "@components/atoms/Noti";
import Editor from "@components/molecules/Editor";
import { ApiUrls } from "@constants/api-urls";
import useCommonCreator from "@hooks/useCommonCreator";
import { useFetchFaqCategoryOption } from "@hooks/useFetchFaqCategoryOption";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { colors, typo } from "@styles/index";
import {
  CommonElementType,
  RenderCommonElementByType,
} from "@utils/DynamicComponentUtils";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { FaqCommonContentsOptionType, FaqCreateData } from "./Data";

export const FaqNoti = (
  <Noti
    iconSrc={ICONS.NOTI_ICON.LIGHT_BULB}
    text="중분류는 고객에게 노출됩니다."
  />
);

const FaqCreate: React.FC = () => {
  // Props
  const HEADER_TITLE = "FAQ 등록";
  const CONTENT_TITLE = "FAQ 내용";

  // Data
  const { createBodyKey, formInitialValues } = FaqCreateData;

  // Formik
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      createMutate(values);
    },
  });

  // Hooks
  const { goBack } = useRouter();
  const { showConfirmModal, hideModal } = useGlobalModal();
  const { createMutate } = useCommonCreator({
    createUrl: ApiUrls.faq,
    createBodyKey,
  });

  const selectedCategory1 = useRef("");
  const selectedCategory2 = useRef("");

  const initCategoryOptionCode = "NOTF00";
  const { categoryOption: categoryCustomOption1 } = useFetchFaqCategoryOption(
    initCategoryOptionCode,
  );
  const {
    categoryOption: categoryCustomOption2,
    setCategoryOption: setCategoryCustomOption2,
  } = useFetchFaqCategoryOption(formik.values.category1);
  const {
    categoryOption: categoryCustomOption3,
    setCategoryOption: setCategoryCustomOption3,
  } = useFetchFaqCategoryOption(formik.values.category2);

  // Functions
  const handleGoBackClick = () => {
    const { category1, category2, category3, title, content } = formik.values;

    if (category1 || category2 || category3 || title || content) {
      showConfirmModal({
        title: `작성한 내용을 저장하지 않고\n 취소 하시겠어요?`,
        firstButtonText: "닫기",
        secondButtonText: "확인",
        onConfirm: () => {
          hideModal();
          goBack();
        },
      });
    } else {
      goBack();
    }
  };

  const handleCreateContent = () => {
    showConfirmModal({
      title: `등록하시겠어요?`,
      firstButtonText: "닫기",
      secondButtonText: "등록하기",
      onConfirm: () => {
        hideModal();
        formik.handleSubmit();
      },
    });
  };

  const createButtonValidate = useCallback(() => {
    const { category1, category2, category3, title, content } = formik.values;
    return !(category1 && category2 && category3 && title && content);
  }, [formik.values]);

  const handleCategoryChange = (categoryCode: string) => {
    if (selectedCategory1.current !== categoryCode) {
      selectedCategory2.current = "";
      formik.setFieldValue("category2", "");
      formik.setFieldValue("category3", "");
    }
    selectedCategory1.current = categoryCode;
  };

  const handleSubCategoryChange = (categoryCode: string) => {
    if (selectedCategory2.current !== categoryCode) {
      formik.setFieldValue("category3", "");
    }
    selectedCategory2.current = categoryCode;
  };

  useEffect(() => {
    if (formik.values.category1) {
      // 선택한 대분류가 있을 경우
      handleCategoryChange(formik.values.category1);
    } else {
      // 전체 선택시 하위 option 초기화
      setCategoryCustomOption2([]);
      setCategoryCustomOption3([]);
    }

    // 전체 선택시 하위 카테고리 초기화
    formik.setFieldValue("category2", "");
    formik.setFieldValue("category3", "");
  }, [formik.values.category1]);

  useEffect(() => {
    if (formik.values.category2) {
      // 선택한 중분류가 있을 경우
      handleSubCategoryChange(formik.values.category2);
    } else {
      // 전체 선택시 하위 option 초기화
      setCategoryCustomOption3([]);
    }

    // 전체 선택시 하위 카테고리 초기화
    formik.setFieldValue("category3", "");
  }, [formik.values.category2]);

  // Options & Elements : 카테고리의 옵션 값은 API로 받아옵니다.
  const contentsOption: FaqCommonContentsOptionType[] = [
    {
      label: "노출 상태",
      name: "isExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "대분류",
      name: "category1",
      type: CommonElementType.SELECT,
      placeholder: "대분류를 선택해주세요",
      options: categoryCustomOption1,
    },
    {
      label: "중분류",
      name: "category2",
      type: CommonElementType.SELECT,
      placeholder: "중분류를 선택해주세요",
      notice: FaqNoti,
      options: categoryCustomOption2,
    },
    {
      label: "소분류",
      name: "category3",
      type: CommonElementType.SELECT,
      placeholder: "소분류를 선택해주세요",
      options: categoryCustomOption3,
    },
    {
      label: "제목",
      name: "title",
      type: CommonElementType.INPUT,
      placeholder: "제목을 입력해주세요",
    },
  ];

  return (
    <ContentsWrap>
      <HeaderWrap>
        <IconBox
          width={32}
          height={32}
          src={ICONS.ARROW_ICON.BACK}
          isCursorPointer
          onClick={handleGoBackClick}
        />
        <HeaderTitle>{HEADER_TITLE}</HeaderTitle>
      </HeaderWrap>

      <div>
        <ContentBox style={{ padding: 0 }}>
          <ContentsHeader>{CONTENT_TITLE}</ContentsHeader>

          <ContentsMainWrap>
            {contentsOption?.map(
              ({ label, name, options, notice, ...res }, _) => {
                return (
                  <React.Fragment key={`${_}_contents`}>
                    <InputWrap>
                      <LabelWrap>
                        <LabelInnerWrap>
                          <Label>{label || "LABEL"}</Label>
                        </LabelInnerWrap>
                      </LabelWrap>
                      <InputContentsWrap noti={!!notice}>
                        {RenderCommonElementByType({
                          formik,
                          name,
                          options,
                          ...res,
                        })}
                        {notice && notice}
                      </InputContentsWrap>
                    </InputWrap>
                  </React.Fragment>
                );
              },
            )}

            <Editor formik={formik} initData={formik?.values.content ?? ""} />
          </ContentsMainWrap>
        </ContentBox>

        <FooterButtonWrap>
          <LeftButtonWrap />
          <RightButtonWrap>
            <Button
              text="취소"
              buttonType="line_black"
              onClick={handleGoBackClick}
            />
            <Button
              disabled={createButtonValidate()}
              text="등록하기"
              onClick={handleCreateContent}
            />
          </RightButtonWrap>
        </FooterButtonWrap>
      </div>
    </ContentsWrap>
  );
};

export default FaqCreate;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const HeaderTitle = styled(MainHeaderTitle)`
  margin: 0;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const ContentTitle = styled(TableTitle)`
  margin: 0;
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const ContentsHeader = styled(ContentTitle)`
  display: flex;
  justify-content: space-between;
`;

const ContentsMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 840px;
  margin: auto;
  padding: 52px 0 80px;
`;

const InputWrap = styled.div`
  display: flex;
  gap: 12px;
  min-height: 48px;
  width: 100%;
`;

const LabelWrap = styled.span`
  min-width: 200px;
  padding: 12px 0 12px;
`;

const LabelInnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const Label = styled.p`
  color: ${colors.GRAY6};
  ${typo.HEADING_7};
`;

const InputContentsWrap = styled.div<{ noti?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ noti }) =>
    noti &&
    css`
      flex-direction: column;
      gap: 12px;
    `}
`;

const FooterButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const LeftButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;

const RightButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;
