import {
  EventCommonContentsOptionType,
  EventNoti,
} from "@pages/EventCreate/Data";
import { CommonElementType } from "@utils/DynamicComponentUtils";
import { CommonCreateBodyKeyType, CommonDataType } from "src/types/common";

export interface EventDetailInitValuesType {
  title: string;
  postStartAt: string;
  postEndAt: string;
  isExposed: boolean;
  eventStartAt: string;
  eventEndAt: string;
  thumbnail: string;
  contentCategory: string;
  content: string;
  url: string;
  urls: string | undefined;
}

export const EventDetailData: CommonDataType<
  CommonCreateBodyKeyType,
  EventDetailInitValuesType,
  EventCommonContentsOptionType
> = {
  createBodyKey: [
    "title",
    "isExposed",
    "eventStartAt",
    "eventEndAt",
    "postStartAt",
    "postEndAt",
    "thumbnail",
    "urls",
    "content",
  ],
  formInitialValues: {
    title: "",
    postStartAt: "",
    postEndAt: "",
    isExposed: false,
    eventStartAt: "",
    eventEndAt: "",
    thumbnail: "",
    contentCategory: "content",
    content: "",
    url: "",
    urls: undefined,
  },
  contentsOption: [
    {
      label: "게시 날짜",
      name: "postStartAt",
      secondName: "postEndAt",
      type: CommonElementType.DATE_RANGE_PICKER,
      placeholder: "날짜를 입력해주세요",
      secondPlaceholder: "날짜를 입력해주세요",
    },
    {
      label: "노출 상태",
      name: "isExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "썸네일 이미지",
      name: "thumbnail",
      type: CommonElementType.IMAGE_UPLOAD,
      size: "medium",
      isSizeQuestion: true,
    },
    {
      label: "제목",
      name: "title",
      type: CommonElementType.INPUT,
      placeholder: "제목을 입력해주세요",
    },
    {
      label: "이벤트 기간",
      name: "eventStartAt",
      secondName: "eventEndAt",
      type: CommonElementType.DATE_RANGE_PICKER,
      placeholder: "날짜를 입력해주세요",
      secondPlaceholder: "날짜를 입력해주세요",
      notice: EventNoti,
    },
    {
      label: "내용",
      name: "contentCategory",
      type: CommonElementType.RADIO,
      options: [
        { label: "직접작성", value: "content" },
        { label: "URL 연결", value: "url" },
        { label: "이벤트 버튼 생성", value: "button" },
      ],
    },
  ],
};
