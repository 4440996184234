import { CommonElementType } from "@utils/DynamicComponentUtils";
import {
  CommonContentsRenderType,
  CommonCreateBodyKeyType,
  CommonDataType,
} from "src/types/common";

export interface AppVersionDetailInitValuesType {
  OS: string;
  version: string;
  releaseNote: string;
  isForcedUpdate: string;
}

export interface AppVersionCreateContentsOptionType
  extends CommonContentsRenderType {
  placeholder?: string;
  textAreaRows?: number;
}

export const AppVersionDetailData: CommonDataType<
  CommonCreateBodyKeyType,
  AppVersionDetailInitValuesType,
  AppVersionCreateContentsOptionType
> = {
  createBodyKey: ["OS", "version", "releaseNote", "isForcedUpdate"],
  formInitialValues: {
    OS: "",
    version: "",
    releaseNote: "",
    isForcedUpdate: "",
  },
  contentsOption: [
    {
      type: CommonElementType.INFO_INPUT,
      name: "OS",
      label: "OS",
    },
    {
      label: "강제 업데이트",
      name: "isForcedUpdate",
      type: CommonElementType.INFO_INPUT,
    },
    {
      type: CommonElementType.INFO_INPUT,
      name: "version",
      label: "버전",
    },
  ],
  secondContentsOption: [
    {
      label: "릴리즈 노트(선택)",
      name: "releaseNote",
      type: CommonElementType.TEXTAREA,
      placeholder: "릴리즈 노트를 입력해주세요",
      textAreaRows: 4,
    },
  ],
};
