import { CommonOptionType } from "src/types/common";

export const findUncommonElements = (prevArr: any[], currArr: any[]) => {
  const set1 = new Set(prevArr);
  const set2 = new Set(currArr);

  const uncommonElement = [
    ...prevArr.filter((item) => !set2.has(item)),
    ...currArr.filter((item) => !set1.has(item)),
  ];
  return uncommonElement;
};

type WithKey<T> = T & { [P in string]: any };

export function hasKey<T extends {}>(obj: T, key: string): obj is WithKey<T> {
  return key in obj;
}

export const imageUploadCategoryCheck = (pathname: string) => {
  const categories = ["event", "notice", "popup", "banner", "manual"];
  const matchedCategory = categories.find((category) =>
    pathname.includes(category),
  );
  return matchedCategory || "/";
};

interface IObject {
  [key: string]: any;
}

export function compareObjects(obj1: IObject, obj2: IObject) {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);

  return str1 === str2;
}

export const faqCategoriesFilter = (faqCategories: CommonOptionType[]) => {
  const option = faqCategories.filter(({ label }) => label !== "전체");

  return option;
};
