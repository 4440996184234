import { FormikValues } from "formik";

interface PreviewValiDateProps {
  formikValues: FormikValues;
  pathname: string;
}

function isPreviewValiDate({
  formikValues,
  pathname,
}: PreviewValiDateProps): boolean {
  const isEventPage = pathname.includes("event");
  const isNoticePage = pathname.includes("notice");
  const isChargerPage = pathname.includes("charger");
  const isChargingServicePage = pathname.includes("charging");

  const { title, content, urls, thumbnail, postStartAt, contentCategory } =
    formikValues;

  if (isEventPage) {
    if (contentCategory === "content") {
      return !!content;
    }

    if (contentCategory === "button") {
      return !!(content && urls && urls.length);
    }
  }

  if (isNoticePage) {
    return !!(content && title && postStartAt);
  }

  if (isChargerPage) {
    return !!(content && title && thumbnail);
  }

  if (isChargingServicePage) {
    return !!(content && title && thumbnail);
  }

  return false;
}

export default isPreviewValiDate;
