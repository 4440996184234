import useGlobalNotiStore from "@store/noti";
import BaseNoti from "../BaseNoti";

const NOTI_TYPES = {
  ConfirmNoti: "ConfirmNoti",
  DeleteNoti: "DeleteNoti",
  EditNoti: "EditNoti",
} as const;

const NOTI_COMPONENTS: any = {
  [NOTI_TYPES.ConfirmNoti]: BaseNoti,
  [NOTI_TYPES.DeleteNoti]: BaseNoti,
  [NOTI_TYPES.EditNoti]: BaseNoti,
};

const GlobalNoti: React.FC = () => {
  const {
    globalNoti: { notiProps, notiType },
  } = useGlobalNotiStore();

  const renderComponent = () => {
    if (!notiType) {
      return null;
    }
    const ModalComponent = NOTI_COMPONENTS[notiType];
    return <ModalComponent {...notiProps} />;
  };

  return <>{renderComponent()}</>;
};

export default GlobalNoti;
