import { TermsResponse } from "@apis/terms/types";
import ContentBox from "@components/atoms/ContentBox";
import Table from "@components/atoms/Table";
import Filter from "@components/molecules/Filter";
import { ApiUrls } from "@constants/api-urls";
import { PrivateRoutes } from "@constants/urls";
import { useFetchList } from "@hooks/useFetchList";
import { colors, typo } from "@styles/index";
import { Pagination } from "antd";
import React from "react";
import styled from "styled-components";
import { TermsInitialValues, termsData } from "./Data";

const TermsManagement: React.FC = () => {
  // Data
  const filter = termsData.forms;
  const formInitialValues = termsData.formInitialValues;
  const tableContentsInfoData = termsData.tableList;

  // Props
  const MAIN_TITLE = "약관 관리";
  const TABLE_TITLE = "약관 목록";
  const FILTER_GIRD_COLUMN = 2;

  // Hooks
  const QUERY_KEY = "termsList";
  const URL = ApiUrls.terms;
  const DETAIL_PATH = PrivateRoutes.TermsDetail;

  const {
    push,
    listData,
    formik,
    page,
    setPage,
    pageSize,
    totalCount,
    handleChangePageSize,
    handleFormReset,
  } = useFetchList<TermsResponse, TermsInitialValues>({
    url: URL,
    queryKey: QUERY_KEY,
    formInitialValues: formInitialValues,
  });

  /** 상세 페이지 이동 id */
  const handleDetailClick = (category: number | string) => {
    push(`${DETAIL_PATH}/${category}`);
  };

  return (
    <ContentsWrap>
      <MainHeaderTitle>{MAIN_TITLE}</MainHeaderTitle>

      <ContentBox>
        <Filter
          formik={formik}
          filterData={filter}
          girdColumn={FILTER_GIRD_COLUMN}
          formInitialValues={formInitialValues}
          handleFormReset={handleFormReset}
        />
      </ContentBox>

      <ContentBoxWithHeader>
        <TableHeader>
          <TableTitle>{TABLE_TITLE}</TableTitle>
        </TableHeader>

        <Table<TermsResponse>
          rowData={listData}
          tableContentsInfoData={tableContentsInfoData}
          currentPageSize={pageSize}
          onTermsDetailClick={handleDetailClick}
        />

        {/* pageNation */}
        <TableBottomWrap>
          <ContentsTotal>
            총<span>{totalCount}</span>
          </ContentsTotal>
          <PageNationWrap>
            <Pagination
              defaultCurrent={page}
              defaultPageSize={pageSize}
              total={totalCount}
              showSizeChanger
              locale={{ items_per_page: "개씩 보기" }}
              current={page}
              pageSize={pageSize}
              onShowSizeChange={handleChangePageSize}
              onChange={setPage}
            />
          </PageNationWrap>
        </TableBottomWrap>
      </ContentBoxWithHeader>
    </ContentsWrap>
  );
};

export default TermsManagement;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const ContentBoxWithHeader = styled.div`
  margin-top: 20px;
  padding: 22px 20px;
  background: ${colors.WHITE};
  border-radius: 16px;
  border: 1px solid ${colors.GRAY9};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const TableBottomWrap = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ContentsTotal = styled.span`
  display: flex;
  gap: 8px;
  color: ${colors.GRAY6};
  font-weight: 500;
  ${typo.BODY_8};

  span {
    ${typo.BODY_8};
    font-weight: 500;
    color: ${colors.GRAY2};
  }
`;

const PageNationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
