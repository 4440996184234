import { FilterElementType } from "@utils/DynamicComponentUtils/index";
import {
  forceUpdateCheck,
  textFormat,
  upDataAtFormat,
} from "@utils/TableItemFormattingUtils";
import { ColDef } from "ag-grid-community";
import { CommonFilterDataType, CommonFormType } from "src/types/common";

export interface AppVersionInitialValues {
  OS?: string;
}

export const appVersionData: CommonFilterDataType<
  AppVersionInitialValues,
  CommonFormType,
  ColDef
> = {
  formInitialValues: {
    OS: "",
  },
  forms: [
    {
      type: FilterElementType.SELECT,
      name: "OS",
      label: "OS",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "IOS",
          value: "IOS",
        },
        {
          label: "AOS",
          value: "AOS",
        },
      ],
    },
  ],
  tableList: [
    {
      field: "OS",
      headerName: "OS",
      maxWidth: 160,
      flex: 1,
      suppressMovable: true,
    },
    {
      field: "isForcedUpdate",
      headerName: "강제 업데이트",
      maxWidth: 160,
      flex: 1,
      suppressMovable: true,
      cellRenderer: forceUpdateCheck,
    },
    {
      field: "version",
      headerName: "버전 정보",
      maxWidth: 160,
      flex: 1,
      suppressMovable: true,
    },
    {
      field: "releaseNote",
      headerName: "릴리즈 노트",
      width: 750,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "updatedAt",
      headerName: "업데이트 일시",
      minWidth: 180,
      maxWidth: 200,
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
    {
      field: "manager",
      headerName: "마지막 수정",
      pinned: "right",
      suppressMovable: true,
      maxWidth: 180,
      cellRenderer: textFormat,
    },
  ],
};
