import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import IconBox from "@components/atoms/IconBox";
import { UploadSize } from "@components/atoms/Upload";
import { colors, typo } from "@styles/index";
import React, { useState } from "react";
import styled from "styled-components";

interface SizeNoticeBoxProps {
  size?: UploadSize;
}

const SizeNoticeBox: React.FC<SizeNoticeBoxProps> = ({ size }) => {
  const [isShow, setIsShow] = useState(false);

  const onQuestionClick = () => {
    setIsShow(true);
  };

  const hideQuestion = () => {
    setIsShow(false);
  };

  const contentsSize = () => {
    switch (size) {
      case "xsmall":
        return "150px x 150px";
      case "small":
        return "335px x 86px";
      case "medium":
        return "335px x 160px";
      case "large":
        return "375px x 280px";
      default:
        return "335px x 160px";
    }
  };

  return (
    <QuestionWrap>
      <IconBox
        src={ICONS.TEXT_ICON.QUESTION}
        onClick={onQuestionClick}
        width={20}
        height={20}
        isCursorPointer
      />

      {isShow && (
        <HelperWrap>
          <div>
            <TitleWrap>
              <Title>팝업 사이즈 안내</Title>
              <IconBox
                src={ICONS.NOTI_ICON.CLOSE_WHITE}
                width={20}
                height={20}
                isCursorPointer
                onClick={hideQuestion}
              />
            </TitleWrap>
            <TextWrap>
              {`이미지 제작 시 팝업 사이즈는`}
              <br />
              {`${contentsSize()} 이며, 이미지 추출 시`}
              <br />
              {`2배수 이상으로 추출해 주세요.`}
            </TextWrap>
          </div>
          <Button buttonSize="regular" text="알겠어요" onClick={hideQuestion} />
        </HelperWrap>
      )}
    </QuestionWrap>
  );
};

export default SizeNoticeBox;

const QuestionWrap = styled.div`
  position: relative;
  width: fit-content;
`;

const HelperWrap = styled.div`
  position: absolute;
  top: 32px;
  left: -20px;
  padding: 16px;
  background-color: ${colors.GRAY4};
  width: fit-content;
  min-width: 328px;
  height: 170px;
  border-radius: 10px;
  color: ${colors.WHITE};
  z-index: 15;

  > div {
    position: relative;

    :after {
      content: "";
      position: absolute;
      top: -35px;
      left: 5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent ${colors.GRAY4} transparent;
    }
  }
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

const Title = styled.p`
  ${typo.HEADING_5}
`;

const TextWrap = styled.p`
  ${typo.BODY_7}
  line-height: 20px;
  margin-bottom: 16px;
`;
