import { ResponseAuth } from "@apis/auth/types";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

interface AuthStore {
  userInfo?: ResponseAuth;
  setUserInfo: (props: { userInfo: ResponseAuth }) => void;
  resetUserInfo: () => void;
}

const initialData = {
  userInfo: undefined,
};

const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialData,
        setUserInfo: ({ userInfo }) => set({ userInfo }),
        resetUserInfo: () => set({ ...initialData }),
      }),
      {
        name: "userInfo", // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // customize the underlying storage instance
      },
    ),
  ),
);

export default useAuthStore;
