import { Rows } from "@apis/common/types";
import request from "@network/index";
import {
  FaqCategoryListResponse,
  FaqContentsResponse,
  UpdateSortedContentsRequest,
} from "./types";

// FAQ 카테고리 목록 조회 API
export const requestFetchFaqCategoryList = async (
  selectedCategoryCode?: string,
) => {
  const { data } = await request<void, Rows<FaqCategoryListResponse>>({
    method: "get",
    url: `/faqs/categories?code=${selectedCategoryCode}`,
  });

  return data.rows;
};

// FAQ 목록 조회 API
export const requestFetchFaq = async (
  type: "sortedCategories" | "sortedContents",
  contentsFetchCode?: string,
) => {
  let contentsFetchUrl = "";

  if (type === "sortedCategories" && contentsFetchCode) {
    contentsFetchUrl = `/faqs?category3=${contentsFetchCode}&page=1&pageSize=1000&sortByDisplayOrder=true`;
  }

  if (type === "sortedContents" && contentsFetchCode) {
    contentsFetchUrl = `/faqs?category2=${contentsFetchCode}&page=1&pageSize=1000`;
  } else if (!contentsFetchCode) {
    contentsFetchUrl = `/faqs?page=1&pageSize=1000`;
  }

  const { data } = await request<void, Rows<FaqContentsResponse>>({
    method: "get",
    url: contentsFetchUrl,
  });
  return data.rows;
};

// FAQ 카테고리 순번 수정 API
export const requestUpdateSortedCategory = async (
  body: UpdateSortedCategoryRequest,
) => {
  await request<UpdateSortedCategoryRequest, void>({
    method: "put",
    url: `/faqs/display-order-by-category`,
    requestBody: body,
  });
};

// FAQ 정렬 순서 수정 API
export const requestUpdateSortedContents = async (
  body: UpdateSortedContentsRequest,
) => {
  await request<UpdateSortedContentsRequest, void>({
    method: "put",
    url: `/faqs/display-order`,
    requestBody: body,
  });
};

export interface UpdateSortedCategoryRequest {
  parentCode: string;
  categoryDisplayOrders: {
    code: string | number;
    displayOrder: number;
  }[];
}
