import { FilterElementType } from "@utils/DynamicComponentUtils/index";
import {
  exposureStatusCheck,
  textFormat,
  upDataAtFormat,
} from "@utils/TableItemFormattingUtils";
import { ColDef } from "ag-grid-community";
import { CommonFilterDataType, CommonFormType } from "src/types/common";

export interface ChargerDescriptionInitialValues {
  title?: string;
  isExposed?: string;
  sort?: string;
  category?: string;
}

export const ChargerDescriptionData: CommonFilterDataType<
  ChargerDescriptionInitialValues,
  CommonFormType,
  ColDef
> = {
  formInitialValues: {
    title: "",
    isExposed: "",
    sort: "createdAt,desc",
    category: "charger",
  },
  forms: [
    {
      type: FilterElementType.INPUT,
      name: "title",
      label: "제목",
    },
    {
      type: FilterElementType.SELECT,
      name: "isExposed",
      label: "노출 상태",
      option: [
        {
          label: "전체",
          value: "",
        },
        {
          label: "노출",
          value: true,
        },
        {
          label: "미노출",
          value: false,
        },
      ],
    },
  ],
  tableList: [
    {
      field: "id",
      headerName: "게시물ID",
      maxWidth: 120,
      minWidth: 100,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "isExposed",
      headerName: "노출 상태",
      maxWidth: 120,
      flex: 1,
      suppressMovable: true,
      cellRenderer: exposureStatusCheck,
    },
    {
      field: "title",
      headerName: "제목",
      width: 750,
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "updatedAt",
      headerName: "업데이트 일시",
      minWidth: 180,
      maxWidth: 200,
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
    {
      field: "manager",
      headerName: "마지막 수정",
      pinned: "right",
      suppressMovable: true,
      maxWidth: 180,
      cellRenderer: textFormat,
    },
  ],
};
