import { UploadSize } from "@components/atoms/Upload";
import { CommonElementType } from "@utils/DynamicComponentUtils";
import {
  CommonContentsRenderType,
  CommonCreateBodyKeyType,
  CommonDataType,
} from "src/types/common";
import { ICONS } from "@assets/icons";
import Noti from "@components/atoms/Noti";

interface EventCreateInitValuesType {
  title: string;
  isExposed: boolean;
  eventStartAt: string;
  eventEndAt: string;
  postStartAt: string;
  postEndAt: string;
  thumbnail: string;
  contentCategory: string;
  category: string;
  content: string;
  urls: undefined;
  url: string;
}

export interface EventCommonContentsOptionType
  extends CommonContentsRenderType {
  size?: UploadSize;
  isSizeQuestion?: boolean;
  secondName?: string;
  placeholder?: string;
  secondPlaceholder?: string;
  notice?: JSX.Element;
}

export const EventNoti = (
  <Noti
    iconSrc={ICONS.NOTI_ICON.LIGHT_BULB}
    text="기간 내 이벤트는 진행중인 건으로 표출되며, 기간이 끝난 이벤트는 지난 이벤트로 표출됩니다."
  />
);

export const EventCreateData: CommonDataType<
  CommonCreateBodyKeyType,
  EventCreateInitValuesType,
  EventCommonContentsOptionType
> = {
  createBodyKey: [
    "title",
    "category",
    "isExposed",
    "eventStartAt",
    "eventEndAt",
    "postStartAt",
    "postEndAt",
    "thumbnail",
    "urls",
    "content",
  ],
  formInitialValues: {
    title: "",
    isExposed: false,
    eventStartAt: "",
    eventEndAt: "",
    postStartAt: "",
    postEndAt: "",
    thumbnail: "",
    contentCategory: "content",
    category: "",
    content: "",
    urls: undefined,
    url: "",
  },
  contentsOption: [
    {
      label: "게시 날짜",
      name: "postStartAt",
      secondName: "postEndAt",
      type: CommonElementType.DATE_RANGE_PICKER,
      placeholder: "날짜를 입력해주세요",
      secondPlaceholder: "날짜를 입력해주세요",
    },
    {
      label: "노출 상태",
      name: "isExposed",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "썸네일 이미지",
      name: "thumbnail",
      type: CommonElementType.IMAGE_UPLOAD,
      size: "medium",
      isSizeQuestion: true,
    },
    {
      label: "제목",
      name: "title",
      type: CommonElementType.INPUT,
      placeholder: "제목을 입력해주세요",
    },
    {
      label: "이벤트 기간",
      name: "eventStartAt",
      secondName: "eventEndAt",
      type: CommonElementType.DATE_RANGE_PICKER,
      placeholder: "날짜를 입력해주세요",
      secondPlaceholder: "날짜를 입력해주세요",
      notice: EventNoti,
    },
    {
      label: "내용",
      name: "contentCategory",
      type: CommonElementType.RADIO,
      options: [
        { label: "직접작성", value: "content" },
        { label: "URL 연결", value: "url" },
        { label: "이벤트 버튼 생성", value: "button" },
      ],
    },
  ],
};
