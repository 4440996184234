export const editorConfiguration = {
  removePlugins: ["Markdown", "MediaEmbedToolbar", "Title"],
  placeholder: "내용을 입력해주세요",
  // toolbar: {
  //   items: [
  //     "heading",
  //     "fontFamily",
  //     "fontSize",
  //     "|",
  //     "bold",
  //     "italic",
  //     "underline",
  //     "strikethrough",
  //     "|",
  //     "alignment",
  //     "bulletedList",
  //     "numberedList",
  //     "outdent",
  //     "indent",
  //     "|",
  //     "link",
  //     "imageUpload",
  //     "insertTable",
  //     "|",
  //     "undo",
  //     "redo",
  //     "|",
  //     "soruceEditing",
  //   ],
  // },
  fontSize: {
    options: [
      8,
      10,
      12,
      14,
      "16 (기본)",
      18,
      20,
      22,
      24,
      26,
      28,
      30,
      32,
      34,
      36,
      38,
      40,
      42,
      44,
      46,
      48,
      50,
      52,
      54,
      56,
      58,
      60,
    ],
  },
  link: {
    decorators: {
      openInNewTab: {
        mode: "manual",
        label: "Open in a new tab",
        defaultValue: true, // This option will be selected by default.
        attributes: {
          target: "_blank",
          rel: "",
        },
      },
    },
  },
  table: {
    tableProperties: {
      defaultProperties: {
        width: "100%",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "rgb(209, 215, 237)",
      },

      // The default styles for table cells in the editor.
      // They should be synchronized with the content styles.
      tableCellProperties: {
        defaultProperties: {
          // horizontalAlignment: "center",
          // verticalAlignment: "bottom",
        },
      },
    },
  },
};
