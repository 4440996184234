import { ConnectionModalProps } from "@components/molecules/Modal/ConnectionModal";
import { EventButtonCreateModalProps } from "@components/molecules/Modal/EventButtonCreateModal";
import { FAQOrderManagementModalProps } from "@components/molecules/Modal/FAQOrderManagementModal";
import { OneButtonModalProps } from "@components/molecules/Modal/OneButtonModal";
import { OrderManagementModalProps } from "@components/molecules/Modal/OrderManagementModal";
import { ManualOrderManagementModalProps } from "@components/molecules/Modal/ManualOrderManagementModal";
import { ReasonEditModalProps } from "@components/molecules/Modal/ReasonEditModal";
import { ReasonModificationModalProps } from "@components/molecules/Modal/ReasonModificationModal";
import { TermsEditorModalProps } from "@components/molecules/Modal/TermsEditorModal";
import { TwoButtonModalProps } from "@components/molecules/Modal/TwoButtonModal";
import { create } from "zustand";

export type ModalType =
  | "TwoButtonModal"
  | "OrderManagementModal"
  | "ConnectionModal"
  | "OneButtonModal"
  | "TermsEditorModal"
  | "ReasonEditModal"
  | "ReasonModificationModal"
  | "EventButtonCreateModal"
  | "OrderManagementModal"
  | "ManualOrderManagementModal"
  | "FAQOrderManagementModal"
  | null;

export interface GlobalModalType {
  modalType: ModalType;
  modalProps:
    | TwoButtonModalProps
    | OneButtonModalProps
    | ConnectionModalProps
    | ReasonEditModalProps
    | ReasonModificationModalProps
    | EventButtonCreateModalProps
    | TermsEditorModalProps
    | OrderManagementModalProps
    | ManualOrderManagementModalProps
    | FAQOrderManagementModalProps
    | null;
}

interface GlobalModalStore {
  globalModal: GlobalModalType;
  setGlobalModal: (modal: GlobalModalType) => void;
  hideGlobalModal: () => void;
}

const initialData = {
  globalModal: {
    modalType: null,
    modalProps: null,
  },
};

const useGlobalModalStore = create<GlobalModalStore>((set) => ({
  ...initialData,
  setGlobalModal: (modal) => set({ globalModal: modal }),
  hideGlobalModal: () =>
    set({
      globalModal: {
        modalProps: null,
        modalType: null,
      },
    }),
}));

export default useGlobalModalStore;
