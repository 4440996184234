import { create } from "zustand";

export type NotiType = "ConfirmNoti" | "DeleteNoti" | "EditNoti" | null;

export interface GlobalNotiType {
  notiType: NotiType;
  notiProps: any | null;
}

interface GlobalNotiStore {
  globalNoti: GlobalNotiType;
  setGlobalNoti: (noti: GlobalNotiType) => void;
  hideGlobalNoti: () => void;
}

const initialData = {
  globalNoti: {
    notiType: null,
    notiProps: null,
  },
};

const useGlobalNotiStore = create<GlobalNotiStore>((set) => ({
  ...initialData,
  setGlobalNoti: (noti) => set({ globalNoti: noti }),
  hideGlobalNoti: () =>
    set({
      globalNoti: {
        notiProps: null,
        notiType: null,
      },
    }),
}));

export default useGlobalNotiStore;
