import request from "@network/index";
import { Row, Rows, UploadData } from "./types";

/** 조회 */
export const requestFetchList = async <T>(url: string, params: string) => {
  const { data } = await request<void, Rows<T>>({
    method: "get",
    url: `${url}?${params}`,
  });
  return data;
};

/** 상세 조회 */
export const requestFetchDetail = async <T>(
  url: string,
  id?: string | (string | null)[],
) => {
  if (!id) return;
  const { data } = await request<void, Row<T>>({
    method: "get",
    url: `${url}/${id}`,
  });
  return data.row;
};

/** 삭제 */
export const requestDelete = async (
  url: string,
  id: string | (string | null)[],
) => {
  if (!id) return;
  await request<void, void>({
    method: "delete",
    url: `${url}/${id}`,
  });
};

/** 이미지 업로드 */
export const requestImageUpload = async (uploadData: UploadData) => {
  const formData = new FormData();
  formData.append("image", uploadData.imageFile);
  formData.append("category", uploadData.category);
  try {
    const { data } = await request<FormData, Rows<string>>({
      method: "post",
      url: "common/file-upload",
      requestBody: formData,
      isMultipart: true,
    });
    return data.rows[0];
  } catch (e) {
    console.error("IMAGE UPLOAD ERROR 🚀 >>>>> :", e);
  }
};
