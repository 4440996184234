import { requestDelete } from "@apis/common";
import useRouter from "./useRouter";
import useGlobalNoti from "./useGlobalNoti";
import useGlobalModal from "./useGlobalModal";
import { ErrorResponseType } from "@network/types";

interface UseCommonDeleteProps {
  deleteUrl: string;
}

const useCommonDelete = ({ deleteUrl }: UseCommonDeleteProps) => {
  const { goBack } = useRouter();
  const { showDeleteNoti } = useGlobalNoti();
  const { showAlertModal } = useGlobalModal();

  const handleDelete = async (id: string | (string | null)[]) => {
    await requestDelete(deleteUrl, id)
      .then(() => {
        showDeleteNoti({});
        goBack();
      })
      .catch((error: ErrorResponseType) => {
        console.error("📕 >>>> ERROR", error);
        if (
          error.message.includes("Token is not active") ||
          error.message.includes(
            "Refresh toked issued before the client session started",
          )
        )
          return;
        showAlertModal({
          title: error.message,
        });
      });
  };
  return {
    handleDelete,
  };
};

export default useCommonDelete;
