import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import IconBox from "@components/atoms/IconBox";
import CustomTextArea from "@components/atoms/TextArea";
import useGlobalModal from "@hooks/useGlobalModal";
import { ModalType } from "@store/modal";
import React, { useState } from "react";
import styled from "styled-components";
import BaseModal from "../BaseModal";
import {
  CloseIconWrap,
  HeaderTitle,
  ModalHeader,
} from "../OrderManagementModal/style";
import { ReasonCategoryType } from "../ReasonModificationModal";

export interface ReasonEditModalProps {
  category: ReasonCategoryType;
  modalType?: ModalType;
  onSave?: () => void;
  onClose?: () => void;
  onChangeReason: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ReasonEditModal: React.FC<ReasonEditModalProps> = ({
  category,
  modalType = "ReasonEditModal",
  onSave,
  onClose,
  onChangeReason,
}) => {
  const { hideModal } = useGlobalModal();

  const categoryTitleCheck = (category: string) => {
    switch (category) {
      case "notice":
        return "공지사항";
      case "faq":
        return "FAQ";
      case "terms":
        return "약관";
      default:
        return "공지사항";
    }
  };

  const [reasonLength, setReasonLength] = useState(0);
  return (
    <BaseModal
      isOpen
      modalWidth={50}
      disableModalPadding
      modalType={modalType}
      handleClose={onClose || hideModal}
    >
      <div>
        <ModalHeader>
          <HeaderTitle>{`${categoryTitleCheck(
            category,
          )} 수정사유 입력`}</HeaderTitle>
          <CloseIconWrap>
            <IconBox
              src={ICONS.MODAL_ICON.CLOSE}
              width={24}
              height={24}
              isCursorPointer
              onClick={onClose || hideModal}
            />
          </CloseIconWrap>
        </ModalHeader>
        <ModalContents>
          <CustomTextArea
            onChange={(e) => {
              // if (formik && name) {
              // formik.setFieldValue(name, e.target.value);
              // } else if (onChangeReason) {
              // }
              onChangeReason(e);
              setReasonLength(e.target.value.length);
            }}
            placeholder={"수정사유를 입력해주세요."}
            rows={3}
          />
        </ModalContents>
        <ModalFooter>
          <div>
            <Button
              buttonSize="lager"
              buttonType="text"
              text="닫기"
              onClick={onClose || hideModal}
            />
            <Button
              buttonSize="lager"
              text="저장하기"
              disabled={reasonLength === 0}
              onClick={onSave}
            />
          </div>
        </ModalFooter>
      </div>
    </BaseModal>
  );
};

export default ReasonEditModal;

const ModalContents = styled.div`
  padding: 24px 32px;
`;

const ModalFooter = styled.div`
  > div {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 0 32px 24px;
  }
`;
