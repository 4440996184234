import { requestFetchDetail } from "@apis/common";
import { ErrorResponseType } from "@network/types";
import { FormikContextType } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";
import useGlobalModal from "./useGlobalModal";
import useRouter from "./useRouter";
import { hasKey } from "@utils/data-fns";

interface UseFetchItemDataProps {
  id?: string | (string | null)[];
  fetchUrl: string;
  queryKey: string;
  formik: FormikContextType<any>;
}

const useFetchDetailData = <T extends {}>({
  id,
  fetchUrl,
  queryKey,
  formik,
}: UseFetchItemDataProps) => {
  const { showAlertModal, hideModal } = useGlobalModal();
  const { pathname, replace, goBack } = useRouter();
  const [initData, setInitData] = useState<T>();

  useQuery(queryKey, () => requestFetchDetail<T>(fetchUrl, id), {
    onSuccess: (data) => {
      if (!data) return;

      let customData = { ...data };

      // 이벤트 상세페이지에서는 contentCategory가 여러개로 나뉨
      if (pathname.includes("event") && hasKey(data, "category")) {
        customData = {
          ...data,
          contentCategory: data.category, // url | content | button
          ...(data.category === "url" && { url: data.urls[0].url }),
        };
      }

      // 공지사항은 contentCategory가 content로 통일
      if (pathname.includes("notice")) {
        customData = {
          ...data,
          contentCategory: "content",
        };
      }

      // 충전기 사용방법은 contentCategory가 content로 통일
      if (pathname.includes("charger")) {
        customData = {
          ...data,
          contentCategory: "content",
        };
      }

      // 충전서비스 이용방법은 contentCategory가 content로 통일
      if (pathname.includes("charging")) {
        customData = {
          ...data,
          contentCategory: "content",
        };
      }

      /** 앱 버전 업데이트 여부 체크 */
      if (pathname.includes("app") && hasKey(data, "isForcedUpdate")) {
        customData = {
          ...data,
          isForcedUpdate: data.isForcedUpdate ? "업데이트" : "미 업데이트",
        };
      }

      if (pathname.includes("faq")) {
        customData = {
          ...formik.initialValues,
          ...customData,
        };
      }

      if (pathname.includes("banner")) {
        customData = {
          ...customData,
          // bannerExposureLocation 값이 없을 경우 전체로 설정
          bannerExposureLocation: hasKey(customData, "bannerExposureLocation")
            ? customData.bannerExposureLocation
            : ["entire"],
          // 컨텐츠 게시기간
          ...(hasKey(data, "contentPostStartDate") && {
            contentPostStartAt: data.contentPostStartDate,
          }),
          ...(hasKey(data, "contentPostEndDate") && {
            contentPostEndAt: data.contentPostEndDate,
          }),
        };
      }

      setInitData(customData);
      formik.setValues(customData);
    },
    onError: (error: ErrorResponseType) => {
      setInitData(undefined);
      if (error.statusCode === 404) {
        replace("error/404", { state: { code: 404 } });
      } else if (error.statusCode === 500) {
        replace("error/500", { state: { code: 500 } });
      } else if (error.statusCode === 503) {
        replace("error/503", { state: { code: 503 } });
      } else if (error.statusCode !== 400 && error.statusCode !== 401) {
        showAlertModal({
          title: error.message,
          onConfirm: () => {
            goBack();
            hideModal();
          },
          handleClose: () => {
            goBack();
            hideModal();
          },
        });
      }
    },
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
  return { initData };
};

export default useFetchDetailData;
