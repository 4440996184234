import { ICONS } from "@assets/icons";
import IconBox from "@components/atoms/IconBox";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface ConnectionSelectItemProps {
  title: string;
  url?: string;
  onClick: () => void;
}

export const ConnectionSelectedItem: React.FC<ConnectionSelectItemProps> = ({
  title,
  url,
  onClick,
}) => {
  return (
    <ConnectionSelectedContainer>
      <TextWrap>
        <Title>{title}</Title>
        {url && (
          <>
            <span>|</span>
            <UrlText>{url}</UrlText>
          </>
        )}
      </TextWrap>

      <IconBox
        onClick={onClick}
        isCursorPointer
        src={ICONS.BUTTON_ICON.CLOSE_RED}
        width={24}
        height={24}
      />
    </ConnectionSelectedContainer>
  );
};

const Title = styled.p``;
const UrlText = styled.p``;

const ConnectionSelectedContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  justify-content: space-between;
  padding: 18px 20px;
  border: 1px solid ${colors.GRAY9};
  border-radius: 12px;
  max-height: 56px;
`;

const TextWrap = styled.div`
  color: ${colors.GRAY2};
  ${typo.BODY_8};

  display: flex;
  width: 100%;
  gap: 20px;

  > span {
    color: ${colors.GRAY8};
  }
`;
