import { Row } from "@apis/common/types";
import { TermsDetailResponse } from "@apis/terms/types";
import request from "@network/index";

//  배너 연결 약관 이력 조회 API
export const requestConnectTermsHistory = async (category?: string) => {
  const { data } = await request<void, Row<TermsDetailResponse>>({
    method: "get",
    url: `/terms/history?category=${category}&isExpired=true&pageSize=1000`,
  });

  return data.row;
};
