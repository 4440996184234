import { colors, typo } from "@styles/index";
import styled, { css } from "styled-components";

export const ModalHeader = styled.div`
  padding: 33px 32px 19px;
  position: relative;
  border-bottom: 1px solid ${colors.GRAY10};
`;

export const HeaderTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_2};
`;

export const CloseIconWrap = styled.span`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const ModalContents = styled.div`
  padding: 24px 32px;
  min-height: 600px;
  display: flex;
`;

export const ContentsSideTitle = styled.div`
  flex: 1;
  max-width: 143px;
  ${typo.HEADING_6};
  color: ${colors.GRAY2};
`;

export const ContentWrap = styled.div`
  flex: 4;
  width: 100%;
`;

export const ContentTitle = styled.p`
  padding-left: 60px;
  color: ${colors.GRAY6};
  ${typo.HEADING_9};
  margin-bottom: 16px;
`;

export const ContentsDataWrap = styled.ul<{ maxHeight?: number }>`
  min-height: 300px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}vh` : "41vh")};
  overflow: auto;
`;

export const ContentItemWrap = styled.li<{ isDragging?: boolean }>`
  display: flex;
  gap: 28px;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${colors.GRAY9};
  background: ${colors.WHITE};
  cursor: pointer;
  color: ${colors.GRAY2};
  margin-bottom: 12px;
  transition: 0.3s;

  /* item 드래그시 style */
  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        box-shadow: 0px 8px 32px rgba(53, 58, 63, 0.1);
      `;
    }
  }}

  .itemNumber {
    ${typo.HEADING_7};
  }

  .itemContent {
    ${typo.BODY_7};
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid ${colors.GRAY10};
  padding: 20px 32px;
  display: flex;
  flex-direction: row-reverse;
`;

export const FooterButtonWrap = styled.div``;
