import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import IconBox from "@components/atoms/IconBox";
import Layout from "@components/templates/Layout";
import useRouter from "@hooks/useRouter";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface ErrorPageProps {}

const ErrorPage: React.FC<ErrorPageProps> = ({}) => {
  const { goBack, state, query } = useRouter();

  const getErrorComponent = (code: number) => {
    switch (code) {
      case 404:
        return (
          <>
            <IconBox
              src={ICONS.ERROR_ICON.ERROR_404}
              width={320}
              height={320}
            />
            <Text>{`찾을수 없는 페이지 입니다\n요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨어요`}</Text>
            <Button
              buttonSize="lager"
              text="이전 페이지로 이동"
              onClick={goBack}
            />
          </>
        );
      case 500:
        return (
          <>
            <IconBox
              src={ICONS.ERROR_ICON.ERROR_500}
              width={320}
              height={320}
            />
            <Text>{`서버에 문제가 발생했습니다\n한번 시도해주세요`}</Text>
            <Button buttonSize="lager" text="새로고침" onClick={goBack} />
          </>
        );
      case 503:
        return (
          <>
            <IconBox
              src={ICONS.ERROR_ICON.ERROR_503}
              width={320}
              height={320}
            />
            <Text>{`서비스를 이용할 수 없습니다\n잠시 뒤에 다시 시도해주세요`}</Text>
            <Button buttonSize="lager" text="다시시도" onClick={goBack} />
          </>
        );
      default:
        return (
          <>
            <IconBox
              src={ICONS.ERROR_ICON.ERROR_503}
              width={320}
              height={320}
            />
            <Text>{`서비스를 이용할 수 없습니다\n잠시 뒤에 다시 시도해주세요`}</Text>
            <Button buttonSize="lager" text="다시시도" onClick={goBack} />
          </>
        );
    }
  };

  return (
    <Layout>
      <Main>{getErrorComponent(state?.code || 404)}</Main>
    </Layout>
  );
};

export default ErrorPage;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  background: ${colors.GRAY11};
`;

const Text = styled.p`
  white-space: pre-wrap;
  text-align: center;
  ${typo.BODY_7};
  font-weight: 500;
  color: ${colors.GRAY2};
`;
