import FilterDatePick from "@components/atoms/FilterDatePicker";
import AntCustomSelect from "@components/atoms/FilterSelect/AntCustomSelect";
import Input from "@components/atoms/Input";

import { FormikContextType, FormikValues } from "formik";
import moment from "moment";
import styled from "styled-components";
import { formatVersionString } from "../string-utils";
import Toggle from "@components/atoms/Toggle";
import DatePicker from "@components/atoms/DatePicker";
import CustomUpload, { UploadSize } from "@components/atoms/Upload";
import { RadioChangeEvent } from "antd";
import CustomRadio from "@components/atoms/Radio";
import CustomTextArea from "@components/atoms/TextArea";
import { useEffect } from "react";
import { CommonOptionType } from "src/types/common";

export enum FilterElementType {
  INPUT,
  SELECT,
  DATE_RANGE_PICKER,
}

export enum CommonElementType {
  INPUT,
  INFO_INPUT,
  SELECT,
  TOGGLE,
  DATE_RANGE_PICKER,
  DATE_PICKER,
  IMAGE_UPLOAD,
  RADIO,
  TEXTAREA,
  CHECK_BOX,
}

interface SearchFormProps<T> {
  type: FilterElementType;
  option?: CommonOptionType[];
  name: string;
  secondName?: string;
  formik: FormikContextType<T>;
  disabled?: boolean;
}

export const RenderSearchFormByType = <T extends FormikValues>({
  type,
  option,
  name,
  secondName,
  disabled,
  formik,
}: SearchFormProps<T>) => {
  switch (type) {
    case FilterElementType.INPUT: {
      const handleKeyDown = (event: React.KeyboardEvent<any>) => {
        if (event.key === "Enter") {
          formik.handleSubmit();
        }
      };

      return (
        <Input
          id={name}
          name={name}
          onChange={formik.handleChange}
          value={formik.values[name]}
          type="text"
          placeholder="입력"
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
      );
    }
    case FilterElementType.SELECT: {
      return (
        <SelectWrap>
          <AntCustomSelect
            type="filter"
            options={option}
            disabled={!option}
            value={formik.values[name] === "" ? "전체" : formik?.values[name]}
            onChange={(value) => {
              formik.setFieldValue(name, value);
              formik.handleSubmit();
            }}
            defaultValue={option ? option[0]?.label : "전체"}
          />
        </SelectWrap>
      );
    }
    case FilterElementType.DATE_RANGE_PICKER: {
      const DATE_FORMAT = "YYYY-MM-DD";

      const isFirstValue = formik.values[name];
      const isSecondValue = secondName && formik.values[secondName];

      const value: [moment.Moment | null, moment.Moment | null] = [
        isFirstValue ? moment(formik.values[name], DATE_FORMAT) : null,
        isSecondValue
          ? moment(secondName && formik.values[secondName], DATE_FORMAT)
          : null,
      ];

      return (
        <FilterDatePick
          onChange={(value, dateString) => {
            const startDate = dateString[0];
            const endDate = dateString[1];

            formik.setFieldValue(name, startDate);

            if (secondName) {
              formik.setFieldValue(secondName, endDate);
              formik.handleSubmit();
            }
          }}
          value={value}
          defaultValue={[null, null]}
          style={{ width: "100%" }}
        />
      );
    }
  }
};

interface RenderCommonElementByTypeProps<T> {
  type: CommonElementType;
  name: string;
  formik: FormikContextType<T>;
  placeholder?: string;
  secondPlaceholder?: string;
  options?: CommonOptionType[];
  size?: UploadSize;
  textAreaRows?: number;
  isButton?: boolean;
  secondName?: string;
}

export const RenderCommonElementByType = <T extends FormikValues>({
  type,
  name,
  formik,
  isButton,
  options,
  placeholder,
  secondName,
  secondPlaceholder,
  size,
  textAreaRows,
}: RenderCommonElementByTypeProps<T>) => {
  useEffect(() => {
    const today = moment();
    if (name === "postStartAt" && formik?.values[name] === "")
      formik?.setFieldValue(name, today.format("YYYY-MM-DD"));

    if (name === "eventStartAt" && formik?.values[name] === "")
      formik?.setFieldValue(name, today.format("YYYY-MM-DD"));
  }, []);

  switch (type) {
    case CommonElementType.INPUT: {
      return (
        <Input
          inputType="create"
          id={name}
          name={name}
          onChange={(event) => {
            if (name === "version") {
              const formatText = formatVersionString(event.target.value);
              formik.setFieldValue(name, formatText);
              return;
            } else {
              formik.handleChange(event);
            }
          }}
          value={formik.values[name]}
          full
          placeholder={placeholder}
        />
      );
    }
    case CommonElementType.SELECT: {
      return (
        <AntCustomSelect
          type="create"
          options={options}
          value={formik.values[name] === "" ? "전체" : formik.values[name]}
          onChange={(value) => {
            formik.setFieldValue(name, value);
          }}
          defaultValue={options ? options[0]?.label : "전체"}
        />
      );
    }
    case CommonElementType.TOGGLE: {
      return (
        <Toggle
          onClick={() => {
            formik.setFieldValue(name, !formik.values[name]);
          }}
          state={formik.values[name]}
        />
      );
    }
    case CommonElementType.DATE_RANGE_PICKER: {
      return (
        <DatePicker
          type="range"
          placeholder={placeholder}
          secondPlaceholder={secondPlaceholder}
          formik={formik}
          name={name}
          secondName={secondName}
          onChange={(date, dateString) => {
            formik.setFieldValue(name, dateString);
          }}
          onChangeSecond={(date, dateString) => {
            if (secondName) {
              formik.setFieldValue(secondName, dateString);
            }
          }}
        />
      );
    }
    case CommonElementType.DATE_PICKER: {
      return (
        <DatePicker
          type="basic"
          formik={formik}
          name={name}
          onChange={(date, dateString) => {
            formik.setFieldValue(name, dateString);
          }}
          placeholder={placeholder}
        />
      );
    }
    case CommonElementType.IMAGE_UPLOAD: {
      if (size) {
        return <CustomUpload formik={formik} name={name} uploadSize={size} />;
      }
      return null;
    }
    case CommonElementType.RADIO: {
      return (
        <CustomRadio
          isButton={isButton}
          options={options}
          value={formik.values[name]}
          onChange={(value: RadioChangeEvent) => {
            formik.setFieldValue(name, value.target.value);
          }}
        />
      );
    }
    case CommonElementType.TEXTAREA: {
      return (
        <CustomTextArea
          onChange={(e) => {
            formik.setFieldValue(name, e.target.value);
          }}
          value={formik.values[name]}
          placeholder={placeholder}
          rows={textAreaRows}
        />
      );
    }
    case CommonElementType.INFO_INPUT: {
      return (
        <Input inputType="detailInfo" value={name && formik?.values[name]} />
      );
    }
    // case CommonElementType.CHECK_BOX: {
    // }
  }
};

export const SelectWrap = styled.div`
  width: 100%;
  position: relative;
  background: white;
`;
