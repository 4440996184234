import {
  exposureStatusCheck,
  textFormat,
  upDataAtFormat,
} from "@utils/TableItemFormattingUtils";
import { ColDef } from "ag-grid-community";

export interface ColumnDefs {
  notices: ColDef[];
  events: ColDef[];
  terms: ColDef[];
}

export const columnDefs: ColumnDefs = {
  notices: [
    {
      field: "isSelect",
      headerName: "선택",
      maxWidth: 62,
      flex: 1,
      suppressMovable: true,
      suppressSizeToFit: true,
    },
    {
      field: "isExposed",
      headerName: "노출 상태",
      maxWidth: 120,
      flex: 1,
      cellRenderer: exposureStatusCheck,
      suppressMovable: true,
    },
    {
      field: "publishDuration",
      headerName: "게시 기간",
      flex: 1,
      cellRenderer: textFormat,
      suppressMovable: true,
    },
    {
      field: "title",
      headerName: "제목",
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "updatedAt",
      headerName: "업데이트 일시",
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
  ],
  events: [
    {
      field: "isSelect",
      headerName: "선택",
      maxWidth: 62,
      flex: 1,
      suppressMovable: true,
      suppressSizeToFit: true,
    },
    {
      field: "isExposed",
      headerName: "노출 상태",
      maxWidth: 120,
      flex: 1,
      cellRenderer: exposureStatusCheck,
      suppressMovable: true,
    },
    {
      field: "publishDuration",
      headerName: "게시 기간",
      flex: 1,
      cellRenderer: textFormat,
      suppressMovable: true,
    },
    {
      field: "title",
      headerName: "제목",
      flex: 1,
      suppressMovable: true,
      cellRenderer: textFormat,
    },
    {
      field: "updatedAt",
      headerName: "업데이트 일시",
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
  ],
  terms: [
    {
      field: "isSelect",
      headerName: "선택",
      maxWidth: 62,
      flex: 1,
      suppressMovable: true,
      suppressSizeToFit: true,
    },
    {
      field: "isExposed",
      headerName: "노출 상태",
      maxWidth: 200,
      minWidth: 140,
      flex: 1,
      cellRenderer: exposureStatusCheck,
      suppressMovable: true,
    },
    {
      field: "postStartAt",
      headerName: "게시 기간",
      minWidth: 150,
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
    {
      field: "createdAt",
      headerName: "업데이트 일시",
      minWidth: 180,
      flex: 1,
      suppressMovable: true,
      cellRenderer: upDataAtFormat,
    },
  ],
};
