import regex from "@constants/regex";
import { FormikValues } from "formik";

interface CreateValiDateProps {
  formikValues: FormikValues;
  validateKey: string[];
  pathname: string;
}

function isCheckValueExistence({
  formikValues,
  validateKey,
  pathname,
}: CreateValiDateProps): boolean {
  let customValue: { [x: string]: any };

  const isEventPage = pathname.includes("event");
  const isNoticePage = pathname.includes("notice");
  const isAppPage = pathname.includes("app");

  const customKey = validateKey.filter((key) => {
    // 공통 isExposed는 제외해야됨📘
    if (key === "isExposed") return false;
    if (key === "updateReason") return false;

    // 공지사항 : 상단고정 여부
    if (isNoticePage) {
      if (key === "isPriorityExposed") return false;
    }

    // 이벤트 : 카테고리에 따라서 유효성 검사를 다르게 해줍니다.
    if (isEventPage) {
      const { contentCategory } = formikValues;

      if (contentCategory === "content") {
        if (key === "urls") return false;
      }
      if (contentCategory === "url") {
        if (key === "content") return false;
      }
      if (contentCategory === "button") {
        if (key === "url") return false;
      }
    }

    // 앱 버전
    if (isAppPage) {
      if (key === "releaseNote") return false;
      if (key === "isForcedUpdate") return false;
    }

    return key;
  });

  customValue = {
    ...formikValues,
  };

  // url일 경우 값을 urls에 넣어서 유효성 검사를 해줘야 된다.
  if (isEventPage) {
    const { contentCategory, url } = formikValues;

    customValue = {
      ...customValue,
      category: contentCategory,
      ...(contentCategory === "url" && url && { urls: [{ url }] }),
    };
  }

  const isUrlRegex = (url: string) => {
    const urlRegex = regex.url;
    return urlRegex.test(url);
  };

  return customKey.every((key) => {
    if (key === "urls") {
      if (customValue[key]) {
        return customValue[key].every((url: { url: string }) => {
          return isUrlRegex(url.url);
        });
      }
    }
    return !!customValue[key];
  });
}

export default isCheckValueExistence;
