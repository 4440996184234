import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

import { Input } from "antd";

const { TextArea } = Input;

interface CustomTextAreaProps {
  placeholder?: string;
  rows?: number;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  placeholder,
  rows = 5,
  value,
  onChange,
}) => {
  return (
    <StyledTextArea
      rows={rows}
      onChange={onChange}
      placeholder={placeholder || "placeholder"}
      value={value}
    />
  );
};

export default CustomTextArea;

const StyledTextArea = styled(TextArea)`
  border-radius: 8px;
  border: 1px solid ${colors.GRAY9};
  padding: 12px;
  ${typo.BODY_7};

  ::placeholder {
    color: ${colors.GRAY6};
  }
`;
