import { CommonElementType } from "@utils/DynamicComponentUtils";
import {
  CommonContentsRenderType,
  CommonCreateBodyKeyType,
  CommonDataType,
} from "src/types/common";

interface AppVersionCreateInitValuesType {
  OS: string;
  version: string;
  releaseNote: string;
  isForcedUpdate: boolean;
}

export interface AppVersionCreateContentsOptionType
  extends CommonContentsRenderType {
  placeholder?: string;
  textAreaRows?: number;
}

export const AppVersionCreateData: CommonDataType<
  CommonCreateBodyKeyType,
  AppVersionCreateInitValuesType,
  AppVersionCreateContentsOptionType
> = {
  createBodyKey: ["OS", "version", "releaseNote", "isForcedUpdate"],
  formInitialValues: {
    OS: "IOS",
    version: "",
    releaseNote: "",
    isForcedUpdate: false,
  },
  contentsOption: [
    {
      label: "OS",
      name: "OS",
      type: CommonElementType.RADIO,
      options: [
        { label: "IOS", value: "IOS" },
        { label: "AOS", value: "AOS" },
      ],
    },
    {
      label: "강제 업데이트",
      name: "isForcedUpdate",
      type: CommonElementType.TOGGLE,
    },
    {
      label: "버전",
      name: "version",
      type: CommonElementType.INPUT,
      placeholder: "버전을 입력해주세요",
    },
    {
      label: "릴리즈 노트(선택)",
      name: "releaseNote",
      type: CommonElementType.TEXTAREA,
      placeholder: "릴리즈 노트를 입력해주세요",
      textAreaRows: 4,
    },
  ],
};
