import { DatePicker } from "antd";
import moment from "moment";
import React from "react";

interface FilterDatePickProps {
  style?: React.CSSProperties;
  className?: string;
  defaultValue?: [moment.Moment | null, moment.Moment | null];
  value?: [moment.Moment | null, moment.Moment | null];
  onChange?: (values: any, formatString: [string, string]) => void;
}

const FilterDatePick: React.FC<FilterDatePickProps> = ({
  style,
  className,
  defaultValue,
  value,
  onChange,
}) => {
  const { RangePicker } = DatePicker;

  return (
    <RangePicker
      style={style}
      className={className}
      // value={[null, null]}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={["시작 날짜", "게시 종료"]}
    />
  );
};

export default FilterDatePick;
