import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import IconBox from "@components/atoms/IconBox";
import RenderEventCategoryContent from "@components/molecules/RenderEventCategoryContent";
import { ApiUrls } from "@constants/api-urls";
import { PrivateRoutes } from "@constants/urls";
import useCommonCreator from "@hooks/useCommonCreator";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { colors, typo } from "@styles/index";
import { RenderCommonElementByType } from "@utils/DynamicComponentUtils";
import isCheckValueExistence from "@utils/check-value-existence";
import isPreviewValiDate from "@utils/preview-validate";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { NoticeCreateData } from "./Data";

const NoticeCreate: React.FC = () => {
  // Props
  const HEADER_TITLE = "공지사항 등록";
  const CONTENT_TITLE = "공지사항 내용";
  const PREVIEW_URL = "notice";

  // Data
  const { contentsOption, createBodyKey, formInitialValues } = NoticeCreateData;

  // Hooks
  const { goBack, pathname } = useRouter();
  const { showConfirmModal, hideModal } = useGlobalModal();
  const { createMutate } = useCommonCreator({
    createUrl: ApiUrls.notice,
    createBodyKey,
  });

  // Formik
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => {
      createMutate(values);
    },
  });

  // Functions
  const handleGoBackClick = () => {
    localStorage.removeItem("previewData");
    const { content, title, postEndAt } = formik.values;

    if (title || content || postEndAt) {
      showConfirmModal({
        title: `작성한 내용을 저장하지 않고\n 취소 하시겠어요?`,
        firstButtonText: "닫기",
        secondButtonText: "확인",
        onConfirm: () => {
          hideModal();
          goBack();
        },
      });
    } else {
      goBack();
    }
  };

  const handleCreateContent = () => {
    formik.handleSubmit();
  };

  const handlePreview = () => {
    const previewData = formik.values;
    localStorage.setItem("previewData", JSON.stringify(previewData));
    window.open(`${PrivateRoutes.PreView}?type=${PREVIEW_URL}`, "_blank");
  };

  const isCreateButtonValidate = useCallback(() => {
    return !isCheckValueExistence({
      formikValues: formik.values,
      validateKey: createBodyKey,
      pathname,
    });
  }, [createBodyKey, formik.values, pathname]);

  const isPreviewValidateCheck = useCallback(() => {
    return !isPreviewValiDate({
      formikValues: formik.values,
      pathname,
    });
  }, [formik.values]);

  return (
    <ContentsWrap>
      <HeaderWrap>
        <IconBox
          width={32}
          height={32}
          src={ICONS.ARROW_ICON.BACK}
          isCursorPointer
          onClick={handleGoBackClick}
        />
        <HeaderTitle>{HEADER_TITLE}</HeaderTitle>
      </HeaderWrap>

      <div>
        <ContentBox style={{ padding: 0 }}>
          <ContentsHeader>{CONTENT_TITLE}</ContentsHeader>

          <ContentsMainWrap>
            {contentsOption?.map(({ label, name, options, ...res }, _) => {
              return (
                <React.Fragment key={`${_}_contents`}>
                  <InputWrap>
                    <LabelWrap>
                      <LabelInnerWrap>
                        <Label>{label || "LABEL"}</Label>
                      </LabelInnerWrap>
                    </LabelWrap>
                    <InputContentsWrap>
                      {RenderCommonElementByType({
                        formik,
                        name,
                        options,
                        ...res,
                      })}
                    </InputContentsWrap>
                  </InputWrap>
                </React.Fragment>
              );
            })}

            <RenderEventCategoryContent
              formik={formik}
              category={formik.values.contentCategory}
            />
          </ContentsMainWrap>
        </ContentBox>

        <FooterButtonWrap>
          <LeftButtonWrap>
            <Button
              text="미리보기"
              buttonType="line_primary"
              disabled={isPreviewValidateCheck()}
              onClick={handlePreview}
            />
          </LeftButtonWrap>
          <RightButtonWrap>
            <Button
              text="취소"
              buttonType="line_black"
              onClick={handleGoBackClick}
            />
            <Button
              disabled={isCreateButtonValidate()}
              text="등록하기"
              onClick={handleCreateContent}
            />
          </RightButtonWrap>
        </FooterButtonWrap>
      </div>
    </ContentsWrap>
  );
};

export default NoticeCreate;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const HeaderTitle = styled(MainHeaderTitle)`
  margin: 0;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const ContentTitle = styled(TableTitle)`
  margin: 0;
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const ContentsHeader = styled(ContentTitle)`
  display: flex;
  justify-content: space-between;
`;

const ContentsMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 840px;
  margin: auto;
  padding: 52px 0 80px;
`;

const InputWrap = styled.div`
  display: flex;
  gap: 12px;
  min-height: 48px;
  width: 100%;
`;

const LabelWrap = styled.span`
  min-width: 200px;
  padding: 12px 0 12px;
`;

const LabelInnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const Label = styled.p`
  color: ${colors.GRAY6};
  ${typo.HEADING_7};
`;

const InputContentsWrap = styled.div<{ noti?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ noti }) =>
    noti &&
    css`
      flex-direction: column;
      gap: 12px;
    `}
`;

const FooterButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const LeftButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;

const RightButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;
