import SideMainMenu from "@components/molecules/SideMainMenu";
import useRouter from "@hooks/useRouter";
import { colors, typo } from "@styles/index";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ASIDE_MENU_LIST } from "./Data";

interface SideMenuBarProps {
  className?: string;
}

const SideMenuBar: React.FC<SideMenuBarProps> = ({ className }) => {
  // SideMenu openInit 설정
  const isOpenInit = true;
  const { pathname } = useRouter();
  return (
    <SideMenuWrap className={className}>
      {ASIDE_MENU_LIST.map((menu) => {
        return (
          <div key={menu.path}>
            <SideMainMenu
              title={menu.title}
              iconSrc={menu.labelIcon}
              isOpenInit={isOpenInit}
            >
              {menu.subMenu.map((subMenu) => {
                return (
                  <Link to={subMenu.path} key={subMenu.path}>
                    <SubMenuItem isActive={pathname.includes(subMenu.path)}>
                      <SubMenuItemInner>
                        <SubMenuTitle>{subMenu.title}</SubMenuTitle>
                      </SubMenuItemInner>
                    </SubMenuItem>
                  </Link>
                );
              })}
            </SideMainMenu>
          </div>
        );
      })}
    </SideMenuWrap>
  );
};

export default SideMenuBar;

const SideMenuWrap = styled.div`
  border-right: 1px solid ${colors.GRAY9};
  width: 250px;
  height: 100%;
  padding: 24px 0;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 0; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const SubMenuTitle = styled.span`
  color: ${colors.GRAY6};
  ${typo.BODY_8};
  font-weight: 500;
`;

const SubMenuItemInner = styled.div`
  padding: 12px 12px 12px 48px;
`;

const SubMenuItem = styled.div<{ isActive?: boolean }>`
  position: relative;
  cursor: pointer;
  padding: 0 8px;

  ${({ isActive }) =>
    isActive &&
    css`
      ${SubMenuItemInner} {
        background: ${colors.PRIMARY7};
        border-radius: 8px;
      }

      ::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        background: ${colors.PRIMARY};
        border-radius: 0 24px 24px 0;
      }

      ${SubMenuTitle} {
        color: ${colors.GRAY2};
      }
    `}
`;
