import Button from "@components/atoms/Button";
import BaseModal from "@components/molecules/Modal/BaseModal";
import { ModalType } from "@store/modal";
import { colors, typo } from "@styles/index";
import styled from "styled-components";

export interface OneButtonModalProps {
  title: string;
  buttonText?: string;
  modalType?: ModalType;
  onConfirm?: () => void;
  handleClose?: () => void;
}

function OneButtonModal({
  modalType = "OneButtonModal",
  title = "title",
  buttonText = "확인",
  onConfirm,
  handleClose,
}: OneButtonModalProps) {
  return (
    <BaseModal modalType={modalType} isOpen handleClose={handleClose}>
      <ModalTitle>{title}</ModalTitle>
      <ButtonWrap>
        <Button buttonSize="lager" text={buttonText} onClick={onConfirm} />
      </ButtonWrap>
    </BaseModal>
  );
}

export default OneButtonModal;

const ButtonWrap = styled.div`
  margin-left: auto;
`;

const ModalTitle = styled.div`
  white-space: pre-line;
  color: ${colors.GRAY2};
  ${typo.HEADING_3};
`;
