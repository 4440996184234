import { requestImageUpload } from "@apis/common";
import { ErrorResponseType } from "@network/types";
import { hasKey, imageUploadCategoryCheck } from "@utils/data-fns";
import { useMutation } from "react-query";
import { CommonCreateBodyKeyType } from "src/types/common";
import useGlobalModal from "./useGlobalModal";
import useGlobalNoti from "./useGlobalNoti";
import useRouter from "./useRouter";
import request from "@network/index";

interface UseCommonCreatorProps {
  createUrl: string;
  createBodyKey: CommonCreateBodyKeyType;
}

const useCommonCreator = <T extends {}>({
  createUrl,
  createBodyKey,
}: UseCommonCreatorProps) => {
  const { goBack, pathname } = useRouter();
  const { showConfirmNoti } = useGlobalNoti();
  const { showAlertModal } = useGlobalModal();

  /** 생성 */
  const requestCreate = async <T extends {}>(body: T) => {
    const values = body as any;

    const checkBody = createBodyKey?.reduce((acc: any, curr: any) => {
      const currValue = values[curr];
      const isBannerExposureLocation = curr === "bannerExposureLocation";

      if (
        values[curr] !== undefined &&
        !!values[curr] &&
        !isBannerExposureLocation
      ) {
        acc[curr] = values[curr];
        // 노출 여부 - 공통
      } else if (curr === "isExposed" && !values[curr]) {
        acc[curr] = values[curr];
        // 공지사항 : 상단 고정여부
      } else if (curr === "isPriorityExposed" && !values[curr]) {
        acc[curr] = values[curr];
        // app : 강제 업데이트 여부
      } else if (curr === "isForcedUpdate" && !values[curr]) {
        acc[curr] = values[curr];
      } else if (isBannerExposureLocation) {
        //  띠 배너 일 경우에만 노출 위치 포함
        if (values.category === "banner") {
          acc[curr] = currValue;
        } else delete acc[curr];
      }
      return acc;
    }, {});

    let customBody = { ...checkBody };

    if (pathname.includes("event")) {
      if (values.contentCategory === "content") {
        customBody = {
          ...customBody,
          category: values.contentCategory,
          content: values.content,
        };
      }

      if (values.contentCategory === "url") {
        customBody = {
          ...customBody,
          category: values.contentCategory,
          urls: [
            {
              url: values.url,
            },
          ],
        };
      }

      if (values.contentCategory === "button") {
        customBody = {
          ...customBody,
          category: values.contentCategory,
          urls: values.urls.map(
            (values: { title: string; url: string }, idx: number) => {
              return {
                ...values,
                displayOrder: idx,
              };
            },
          ),
        };
      }
    }

    if (pathname.includes("banner")) {
      customBody = {
        ...customBody,
        ...(values.category === "pop"
          ? {
              bannerExposureLocation: values.bannerExposureLocation,
            }
          : {}),
      };
    }

    // IMAGE UPLOAD
    if (
      hasKey(body, "thumbnail") &&
      typeof body.thumbnail === "object" &&
      pathname
    ) {
      const imageFile = body.thumbnail.originFileObj;
      const category = imageUploadCategoryCheck(pathname);
      const uploadData = { imageFile, category };

      const imageUrl = await requestImageUpload(uploadData);

      customBody = {
        ...customBody,
        thumbnail: imageUrl,
      };
    }

    /** 생성 API */
    await request<T, void>({
      method: "post",
      url: createUrl,
      requestBody: customBody,
    });
  };

  const { mutate: createMutate } = useMutation<void, ErrorResponseType, T>(
    requestCreate,
    {
      onSuccess: () => {
        showConfirmNoti({});
        goBack();
      },
      onError: (error) => {
        if (
          error.message.includes("Token is not active") ||
          error.message.includes(
            "Refresh toked issued before the client session started",
          )
        ) {
          return console.log("📕 >>>> ERROR", error.message);
        } else {
          const message =
            typeof error.message === "object"
              ? error.message[0]
              : error.message;

          showAlertModal({
            title: message,
          });
        }
      },
      retry: false,
    },
  );

  return {
    createMutate,
  };
};

export default useCommonCreator;
