import { ICONS } from "@assets/icons";
import IconBox from "@components/atoms/IconBox";
import { colors, typo } from "@styles/index";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface SideMainMenuProps {
  children?: ReactNode;
  title?: string;
  iconSrc?: string;
  isOpenInit?: boolean;
}

const SideMainMenu: React.FC<SideMainMenuProps> = ({
  children,
  title,
  iconSrc,
  isOpenInit = true,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenInit);
  const subMenuRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>("");

  useEffect(() => {
    if (subMenuRef.current) {
      subMenuRef.current.style.height = "auto";
      setHeight(`${subMenuRef.current.getBoundingClientRect().height}px`);
    }
  }, [height, title]);

  useEffect(() => {
    if (subMenuRef.current && height && isOpen) {
      subMenuRef.current.style.height = height;
    } else if (subMenuRef.current && !isOpen) {
      subMenuRef.current.style.height = "0px";
    }
  }, [isOpen, height]);

  return (
    <>
      <MenuItemWrap isOpen={isOpen}>
        <MenuItem onClick={() => setIsOpen((prev) => !prev)}>
          <div className="titleWrap">
            <IconBox src={iconSrc} width={20} height={20} />
            <Title>{title}</Title>
          </div>
          <IconBox
            className="arrow-icon"
            src={ICONS.ARROW_ICON.UP}
            width={20}
            height={20}
          />
        </MenuItem>

        <SubMenuItemWrap ref={subMenuRef}>{children}</SubMenuItemWrap>
      </MenuItemWrap>
    </>
  );
};

export default SideMainMenu;

const MenuItemWrap = styled.div<{ isOpen?: boolean }>`
  overflow: hidden;

  .arrow-icon {
    transition: 0.3s;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleWrap {
    display: flex;
    gap: 14px;
    align-items: center;
  }
`;

const Title = styled.span`
  ${typo.BODY_8}
  color: ${colors.GRAY4}
`;

const SubMenuItemWrap = styled.div<{ isOpen?: boolean }>`
  transition: all 0.3s;
`;
